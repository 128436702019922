import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import './panel.css';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { SaveButton } from '../components/base/SaveButton';
import { lang } from 'moment';

export interface ModalPanelState {
    modal: boolean,
    isSaving: boolean,
    isLeaving: boolean
}

export interface ModalPanelProps {
    title?: string,
    description?: string,
    header?: React.ReactNode,
    color?: string,
    okButtonLabel?: string,
    leaveButtonLabel?: string,
    cancelButtonLabel?: string,
    modalPanelOpen?: string,
    modalPanelClose?: string,
    modalPanelSave?: string,
    position?: string,
    showHeader?: boolean,
    onClosed?: (()=> void),
    onSave?: (()=> void),
    isDirty: boolean,
    unsavedChangesText: string,
}

export class ModalPanel extends React.Component<ModalPanelProps, ModalPanelState> {
    constructor(props:any) {
        
        super(props);
        
        this.state = {
            modal: false,
            isSaving: false,
            isLeaving: false
        };
        
        this.close = this.close.bind(this);
        this.closeAndLeave = this.closeAndLeave.bind(this);
        this.save = this.save.bind(this);
        this.open = this.open.bind(this);
    }


    public componentDidMount() {
        App.subscribe(this.props.modalPanelOpen,
            this.open);
        App.subscribe(this.props.modalPanelClose,
                this.closeAndLeave);
    }

    public componentWillUnmount() {
        App.unsubscribe(this.props.modalPanelOpen);
        App.unsubscribe(this.props.modalPanelClose);
    }

    open() {
        this.setState({
            modal: true, isSaving: false
        });
    }

    close() {
        if (!this.props.isDirty)
        {
            this.setState({
                modal: false,
                isLeaving: false
            });
            if (this.props.onClosed!=null) this.props.onClosed();
        }
        else
        {
            this.setState({isLeaving: true});
        }
    }

    closeAndLeave() {
        this.setState({
            modal: false,
            isLeaving: false
        });
        if (this.props.onClosed!=null) this.props.onClosed();
    }

    save() {
        this.setState({
            isSaving: true
        });
        App.publish(this.props.modalPanelSave,{});
        
        if (this.props.onSave!=null)
        {
            this.props.onSave();
        } 
    }

    render() {
        let showHeader = true;
        if (this.props.showHeader!=undefined)
        {
            showHeader = this.props.showHeader;
        }

        return <div>
            <Modal isOpen={this.state.modal}  toggle={this.close} contentClassName="modal-panel-content"
                backdrop={this.props.isDirty? "static": true} keyboard={!this.props.isDirty}
                modalClassName={this.props.position} onClosed={this.props.onClosed}>
                    {showHeader ?
                    <div className="yc-navbar-panel modal-header">
                        <div className="p-2 flex-grow-1 bd-highlight" 
                            style={this.props.color ? {borderLeft: '10px solid ' + this.props.color, marginLeft: '-16px'}:{}}
                            >
                            {this.props.header ? this.props.header : <div>
                                <h1>{ this.props.title }</h1>
                                <div >
                                    { this.props.description } 
                                </div>
                            </div>}
                        </div>
                        <div className="p-2 bd-highlight">                                       
                            <button type="button" onClick={this.close} className="close" aria-label="Close"><span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                    : <React.Fragment></React.Fragment>}
                    {this.props.isDirty && this.state.isLeaving ?
                    <React.Fragment>
                    <Alert color="danger" className="shadow" style={{margin: '10px'}}>
                        {this.props.unsavedChangesText} 
                        <hr />
                        <SaveButton saving={this.state.isSaving} onSave={this.save} text={ this.props.okButtonLabel }/>
                        <button className="btn btn-link"
                            onClick={() => this.closeAndLeave()}>{ this.props.leaveButtonLabel }</button>
                    </Alert> 
                    </React.Fragment>: <React.Fragment></React.Fragment>}
                    <ModalBody style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    { this.props.children }
                    </ModalBody>
                    <ModalFooter>
                        {this.props.isDirty && !this.state.isLeaving ?
                            <SaveButton saving={this.state.isSaving} onSave={this.save} text={ this.props.okButtonLabel }/>
                        : <React.Fragment></React.Fragment>
                        }
                        {!this.state.isLeaving ?
                        <button className="btn btn-link"
                            onClick={this.close}>{ this.props.cancelButtonLabel }</button>: <React.Fragment></React.Fragment>}
                    </ModalFooter>
            </Modal>

            
        </div>
    }
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container } from 'reactstrap';
import { LanguageContext, Language } from '../../backbone/Language';
import { ContentItem } from '../../api/Content';
import {Loading} from './Loading'

import ReactMarkdown from  'react-markdown';

export interface ContentBaseState {
    loading: boolean
    content?: ContentItem;
    contentID: string;
    contentType: string,
    locale: string,
    preview: boolean
}

export interface ContentBaseProps {
    contentID: string;
    contentType: string;
    preview?: boolean
}

export class ContentBase extends React.Component<ContentBaseProps, ContentBaseState> {
    constructor(props:ContentBaseProps) {
        super(props);
        
        this.state = {
            loading: true,
            locale: Language.GetLocale(),
            preview: this.props.preview ? true : false,
            contentID: this.props.contentID ?  this.props.contentID : '',
            contentType: this.props.contentType ?  this.props.contentType : 'internal'
        };
    }

    componentDidMount() {
        if (this.state.contentType=='contentful' && this.state.contentID.length>0) {
            fetch('api/content/getcontent?id=' + this.state.contentID + '&locale=' + this.state.locale
                + '&preview=' + this.state.preview,
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<ContentItem>)
                .then(data => {    
                    console.log(data);
                    this.setState({content: data, loading: false});
                }).catch(reason => {
                    console.log(reason);
                    this.setState({loading: false});
            });
        }
        else if (this.state.contentType=='internal' && this.state.contentID.length>0)
        {
            fetch('api/content/getinternalcontent?id=' + this.state.contentID + '&locale=' + this.state.locale,
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<ContentItem>)
                .then(data => {    
                    console.log(data);
                    this.setState({content: data, loading: false});
                }).catch(reason => {
                    console.log(reason);
                    this.setState({loading: false});
            });
        }
    }

    render() {
        return <>
        <Loading style={{height: '100%'}} loading={this.state.loading}>
            <>
            <ReactMarkdown key="copy" source={this.state.content?.copy} />
            {this.props.children}
            <ReactMarkdown key="slug" source={this.state.content?.slug} />
            </>   
        </Loading>
        </>
    }
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Row, Col } from 'reactstrap';
import { LanguageContext } from '../backbone/Language';
import { GlobalAppsettings } from '../backbone/GlobalSettings';

import { default as LogoBlackBlue} from '../images/Logo_BlackBlue';
import { default as LogoWhiteBlue} from '../images/Logo_WhiteBlue';
import { default as LogoColor} from '../images/Logo_Color';

export interface ProductLogoState {
    loading: boolean
    title: string
}


export interface ProductLogoProps {
    title?: string
    type?: string
    width?: string  
}

export class ProductLogo extends React.Component<ProductLogoProps, ProductLogoState> {
    static displayName = 'ProductLogo';
    static contextType = LanguageContext;
    constructor(props:ProductLogoProps) {
        super(props);
        
        let _title = props.title!=undefined ? props.title: '';

        this.state = {
            title: _title,
            loading: false
        };
    }

    componentDidMount() {
    }


    render() {
        
        let width = '80px';

        if (this.props.width!=undefined)
        {
            width=this.props.width;
        }

        let logo = <LogoBlackBlue style={{ width: width }} />;

        if (this.props.type!=undefined)
        {
            if (this.props.type=="white")
            {
                logo = <LogoWhiteBlue style={{ width:width }} />;
            }
            if (this.props.type=="color")
            {
                logo = <LogoColor style={{ width: width }} />;
            }
        }
        
        
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div >
           <Row><Col>{logo}<div className="text-bold">{this.props.title}{this.props.children}</div></Col></Row>
            {/* <div className="product-logo">{this.props.title}{this.props.children}</div> */}
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container, Row, Col } from 'reactstrap';
import { LanguageContext, Language } from '../backbone/Language';
import { ContentItem } from '../api/Content';
import { HeroItem } from '../api/LandingPage';
import { GlobalAppsettings } from '../backbone/GlobalSettings';

import ReactMarkdown from 'react-markdown';



import { default as LogoBlackBlue} from '../images/Logo_BlackBlue';
import { default as LogoWhiteBlue} from '../images/Logo_WhiteBlue';
import { default as LogoColor} from '../images/Logo_Color';

import moment from "moment";
import { AppCore } from '../backbone/AppCore';

import { motion } from "framer-motion";

export interface HeroState {
    loading: boolean
    items?: HeroItem[]
    currentHeroItem?: HeroItem
    locale: string
}

export interface HeroProps {
  items?: HeroItem[]
  currentHeroItem?: HeroItem
}

export class Hero extends React.Component<HeroProps, HeroState> {
    static displayName = 'Hero';
    static contextType = LanguageContext;
    constructor(props:HeroProps) {
        super(props);

        this.state = {
            loading: false,
            locale: Language.GetLocale(),
            items: this.props.items,
            currentHeroItem: this.props.currentHeroItem
        };
    }

    componentDidMount() {
      if (this.props.currentHeroItem == undefined && this.props.items == undefined)
      {
        fetch('api/content/getAllHeros?locale=' + this.state.locale + '&preview=false',
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<HeroItem[]>)
                .then(data => {    
                    if (data.length > 0)
                    {
                        this.setState({items: data, currentHeroItem: data[0], loading: false});
                    }
                }).catch(reason => {
                    console.error(reason);
                    this.setState({loading: false});
            });
      }
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => (
        <div  style={{
            backgroundImage:  'url(' + this.state.currentHeroItem?.image.url +')', 
            paddingTop: '40px',
            paddingBottom: '120px',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            overflowY: 'hidden',
            backgroundBlendMode: 'screen',
            backgroundColor: 'rgba(4, 118, 217, 0.27)'
            }}>
          <Container>
          
          <Row>
            <Col style={{marginLeft: '40px'}}>
              <div className="text-sm u-letter-" style={{color: 'black', 
                marginLeft: '5px'}}>{this.state.currentHeroItem?.title}</div>  
              <div className="yc-header" style={{color: 'white', lineHeight: '95px'}}>
                {this.state.currentHeroItem?.heading}
              </div>
              
              <Row style={{ marginTop: '80px'}}>
                <Col>
                  <Button color="primary" className="btn--pill"  style={{ width: '250px', height: '48px' ,marginBottom: '20px'}} 
                    onClick={() => App.publish('user.login',{})}>{this.state.currentHeroItem?.callToActionText}</Button>
                </Col>
              </Row>
            </Col>
            {/* <Col md="5" style={{marginRight: '40px'}}>
              <motion.div
               initial={{ scale: 0 }}
               animate={{ scale: 1 }}
               transition={{
                 type: "spring",
                 stiffness: 260,
                 damping: 20,
                delay: 0.8}}
              >
                <article className="u-pricing shadow-lg text-center rounded-md" style={{height: '400px', backgroundColor: '#000000ab'}}>
                  <div className="u-pricing__content" style={{height: '250px'}}>
                  <div className="yc-subheader" style={{color: 'white'}}>
                        {this.state.currentHeroItem?.heroBox}
                        <ReactMarkdown  source={this.state.currentHeroItem?.subHeading}></ReactMarkdown>
                    </div>
                  </div>
                </article>
              </motion.div>
            </Col> */}
          </Row>
          </Container>
          </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
}

import * as React from 'react';
import * as App from 'pubsub-js';
import Moment from 'react-moment';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Form, FormGroup, 
    Row, Col, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, InputGroup, InputGroupAddon, 
    Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { LanguageContext } from '../../../backbone/Language';

import { Loading } from '../../../components/base/Loading';

import { Media } from '../api/Media';


export interface MediaPanelState {
    modal: boolean,
    loading : boolean,
    search: string,
    result?: Media.MediaResult,
    per_page: number,
    current_page: number,
    moreLoading: boolean,
    currentUploadFile?:  File,
    activeTab: string
}

export interface MediaPanelProps {
    title?: string,
    cancelButtonLabel?: string,
    modalPanelOpen?: string,
    position?: string ,
    onClosed?: (()=> void)
}

export class MediaPanel extends React.Component<MediaPanelProps, MediaPanelState> {

    constructor(props:any) {
        
        super(props);
        
        this.state = {
            modal: false,
            loading: false,
            moreLoading: false,
            search: '',
            per_page: 20,
            current_page: 1,
            activeTab: '0'
        };
        
        this.close = this.close.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.renderMediaPanel = this.renderMediaPanel.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    public componentDidMount() {
        App.subscribe(this.props.modalPanelOpen,
            this.open.bind(this));
    }

    public componentWillUnmount() {
        App.unsubscribe(this.open.bind(this));
    }

    private open(message: string, input?: string) {
        
        this.setState({
            modal: true
        });

        if (input!=undefined) {
            this.setState({
                search: input
            });
        }

        this.search();
    }

    private search() {

        this.setState({
            loading: true, result: undefined, current_page: 1
        });

        let request = {
            searchText: this.state.search,
            per_page: this.state.per_page,
            page: this.state.current_page
        }

        // fetch('/api/media/GetMedia',{
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(request),
        //     credentials: 'same-origin'})
        //     .then(response => response.json() as Promise<Media.MediaResult>)
        //     .then(data => {
        //         this.setState({ result: data, loading: false });
        //     }).catch(reason => {

        // });
    }

    private loadMore() {

        let nextPage: number = this.state.current_page + 1;

        this.setState({
            moreLoading: true, current_page: nextPage
        });

        let request = {
            searchText: this.state.search,
            per_page: this.state.per_page,
            page: nextPage
        }

        // fetch('/api/media/GetMedia',{
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(request),
        //     credentials: 'same-origin'})
        //     .then(response => response.json() as Promise<Media.MediaResult>)
        //     .then(data => {
                
        //         if (this.state.result!=undefined)
        //         {
        //             let current: Media.MediaResult = this.state.result;

        //             data.data.forEach(item => {
        //                 current.data.push(item);
        //             });

        //             this.setState({ result: current });
        //         }

        //         this.setState({
        //             moreLoading: false
        //         });
                
        //     }).catch(reason => {

        // });
    }

    private close() {
        this.setState({
            modal: false
        });
    }

    private onSearchChange(event: any) {
        this.setState({
            search: event.target.value
        });
    }

    private onKeyPress (e: any) {
        if (e.key === 'Enter') {
            this.search();
        }
    }

    private onScroll(e: any) {
        const bottom = e.target.scrollHeight - e.target.scrollTop < (e.target.clientHeight + 5);
        if (bottom)
        {
            this.loadMore();
        }
    }

    private addMedia(media: Media.MediaRef) {

        let ref = {
            id: media.id,
            url: media.assets.huge_thumb.url,
            provider: media.contributor.id,
            width: media.assets.huge_thumb.width,
            height: media.assets.huge_thumb.height,
            description: media.description,
            searchText: this.state.search
        }

        App.publish('taskedit.addmedia',ref)
    }

    private uploadfile() {

        //e.preventDefault();

        // var inputfile = this.state.currentUploadFile;
    
        // if (inputfile!=undefined)
        // {
        //     var formData = new FormData();
        //     formData.append(inputfile.name, inputfile);

        //     fetch('/api/files/uploadto?_ref=' + 'files'
        //         + '&reftype:reftype.reference', { 
        //         method: 'POST',
        //         headers: {
        //         'Accept': 'application/json'
        //         // 'Content-Type': 'multipart/form-data' //Boundary Error
        //         },
        //         body: formData,
        //         credentials: 'same-origin'
        //     }).then(response => {
                
        //         App.publish("ref.reload",{});

        //         //this.setState({currentUploadFile: undefined, message: 'Dokumentet laddades upp' });

        //     }).then(
        //         success => console.log(success) 
        //     ).catch(
        //         error => console.log(error) 
        //     );
        // }
    
    }

    

    render() {
        
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
        <Modal isOpen={this.state.modal} toggle={this.close} contentClassName="modal-panel-content"
            modalClassName={this.props.position} onClosed={this.props.onClosed}>
            <div className="youcap-navbar-panel">
                <Row>

                </Row>
            </div>
            <ModalBody style={{overflowY: 'auto', overflowX: 'hidden'}}
                onScroll={this.onScroll} >
            <div style={{width: '80vw'}}>
                 <Loading loading={this.state.loading} style={{minHeight: '400px'}}>
                 {this.renderMediaPanel(this.state.result)}
                 {this.state.moreLoading ? 
                                <div className="loading">
                                    <p><img src="./img/_loading.gif"/><br/></p>
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                </Loading> 
            </div>
            </ModalBody>
            <ModalFooter>
                <Button color="link" onClick={this.close}>{ this.props.cancelButtonLabel }</Button>
            </ModalFooter>
        </Modal>
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private renderMediaPanel(result?: Media.MediaResult) {
        if (result!=undefined)
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            
            {/* <span style={{fontStyle: 'italic', fontSize: '11px'}}>
                {result.total_count} {lang.Text('mediaplanel.hits')}
            </span> */}

            <hr/>

            {result.data.map( media =>
              <Card key={media.id}
                className="shadow"
                style={{marginTop: '5px', marginRight: '15px'}}>
                <div style={{width: '100%',height: media.assets.huge_thumb.height, overflow: 'hidden'}}>
                    <img width={media.assets.huge_thumb.width} 
                             src={media.assets.huge_thumb.url}
                             />
                </div>
                <CardBody>
                  {/* <CardTitle>Card title</CardTitle> */}
                  <CardText>
                      {media.description}
                  </CardText>
                  <Button color="primary" onClick={() => this.addMedia(media)} >{lang.Text('mediapanel.add')}</Button>
                </CardBody>
              </Card>
            )}

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    
}
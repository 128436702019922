import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Dropdown, DropdownToggle, Input, DropdownMenu, Container, Row, Col } from 'reactstrap';
import { LanguageContext, Language } from '../../../backbone/Language';
import { ContentItem } from '../../../api/Content';
import { GlobalAppsettings } from '../../../backbone/GlobalSettings';
import { Loading } from '../../../components/base/Loading';

import { InfoPanel } from '../../../panels/InfoPanel';

import { SearchApi } from '../api/Search';

import { DraftEditor } from '../../../components/drafteditor/DraftEditor';

export interface SearchTaskState {
    loading: boolean
    searchText: string
    dropdownOpen: boolean
    isSearching: boolean
    searchRes?: SearchApi.SearchRes,
    isSearchPanelOpen: boolean
}

export interface SearchTaskProps {
    
}

export class Search extends React.Component<SearchTaskProps, SearchTaskState> {
    static displayName = 'SearchTask';
    static contextType = LanguageContext;
    constructor(props:SearchTaskProps) {
        super(props);
        
        this.state = {
            loading: false,
            searchText: '',
            dropdownOpen: false,
            isSearching: false,
            isSearchPanelOpen: false
        };

        this.onSearchChange = this.onSearchChange.bind(this);
        this.searchToggle = this.searchToggle.bind(this);
        this.openSearch = this.openSearch.bind(this);
        this.searchKeyPress = this.searchKeyPress.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.renderSearchResult = this.renderSearchResult.bind(this);

        this.openSearch = this.openSearch.bind(this);
        this.renderSearchResult = this.renderSearchResult.bind(this);

        this.openSearchPanel = this.openSearchPanel.bind(this);
        this.searchPanelClose = this.searchPanelClose.bind(this);
    }

    componentDidMount() {
        
    }

    private openSearch() {
            
        fetch('api/workset/SearchTasks?text=' + encodeURIComponent(this.state.searchText),
        {credentials: "same-origin"})
        .then(response => response.json() as Promise<SearchApi.SearchRes>)
        .then(data => {     
            this.setState({ isSearching: false, searchRes : data });

        }).catch(reason => {
            this.setState({ isSearching: false, searchRes : undefined });
        });

    }

    private closeSearch() {
        this.setState({
            dropdownOpen: false
          }); 
    }

    private onSearchChange(event: any) {
        this.setState({searchText: event.target.value});

        if (this.state.searchText.length === 0) {
            this.setState({searchRes: undefined});
        }
    }

    private searchKeyPress(event: any) {
        if (event.key==='Enter')
        {
            if (this.state.isSearchPanelOpen)
            {
                this.setState({
                    isSearching: true});
            }
            else
            {
                this.setState({
                    dropdownOpen: true,
                    isSearching: true});
            }
            this.openSearch();
        }
    }

    private searchToggle() {
        // this.setState({
        //     dropdownOpen: !this.state.dropdownOpen
        //   });
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
                <div className="input-group " 
                    // style={{marginTop: '14px'}}
                        >
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.searchToggle}
                            className="">
                            <DropdownToggle
                                tag="div"
                                // onClick={this.searchToggle}
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpen}>
                                <Input type="search" 
                                    name="search"
                                    placeholder={lang.Text('search.placeholder')} 
                                    aria-label="Username" aria-describedby="basic-addon1"
                                    onKeyPress={this.searchKeyPress}
                                    value={this.state.searchText}
                                    onChange={this.onSearchChange}
                                    style={{width: '40vw'}}/>
                            </DropdownToggle>
                            <DropdownMenu className="shadow">
                                <div >
                                <Container fluid style={{width: '550px'
                                    , minHeight: '340px'
                                    }}
                                   >
                                <Row>
                                    <Col>
                                        {this.state.isSearching ?
                                            <Loading loading></Loading>
                                            : 
                                            this.renderSearchResult(lang)
                                        }
                                    </Col>
                                </Row>
                                <Row className="fixed-bottom">
                                    <Col><Button className="float-right" color="link"
                                        onClick={this.closeSearch}>{lang.Text('search.close.results')}</Button></Col>
                                </Row>
                                </Container>
                                </div>
                            </DropdownMenu>
                        </Dropdown>

                        {/* <div className="input-group-append">
                            <span className="input-group-text btn btn-success d-none d-md-block" id="basic-addon1" style={{backgroundColor: '#293c48',cursor: 'pointer'}}>
                                <span className="iconic iconic-magnifying-glass" style={{color: 'white'}}
                                    onClick={this.openSearch}></span>    
                            </span>
                        </div> */}
                    </div>
               


            <InfoPanel title={lang.Text('search.title')} cancelButtonLabel={lang.Text('button.close')}
                modalPanelOpen="search.open"
                position="modal-panel-right" onClosed={this.searchPanelClose}
                header = {<React.Fragment>
                    
                    <div className="input-group">
                        <Input type="search" 
                                name="search"
                                placeholder={lang.Text('search.placeholder')} 
                                aria-label="Username" aria-describedby="basic-addon1"
                                onKeyPress={this.searchKeyPress}
                                value={this.state.searchText}
                                onChange={this.onSearchChange}/>
                        {/* <div className="input-group-append ">
                            <span className="input-group-text btn btn-success" id="basic-addon1" 
                                    style={{
                                        //backgroundColor: '#293c48',
                                        cursor: 'pointer'
                                    }}>
                                <span className="iconic iconic-magnifying-glass" style={{color: 'white'}}
                                    onClick={this.openSearch}></span>    
                            </span>
                        </div> */}
                    </div>

                </React.Fragment>}
                >
                <div className="yc-panel-sm" style={{width: '580px'}}>
                    {this.state.isSearching ?
                        <Loading loading></Loading>
                        : 
                        this.renderAllSearchResult(lang)
                    }
                </div>

            </InfoPanel>
        
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private getStateColor(state: number): any {
        switch (state) {
            case 0:
                return '#6c757d';
            break;
            case 1:
                return '#5bc0de';
            break;
            case 2:
                return '#f0ad4e';
            break;
            case 3:
                return '#2595FF';
            break;
            case 4:
                return '#d9534f';
            break;
        }
        return '#6c757d';
    }

    private openPreview(event: any, taskid: string) {
        let preview = {
            id: taskid,
            versionId: undefined
        }
        App.publish("task.preview.open", preview);
    }

    private openSearchPanel(){
        App.publish("search.open",{});
        this.setState({isSearchPanelOpen: true, dropdownOpen: false})
    }

    private searchPanelClose(){
        this.setState({isSearchPanelOpen: false})
    }

    private renderSearchResult(lang: Language) {
        if (this.state.searchRes!=undefined && !this.state.isSearchPanelOpen)
        return (<div>
            {this.state.searchRes.count > 0 ? <p>{lang.Text('search.result.found')} {this.state.searchRes.count} {lang.Text('search.result.matching')} '{this.state.searchText}':</p> :
                <p>{lang.Text('search.noresult')}'{this.state.searchText}'</p>}
            <Row>
                <Col>
                {this.state.searchRes.tasks.slice(0,3).map(item =>
                    <div key={item.id} style={{borderLeft: '3px solid', 
                        borderLeftColor: this.getStateColor(item.state),
                        paddingLeft: '5px'}}>
                        <Button style={{fontSize: '17px',marginLeft: '-10px'}} 
                            onClick={(event: any) => this.openPreview(event,item.id)}
                            color="link">{item.title}</Button>
                        <DraftEditor
                            textOnly={false} readOnly={true}
                            id={this.getNextId('desc')} dataId={this.getNextId('desc')} 
                            text={item.description}
                        ></DraftEditor>
                        <hr/>
                    </div>
                )}
                </Col>
            </Row>
            {this.state.searchRes.count > 3 ?
            <p>
                <Button color="link" onClick={this.openSearchPanel}>{lang.Text('search.more.results')}</Button>
            </p> : <React.Fragment></React.Fragment>}
        </div>)
        else return (
            <div className="statcard p-3 text-xs-center" style={{marginTop: '45px'}}>
                <h3 className="statcard-number"><span className="iconic iconic-brain"></span></h3>
                <span className="statcard-desc">{lang.Text('navbar.search.intro')}</span>
                <p>
                {lang.Text('navbar.search.description')}</p>
            </div>
        )
    }

    private num: number = 0;
    //Function to generate unique id:s for components
    private getNextId(id: string): string {
        this.num ++;
        return id + this.num;
    }

    static GetTaskUrl(id: string): string {
        return './task/' + id + "?taskgroup=" + "Sök";
    }

    private renderAllSearchResult(lang: Language) {
        if (this.state.searchRes!=undefined)
        return (<div>
            {this.state.searchRes.count > 0 ? <p>{lang.Text('search.result.found')} {this.state.searchRes.count} {lang.Text('search.result.matching')} '{this.state.searchText}':</p> :
                <p>{lang.Text('search.noresult')}'{this.state.searchText}'</p>}
            <Row>
                <Col>
                {this.state.searchRes.tasks.map(item =>
                    <div key={item.id} style={{borderLeft: '3px solid', 
                        borderLeftColor: this.getStateColor(item.state),
                        paddingLeft: '5px'}}>
                        <Button style={{fontSize: '17px',marginLeft: '-10px',textAlign: 'left'}} 
                            onClick={(event: any) => this.openPreview(event,item.id)}
                            color="link">{item.title}</Button>
                        <DraftEditor
                            textOnly={false} readOnly={true}
                            id={this.getNextId('desc')} dataId={this.getNextId('desc')} 
                            text={item.description}
                        ></DraftEditor>
                        <hr/>
                    </div>
                )}
                </Col>
            </Row>
        </div>)
        else return (
            <p className="no-data mt-3 mb-3">
            <i className="fa fa-umbrella-beach"/>{'   '} {lang.Text('no.data')}
            </p>
        )
    }
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Form, FormGroup, Row, Col } from 'reactstrap';

import { LanguageContext } from '../../../backbone/Language';
import { Api } from '../api/WorkSet';
import { DraftEditor } from '../../../components/drafteditor/DraftEditor';

import Moment from 'react-moment';
import { Loading } from '../../../components/base/Loading';

export interface HistoryPanelState {
    modal: boolean,
    loading : boolean,
    history?: Api.TaskCore[]
}

export interface HistoryPanelProps {
    title?: string,
    cancelButtonLabel?: string,
    modalPanelOpen?: string,
    position?: string ,
    onClosed?: (()=> void)
}

export class HistoryPanel extends React.Component<HistoryPanelProps, HistoryPanelState> {
    constructor(props:any) {
        
        super(props);
        
        this.state = {
            modal: false,
            loading: true
        };
        
        this.close = this.close.bind(this);
    }

    public componentDidMount() {
        App.subscribe(this.props.modalPanelOpen,
            this.open.bind(this));
    }

    public componentWillUnmount() {
        App.unsubscribe(this.open.bind(this));
    }

    private open(message: string, id: string) {
        
        this.setState({
            modal: true, loading: true,
        });

        fetch('api/workset/gethistory?id='+ id,
            {credentials: "same-origin"})
            .then(response => response.json() as Promise<Api.TaskCore[]>)
            .then(data => {
                this.setState({ history: data, loading: false });
                App.publish('intercom.event','Open HistoryPanel');
            }).catch(reason => {

        });

    }

    private close() {
        this.setState({
            modal: false
        });
    }

    render() {
        
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
        <Modal isOpen={this.state.modal} toggle={this.close} contentClassName="modal-panel-content"
            modalClassName={this.props.position} onClosed={this.props.onClosed}>
                <ModalHeader className="youcap-navbar-panel" toggle={this.close}> { this.props.title }</ModalHeader>
                <ModalBody style={{overflowY: 'auto', overflowX: 'hidden'}}>
                <div style={{width: '500px'}}>
                    {this.state.loading ? 
                        <Loading loading/>
                    :<React.Fragment>
                   
                    <hr/>
                    <div>
                        {this.renderHistory(this.state.history)}
                    </div>
                    </React.Fragment>
                    }
                 </div>
                </ModalBody>
            <ModalFooter>
                <Button color="link" onClick={this.close}>{ this.props.cancelButtonLabel }</Button>
            </ModalFooter>
        </Modal>
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private openPreview(event: any,task: Api.TaskCore) {
        let preview = {
            id: task.id,
            versionId: task.versionId
        }
        App.publish("preview.open", preview);
    }

    private renderHistory(history?: Api.TaskCore[]) {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            {history!=undefined ? history.map( task => 
               <Row className="top-spacing-sm">
                    <Col sm="2">
                        <Button color={this.getStateColor(task.state)} style={{minWidth: '35px',marginTop: '-9px',fontSize: '0.6em'}}
                            onClick={(event: any) => this.openPreview(event,task)}>
                        <span>{lang.Text('task.version')}</span><br/>
                        {task.versionText}
                        </Button> 
                    </Col>
                    <Col sm="10">
                    <Alert color={this.getStateColor(task.state)} style={{marginTop: '-11px'}}>
                        <Row>
                            <Col>
                                <h4>{task.title}</h4>
                                {/* <DraftEditor textOnly={false} readOnly={true}
                                    id="history-desc" dataId="history.desc" text={task.description} /> */}
                            </Col>
                            <Col md="5">
                                <Moment format="YYYY-MM-DD HH:mm"
                                    date={task.timeStamp}/>
                            </Col>
                        </Row>
                        <hr/>
                        { task.changes.length > 0 ? <div>
                              {task.changes[0].type === 'task.comment' ? <div></div> :
                                <p><i>{task.changes[0].user} {lang.Text('task.history.changes')}{':'}</i></p> 
                               }
                               {task.changes.map(change => this.renderChange(change))}
                            </div>
                        : <div></div> }
                    </Alert>
                    </Col>
                </Row>
                
            )
            :
            <React.Fragment></React.Fragment>
            }    
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private getStateColor(state: number): any {
        switch (state) {
            case 0:
                return 'secondary';
            break;
            case 1:
                return 'info';
            break;
            case 2:
                return 'warning';
            break;
            case 3:
                return 'success';
            break;
            case 4:
                return 'danger';
            break;
        }
        return 'secondary';
    }

    private renderChange(change: Api.Change){
        let ch = <React.Fragment></React.Fragment>;
        
        if (change.type==='task.comment') {
            return <React.Fragment>
            <LanguageContext.Consumer>
            {lang => ( <div>
                <Alert color="primary">
                    <b>{change.user} {lang.Text(change.type)}:</b> <DraftEditor textOnly={false} readOnly={true}
                        id="history-desc" dataId="history.desc" text={change.text} />
                </Alert>
            </div>)}
            </LanguageContext.Consumer>
            </React.Fragment>
        }
        if (change.type==='task.addmedia' || change.type==='task.removemedia') {
            return <React.Fragment>
            <LanguageContext.Consumer>
            {lang => ( <div>
                <b>{lang.Text(change.type)}:</b><img src={change.text} />
            </div>)}
            </LanguageContext.Consumer>
            </React.Fragment>
        }
        else {
            return <React.Fragment>
            <LanguageContext.Consumer>
            {lang => ( <div>
                <b>{lang.Text(change.type)}:</b> <DraftEditor textOnly={false} readOnly={true}
                        id="history-desc" dataId="history.desc" text={change.text} />
            </div>)}
            </LanguageContext.Consumer>
            </React.Fragment>
        }
    }
}
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

//import './edit-panel.css';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { SaveButton } from '../../../components/base/SaveButton';
import { LanguageContext, Language } from '../../../backbone/Language';

import { GlobalAppsettings } from '../../../backbone/GlobalSettings';
import { Loading } from '../../../components/base/Loading';
import { TaskEdit } from '../components/TaskEdit';

export interface EditPanelState {
    modal: boolean,
    id?: string,
    isDirty: boolean,
    isSaving: boolean,
    isLeaving: boolean,
    loading: boolean
}

export interface EditPanelProps {
    
}

export class EditPanel extends React.Component<EditPanelProps, EditPanelState> {
    constructor(props:any) {
        
        super(props);
        
        this.state = {
            modal: false,
            isSaving: false,
            isLeaving: false,
            isDirty: false,
            loading: true
        };
        
        this.close = this.close.bind(this);
        this.closeAndLeave = this.closeAndLeave.bind(this);
        this.save = this.save.bind(this);
        this.open = this.open.bind(this);
        
        this.setDirty = this.setDirty.bind(this);

        
    }


    public componentDidMount() {
        App.subscribe('construction.edit.open', this.open);
        App.subscribe('taskedit.dirty', this.setDirty);
        App.subscribe('construction.edit.close', this.closeAndLeave);
    }

    public componentWillUnmount() {
        App.unsubscribe('construction.edit.open');
        App.unsubscribe('taskedit.dirty');
        App.unsubscribe('construction.edit.close');
    }

    setDirty(msg: string, data: any) {
        console.info('...setDirty');
        this.setState({
            isDirty: true
        });
    } 

    open(msg: string, data: any) {
        
        console.info('open -> ' + data);
        
        this.setState({
            modal: true, 
            id: data,
            isSaving: false,
            isDirty: false,
            isLeaving: false
        });
    }

    close() {
        console.info('...close');
        if (!this.state.isDirty)
        {
            this.setState({
                modal: false,
                isLeaving: false
            });
        }
        else
        {
            this.setState({isLeaving: true});
        }
    }

    closeAndLeave() {
        console.info('...closeAndLeave');
        this.setState({
            modal: false,
            isLeaving: false
        });
    }

    save() {
        console.info('...save');
        this.setState({
            isSaving: true
        });
        App.publish("taskedit.save",null);
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            <Modal isOpen={this.state.modal}  toggle={this.close} contentClassName="modal-panel-content"
                backdrop={this.state.isDirty? "static": true} keyboard={!this.state.isDirty}
                modalClassName={'modal-panel-right'} 
                >
                {this.state.isDirty && this.state.isLeaving ?
                    <React.Fragment>
                    <Alert color="danger" className="shadow" style={{margin: '10px'}}>
                        {lang.Text('modal.unsaved.changes')} 
                        <hr />
                        <SaveButton saving={this.state.isSaving} onSave={this.save} text={lang.Text('btn.save') }/>
                        <button className="btn btn-link"
                            onClick={() => this.closeAndLeave()}>{ lang.Text('btn.leave') }</button>
                    </Alert> 
                    </React.Fragment>: <React.Fragment></React.Fragment>}
                    <ModalBody style={{overflowY: 'auto', overflowX: 'hidden'}}>
                        <div className="yc-panel-xl">
                            {this.renderTask(lang)}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.isDirty && !this.state.isLeaving ?
                            <SaveButton saving={this.state.isSaving} onSave={this.save} text={lang.Text('btn.save')}/>
                        : <React.Fragment></React.Fragment>
                        }
                        {!this.state.isLeaving ?
                        <button className="btn btn-link"
                            onClick={this.close}>{ lang.Text('btn.close') }</button>: <React.Fragment></React.Fragment>}
                    </ModalFooter>
            </Modal>

            </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    renderTask(lang: Language) {
        return <>
        <TaskEdit id={this.state.id ? this.state.id : ''}></TaskEdit>
        </>
    }
}


import {

    ITelemetryPlugin,

    ITelemetryItem,

    IPlugin,

    IConfiguration,

    IAppInsightsCore

} from '@microsoft/applicationinsights-core-js';

import { ConfigurationManager, IDevice } from '@microsoft/applicationinsights-common';


export interface INativeDevice {
    /**
     * Device type, e.g. Handset, Tablet, Tv
     */
    deviceClass?: string;
    /**
     * Unique installation ID
     */
    id?: string;
    /**
     * The device model: iPhone XS Max, Galaxy S10, etc
     */
    model?: string;
}

export interface IAppInsightsPluginConfig {

    disableDeviceCollection?: boolean;
    
    [key: string]: any;
}

export class AppInsightsPlugin implements ITelemetryPlugin {

    private _initialized: boolean = false;

    private _device: INativeDevice;

    private _config: IAppInsightsPluginConfig;

    identifier: string = 'AppInsightsReactNativePlugin';

    priority: number = 199;

    _nextPlugin?: ITelemetryPlugin;

    constructor(config?: IAppInsightsPluginConfig) {

        this._config = config || this._getDefaultConfig();

        this._device = {};

    }


    public initialize(

        config?: IAppInsightsPluginConfig | object, // need `| object` to coerce to interface

        core?: IAppInsightsCore,

        extensions?: IPlugin[]

    ) {

        if (!this._initialized) {

            const inConfig = config || {};

            const defaultConfig = this._getDefaultConfig();

            for (const option in defaultConfig) {

                this._config[option] = ConfigurationManager.getConfig(
                    inConfig as any,
                    option,
                    this.identifier,
                    this._config[option]
                );

            }

            if (!this._config.disableDeviceCollection) {

                this._collectDeviceInfo();

            }

        }

        this._initialized = true;

    }



    public processTelemetry(item: ITelemetryItem) {

        this._applyDeviceContext(item);

        if (this._nextPlugin) {

            this._nextPlugin.processTelemetry(item);

        }

    }



    // public setNextPlugin(next: ITelemetryPlugin) {

    //     this._nextPlugin = next;

    // }

    public setDeviceId(newId: string) {

        this._device.id = newId;

    }



    public setDeviceModel(newModel: string) {

        this._device.model = newModel;

    }



    public setDeviceType(newType: string) {

        this._device.deviceClass = newType;

    }


    private _collectDeviceInfo() {

        // if (Constants.platform.ios !=undefined)
        // {
        //     this._device.model = Constants.platform.ios.platform 
        //         + " " + Constants.platform.ios.model;
        // }

        // if (Constants.platform.android !=undefined)
        // {
        //     this._device.model = Constants.deviceId;
        // }

        // this._device.id = Constants.installationId;

        // this._device.deviceClass = 'Mobile' + Constants.nativeAppVersion;
    }



    private _applyDeviceContext(item: ITelemetryItem) {

        if (this._device) {

            item.ext = item.ext || {};

            item.ext.device = item.ext.device || <IDevice>{};

            if (typeof this._device.id === 'string') {

                item.ext.device.localId = this._device.id;

            }

            if (typeof this._device.model === 'string') {

                item.ext.device.model = this._device.model;

            }

            if (typeof this._device.deviceClass === 'string') {

                item.ext.device.deviceClass = this._device.deviceClass;

            }

        }

    }



    private _getDefaultConfig(): IAppInsightsPluginConfig {

        return {

            // enable autocollection by default

            disableDeviceCollection: false

        };

    }

}
import * as React from 'react';
import * as App from 'pubsub-js';
import { RouteComponentProps } from 'react-router';

import { LanguageContext, Language } from '../../../backbone/Language';
import { Api } from '../api/WorkSet';

import Moment from 'react-moment';
import { Button, FormGroup, Form, Label, CustomInput, Fade, Input, Alert, Badge, Nav, 
    NavItem, NavLink, TabContent, TabPane, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

    
import { Loading } from '../../../components/base/Loading';


export interface RevisionState {
    loading: boolean;
    handle_park_Selected: boolean,
    handle_other_Selected: boolean,
    handle_test_Selected: boolean,
    handle_unison_Selected: boolean,
    handle_back_Selected: boolean,
    handle_park_Enabled: boolean,
    handle_unison_Enabled: boolean,
    otherInstans?: string,
    handle_back_question: string,
    handle_back_distractors: string,
    handle_park_reason: string,
    remissConfig?: Api.TaskRevisionConfig;
    remissTarget?: Api.RevisionTarget;
    remissLoading: boolean;
    revisionMessage?: Api.RevisionResponse;
}

export interface RevisionProps {
    openRevision?: string;
    revisionID?: string;
    prevState?: Api.TaskRevision; 
}

export interface RevisionTargetInfo {
    revisionID: string;
}

export class Revision extends React.Component<RevisionProps, RevisionState> {
    private currentRevision?: string;
    constructor(props:any) {
        
        super(props);
        
        this.state = { loading: false,
            handle_park_Selected: false,
            handle_other_Selected: false,
            handle_test_Selected: false,
            handle_unison_Selected: false,
            handle_back_Selected: false,
            handle_back_question: '',
            handle_back_distractors: '',
            handle_park_reason: '',
            remissLoading: false,
            handle_park_Enabled: true,
            handle_unison_Enabled: true,
        };

        this.currentRevision=undefined;

        this.remissTargetChange = this.remissTargetChange.bind(this);

        this.bounce = this.bounce.bind(this);
        this.sendOther = this.sendOther.bind(this);
        this.approve = this.approve.bind(this);
        this.parkTask = this.parkTask.bind(this);
        this.sendUnison = this.sendUnison.bind(this);

        this.getRevisionConfig = this.getRevisionConfig.bind(this);
    }

    public componentDidMount() {
        // App.subscribe(this.props.openRevision,
        //     this.open.bind(this));

        this.getRevisionConfig();

        if (this.props.prevState!=undefined)
        {
            this.setState({handle_park_Enabled: !this.props.prevState.isParked,
                            handle_unison_Enabled: !this.props.prevState.isUnison});
        }

        if (this.props.revisionID!=undefined) {

            this.currentRevision = this.props.revisionID;
        
            this.setState({revisionMessage: undefined, handle_park_reason: '',
                handle_back_Selected: false,
                handle_back_question: '',
                handle_other_Selected: false,
                handle_park_Selected: false,
                handle_test_Selected: false,
                handle_back_distractors: '', 
                loading: true
            });
        }
    }

    public componentWillUnmount() {
        // App.unsubscribe(this.open.bind(this));
    }

    open(msg:string, revisionInfo?: RevisionTargetInfo) {
       
        console.info(revisionInfo);

        if (revisionInfo!=undefined)
        {  
            this.currentRevision = revisionInfo.revisionID;
        
            this.setState({revisionMessage: undefined, handle_park_reason: '',
                handle_back_Selected: false,
                handle_back_question: '',
                handle_other_Selected: false,
                handle_park_Selected: false,
                handle_test_Selected: false,
                handle_unison_Selected: false,
                handle_back_distractors: '', 
                loading: true
            });
        
            this.getRevisionConfig();
        }

    }

    private getRevisionConfig() {

        fetch('api/revision/GetRevisionConfig',
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<Api.TaskRevisionConfig>)
                .then(data => {
                    this.setState({remissConfig: data});
                }).catch(reason => {
                    this.setState({ loading: false });
            });

    }

    private otherOptionChanged(event: any) {
        if (event.target.checked)
        {
            this.setState({
                handle_other_Selected: true,
                handle_back_Selected: false,
                handle_park_Selected: false,
                handle_test_Selected: false,
                handle_unison_Selected: false,
             });
        }
    }

    private backOptionChanged(event: any) {
        if (event.target.checked)
        {
            this.setState({
                handle_back_Selected: true,
                handle_other_Selected: false,
                handle_park_Selected: false,
                handle_test_Selected: false,
                handle_unison_Selected: false
            });
        }
    }

    private testOptionChanged(event: any) {
        if (event.target.checked)
        {
            this.setState({
                handle_test_Selected: true,
                handle_other_Selected: false,
                handle_back_Selected: false,
                handle_park_Selected: false,
                handle_unison_Selected: false
            });
        }
    }

    private parkOptionChanged(event: any) {
        if (event.target.checked)
        {
            this.setState({
                handle_park_Selected: true,
                handle_other_Selected: false,
                handle_back_Selected: false,
                handle_test_Selected: false, 
                handle_unison_Selected: false
            });
        }
    }

    private unisonOptionChanged(event: any) {
        if (event.target.checked)
        {
            this.setState({
                handle_park_Selected: false,
                handle_other_Selected: false,
                handle_back_Selected: false,
                handle_test_Selected: false, 
                handle_unison_Selected: true
            });
        }
    }

    private sendOther() {
        if (this.currentRevision!=undefined && this.state.remissTarget!=undefined)
        {
            this.setState({remissLoading: true, revisionMessage: undefined});

            fetch('api/revision/sendto?id=' + this.currentRevision
                + "&userid=" + this.state.remissTarget.userid ,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
                })
                .then(response => response.json() as Promise<Api.RevisionResponse>)
                .then(data => {
                    
                    this.setState({ revisionMessage: data, remissLoading: false});
                    
                    if (data.success) {
                        App.publish("revision.reload",{});
                    }

                }).catch(reason => {
                    this.setState({ remissLoading: false });
                });
        }
    }

    private sendUnison() {
        if (this.currentRevision!=undefined )
        {
            this.setState({remissLoading: true, revisionMessage: undefined});

            fetch('api/revision/unison?id=' + this.currentRevision,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
                })
                .then(response => response.json() as Promise<Api.RevisionResponse>)
                .then(data => {
                    
                    this.setState({ revisionMessage: data, remissLoading: false});
                    
                    if (data.success) {
                        App.publish("revision.reload",{});
                    }

                }).catch(reason => {
                    this.setState({ remissLoading: false });
                });
        }
    }

    private bounce() {
        if (this.currentRevision!=undefined)
        {        
            this.setState({remissLoading: true, revisionMessage: undefined});

            fetch('api/revision/bounce?id=' + this.currentRevision 
                + "&message=" + this.state.handle_back_question ,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
                })
                .then(response => response.json() as Promise<Api.RevisionResponse>)
                .then(data => {
                    
                    this.setState({ revisionMessage: data, remissLoading: false });

                    if (data.success) {
                        App.publish("revision.reload",{});
                    }

                }).catch(reason => {
                    this.setState({ remissLoading: false });
                });
        }
    }

    private approve() {
        if (this.currentRevision!=undefined)
        {        
            this.setState({remissLoading: true, revisionMessage: undefined});

            fetch('api/revision/approve?id=' + this.currentRevision ,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
                })
                .then(response => response.json() as Promise<Api.RevisionResponse>)
                .then(data => {
                    
                    this.setState({ revisionMessage: data, remissLoading: false  });

                    if (data.success) {
                        App.publish("revision.reload",{});
                    }

                }).catch(reason => {
                    this.setState({ remissLoading: false });
                });
        }
    }

    private parkTask() {
        if (this.currentRevision!=undefined)
        {
            this.setState({remissLoading: true, revisionMessage: undefined});

            fetch('api/revision/parkrevision?id=' + this.currentRevision
                + "&message=" + this.state.handle_park_reason ,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
                })
                .then(response => response.json() as Promise<Api.RevisionResponse>)
                .then(data => {
                    console.info(data);

                    this.setState({ revisionMessage: data, remissLoading: false });
                    if (data.success) {
                        App.publish("revision.reload",{});
                    }

                }).catch(reason => {
                    this.setState({ remissLoading: false });
                });
        }
    }

    render() {
        
        console.info(this.props.revisionID);

        if (this.props.revisionID!=undefined) {
            this.currentRevision = this.props.revisionID;
        }

        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            {this.state.revisionMessage!= undefined ? 
                <Alert color={this.state.revisionMessage.success ? "success" : "danger"}>
                <span className="iconic iconic-check"></span>     
                <span>
                {'  '}{lang.Text(this.state.revisionMessage.message)}
                </span>
            </Alert> 
            : this.renderHandlePanel(lang)}
            </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private onReasonChange(event: any) {
        this.setState({handle_park_reason: event.target.value});
    }

    private onQuestionChange(event: any) {
        this.setState({handle_back_question: event.target.value});
    }

    private remissTargetChange(event: any) {
        let targetId: string = event.target.value;
        if (this.state.remissConfig!=undefined) {

            if (targetId.length>0 && targetId != 'none') {
                this.state.remissConfig.revisionTargets.forEach(element => {
                    if (element.isActive && element.userid==targetId){
                        this.setState({remissTarget: element});
                    }
                });
            }
            else {
                this.setState({remissTarget: undefined})
            }
        }
    }

    private renderHandlePanel(lang: Language) {
        return (<React.Fragment>
        {this.state.remissLoading ? <Loading loading></Loading>
            :
            <React.Fragment>
            <Alert color="primary">
                <span className="iconic iconic-info"></span>     
                <span>
                {'  '}  {lang.Text('revision.handle.description')}
                </span>
            </Alert>
                    <Form >

                <FormGroup>
                <div>
                    <CustomInput type="checkbox" id="rev1" name="custom1" 
                        checked={this.state.handle_other_Selected}
                        onChange={this.otherOptionChanged.bind(this)}
                        label={lang.Text('revision.send.option')}>
                    <p>
                       {lang.Text('revision.send.description')} 
                    </p>
                    {this.state.handle_other_Selected ? <Fade>
                        <FormGroup>
                            <Label for="exampleCustomSelect">{lang.Text('revision.send.to')}: </Label>
                            <CustomInput type="select" id="remissCustomSelect" name="remissSelect"
                                onChange={this.remissTargetChange}
                                >
                                <option value='none'>{lang.Text('task.remiss.choose')}</option>
                                {this.state.remissConfig !=undefined ? 
                                    this.state.remissConfig.revisionTargets.map(target =>
                                    <option key={target.userid} value={target.userid} >
                                        {target.name} ({target.area})
                                    </option>
                                ) : <React.Fragment></React.Fragment> }
                            </CustomInput>
                        </FormGroup>
                        <FormGroup>
                            <Button color="success" onClick={this.sendOther} className="btn--pill">{lang.Text('revision.send.btn')}</Button>
                        </FormGroup>
                    </Fade> : <React.Fragment></React.Fragment>}
                    </CustomInput>
                                <CustomInput type="checkbox" id="rev5" name="custom5"
                                    checked={this.state.handle_unison_Selected}
                                    onChange={this.unisonOptionChanged.bind(this)}
                                    label={lang.Text("revision.unison.option")}>
                        <p>
                            {lang.Text('revision.unison.description')}
                        </p>
                        {this.state.handle_unison_Selected ? <Fade>     
                            <FormGroup>
                                <Button color="success" onClick={this.sendUnison} className="btn--pill">{lang.Text('revision.unison.send')}</Button>
                            </FormGroup>
                        </Fade> : <React.Fragment></React.Fragment>}
                    </CustomInput>
                    <CustomInput type="checkbox" id="rev2" name="custom2"
                        checked={this.state.handle_back_Selected}
                        onChange={this.backOptionChanged.bind(this)} 
                        label={lang.Text('revision.bounce.option')} >
                    <p>
                        {lang.Text('revision.bounce.description')}
                        
                    </p>
                    {this.state.handle_back_Selected ? <Fade>
                        <FormGroup>
                            <Label for="exampleText">{lang.Text('revision.bounce.message')}:</Label>
                            <Input type="textarea" name="text" id="exampleText" 
                            value={this.state.handle_back_question}
                            onChange={this.onQuestionChange.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button color="success" onClick={this.bounce} className="btn--pill">{lang.Text('revision.bounce.btn')}</Button>
                        </FormGroup>
                    </Fade> : <React.Fragment></React.Fragment>}
                    </CustomInput>
                    <CustomInput type="checkbox" id="rev3" name="custom3" 
                         checked={this.state.handle_test_Selected}
                         onChange={this.testOptionChanged.bind(this)} 
                        label={lang.Text('revision.test.option')} >
                    <p>
                        {lang.Text('revision.test.description')}
                       
                    </p>
                    {this.state.handle_test_Selected ? <Fade>
                        <FormGroup>
                            <Button color="warning" onClick={this.approve}
                            className="btn--pill">{lang.Text('revision.test.btn')}</Button>
                        </FormGroup>
                        </Fade> : <React.Fragment></React.Fragment>}
                    </CustomInput>
                    
                    <CustomInput type="checkbox" id="rev4" name="custom4"
                         checked={this.state.handle_park_Selected}
                         onChange={this.parkOptionChanged.bind(this)} 
                        label={lang.Text('revision.park.option')}
                        // disabled={this.checkParked(this.currentRevision)} 
                        >
                    <div>
                    <p>
                    {lang.Text('revision.park.description')}
                       
                    </p>
                    {this.state.handle_park_Selected ? <Fade>
                        <FormGroup>
                            <Label for="exampleText"> {lang.Text('revision.park.reason')}:</Label>
                            <Input type="textarea" name="text" id="exampleText" 
                                onChange={this.onReasonChange.bind(this)}
                                value={this.state.handle_park_reason}/>
                        </FormGroup>
                        <FormGroup>
                            <Button color="success" onClick={this.parkTask} className="btn--pill"> {lang.Text('revision.park.btn')}</Button>
                        </FormGroup>
                    </Fade> : <React.Fragment></React.Fragment>}
                    </div>
                    </CustomInput>
                </div>
                </FormGroup>

            </Form>
            </React.Fragment>}
        </React.Fragment>);
    }
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button } from 'reactstrap';

export interface InfoLinkState {
    loading: boolean
}

export interface ComponentOnChangeEvent {
    message: string
}

export interface InfoLinkProps {
    title: string
    keyword: string
}

export class InfoLink extends React.Component<InfoLinkProps, InfoLinkState> {
    constructor(props:InfoLinkProps) {
        super(props);
        
        this.state = {
            loading: false
        };

        this.linkClick= this.linkClick.bind(this);
    }

    private linkClick() {
        App.publish("ref.open", this.props.keyword);
    }

    render() {
        return <React.Fragment>
        {/* <LanguageContext.Consumer>
        { lang => ( <div> */}
           <Button style={{paddingTop: '0px',paddingLeft: '3px'}} color="link" onClick={this.linkClick}>{this.props.title} {'  '}
            <span className="iconic iconic-info"></span>
           </Button> 
        {/* </div>)}
        </LanguageContext.Consumer> */}
        </React.Fragment>
    }
}

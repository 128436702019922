import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 225.56 40.37" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#ff4338}.prefix__cls-2{fill:#00b3e3}.prefix__cls-3{fill:#002f32}.prefix__cls-4{fill:#ffc843}.prefix__cls-5{fill:#05ce7c}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            fill="#ff4338"
            d="M31.83 0h-8.71v10.9a5.43 5.43 0 01-2.85 4.58v7.66c6.84-1.56 11.55-6.55 11.56-12.24V0M8.71 0H0v10.9c0 5.69 4.73 10.68 11.56 12.24v-7.62a5.45 5.45 0 01-2.85-4.58V0"
          />
          <path

            fill="#00b3e3"
            d="M20.27 23.18a19.41 19.41 0 01-8.71 0v17.15h8.71V23.18"
          />
          <path
            fill="#002f32"
            d="M20.27 15.53a8.64 8.64 0 01-8.71 0v7.65a19.41 19.41 0 008.71 0v-7.65"
          />
          <path

            fill="#ffc843"
            d="M53.61 0c-9.24 0-16.74 6-16.75 13.4v13.5c0 7.4 7.5 13.39 16.75 13.39s16.75-6 16.75-13.39V13.42C70.35 6 62.86 0 53.61 0m0 33.33c-4.44 0-8-2.88-8-6.43V13.42c0-3.55 3.6-6.43 8-6.43s8 2.88 8 6.43v13.5c0 3.55-3.6 6.42-8 6.43"
          />
          {/* <path

            fill="#05ce7c"
            d="M107.2 27.6c0 4.15-2.53 8-6.78 10.42a9 9 0 006.21 2.31H111v-7h-3.8V27.6"
          /> */}
          <path
            d="M84.09 0h-8.71v27.6c0 4.75 3.3 9.1 8.57 11.29a19.3 19.3 0 0016.47-.89 5.76 5.76 0 01-1.93-4.2v-6.2c0 3.17-3.24 5.72-7.2 5.72s-7.17-2.55-7.2-5.72V0"
            fill="#da3ab3"
          />
          <path
            d="M107.2.05h-8.71v33.77a5.76 5.76 0 001.93 4.2c4.25-2.38 6.78-6.27 6.78-10.42V.05"
            fill="#042f57"
          />
          <path
            d="M132 0c-9.25 0-16.74 6-16.75 13.4v13.49c0 7.4 7.5 13.4 16.75 13.4s16.75-6 16.75-13.4h-8.71c0 3.56-3.6 6.44-8 6.44s-8-2.88-8-6.44V13.4c0-3.55 3.6-6.43 8-6.43s8 2.88 8 6.43h8.71C148.75 6 141.26 0 132 0"
            fill="#ff6b00"
          />
          <path
            fill="#00b3e3"
            d="M171.23.08c-9.23 0-16.71 6-16.72 13.38v26.91h8.71V26h-8.71v-7h8.71v-5.54c0-3.54 3.58-6.41 8-6.41s8 2.87 8 6.41v5.61H188v-5.61C188 6.07 180.51.09 171.28.08M188 26h-8.71v14.33H188V26"
          />
          <path

            fill="#ff4338"
            d="M179.24 19.07h-16.02v6.97h16.02v-6.97"
          />
          <path

            fill="#002f32"
            d="M188 19.07h-8.71v7H188v-7m-24.73 0h-8.71v7h8.71v-7"
          />
          <path

            fill="#ffc843"
            d="M192.24.02v6.97h3.78v33.32h8.71V23.65h-8.71v-6.97h8.71V6.99h-12.49V.02"
          />
          <path
            fill="#05ce7c"
            d="M210.79 0h-14.2c4.49 0 8.13 2.92 8.14 6.51V7h6.06c3.35 0 6.06 2.17 6.06 4.84s-2.71 4.85-6.06 4.85h-6.06v7h6.06c8.16 0 14.77-5.29 14.77-11.82S219 0 210.79 0"
          />
          <path
            d="M196.59 0h-4.35v7h12.49v-.47c0-3.59-3.65-6.5-8.14-6.51m8.14 16.66H196v7h8.71v-7"
            fill="#05a221"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

import * as React from 'react';
import * as App from 'pubsub-js';

import './panel.css';

import { ContentItem } from '../api/Content';

import ReactMarkdown from 'react-markdown';


import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container } from 'reactstrap';
import { AppCore } from '../backbone/AppCore';
import { Language } from '../backbone/Language';

const moment = require("moment");

export interface ContentPanelState {
    modal: boolean,
    loading: boolean,
    content?: ContentItem;
    contentID: string;
    contentType: string,
    locale: string,
    preview: boolean,
    
}

export interface ContentData {
    id?: string;
    locale?: string;
    preview?: boolean,
}

export interface ContentPanelProps {
    contentId: string,
    contentType?: string,
    type?: string,
    width: string,
    cancelButtonLabel?: string,
    modalPanelOpen?: string,
    position?: string ,
    onClosed?: (()=> void),
    preview?: boolean,
    lastUpdated: string,
    color?: string,
}

export class ContentPanel extends React.Component<ContentPanelProps, ContentPanelState> {
    constructor(props:any) {
        
        super(props);

        this.state = {
            modal: false,
            loading: false,
            locale: Language.GetLocale(),
            contentID: this.props.contentId,
            contentType: this.props.contentType? this.props.contentType :  'internal',
            preview: this.props.preview ? true : false
        };
        
        this.close = this.close.bind(this);
    }


    public componentDidMount() {
        App.subscribe(this.props.modalPanelOpen,
            this.open.bind(this));

        
    }

    public componentWillUnmount() {
        App.unsubscribe(this.open.bind(this));
    }

    open(msg: string, data: ContentData) {

        let contentId = this.state.contentID;
        if (data!=undefined && data.id!=undefined)
        {
            contentId = data.id;
        }

        
        //Load data
        if (this.state.contentType=='contentful' && contentId.length>0) {
            fetch('api/content/getcontent?id=' + contentId + '&locale=' + this.state.locale 
                + '&preview=' + this.state.preview, 
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<ContentItem>)
                .then(data => {    
                    // console.log(data);
                    this.setState({content: data, loading: false});
                }).catch(reason => {
                    console.log(reason);
                    this.setState({loading: false});
            });
        }
        else if (this.state.contentType=='internal' && contentId.length>0)
        {
            fetch('api/content/getinternalcontent?id=' + contentId,
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<ContentItem>)
                .then(data => {    
                    // console.log(data);
                    this.setState({content: data, loading: false});
                }).catch(reason => {
                    console.log(reason);
                    this.setState({loading: false});
            });
        }
        this.setState({
            modal: true
        });
    }

    close() {
        this.setState({
            modal: false
        });
    }

    render() {
            return (
            <React.Fragment>
            <Modal isOpen={this.state.modal} toggle={this.close} 
                contentClassName="modal-panel-content yc-panel-dynamic"
                modalClassName={this.props.position} onClosed={this.props.onClosed}>
                     
                    <div className="Backnine-navbar-panel modal-header">
                        <div className="p-2 flex-grow-1 bd-highlight" 
                            style={this.props.color ? {borderLeft: '10px solid ' + this.props.color, marginLeft: '-16px'}:{}}
                            >
                            <h1>{this.state.content? this.state.content.title : '' }</h1>
                            <div >
                                {this.state.content? this.state.content.shortDescription : '' }
                            </div>
                        </div>
                        <div className="p-2 bd-highlight">
                                                           
                        </div>
                        <button type="button" onClick={this.close} className="close" aria-label="Close"><span aria-hidden="true">×</span>
                        </button>
                    </div>
                                        
                    <ModalBody style={{overflowY: 'auto', overflowX: 'hidden'}}>
                        <Container  className="yc-panel-xl" style={{ paddingRight: '20px', paddingLeft: '20px'}}>
                            <Row>
                                {this.props.children}
                            </Row>
                           <Row style={{marginTop: '10px'}}>
                            <ReactMarkdown source={this.state.content?.copy} />
                           </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter >
                    <div className="p-2 flex-grow-1 bd-highlight float-left" style={{height: '45px'}}>
                    <div className="p-2 bd-highlight align-self-end ">
                        <p style={{fontSize: '0.8em', color: 'darkgray'}}>
                           {this.props.lastUpdated}  {moment(this.state.content?.sys.updatedAt).format('YYYY-MM-DD HH:mm')}
                        </p>                                
                        </div>
                    </div>
                    <button className="btn btn-link"
                        onClick={this.close}>{ this.props.cancelButtonLabel }</button>
                    </ModalFooter>
            </Modal>
            </React.Fragment>);
    }
}

import React from 'react'
import { Component } from 'react'
import { Route, Redirect, Link } from 'react-router-dom'

import { GlobalAppsettings } from './GlobalSettings';
import * as App from 'pubsub-js';
import {AppCore, AuthEventInfo} from './AppCore';
import { Nav, NavLink, Button } from 'reactstrap';



export interface AuthorizeButtonState {
    ready: boolean,
    authenticated: boolean
}

export interface AuthorizeButtonProps {
    to: string
    color: string
    className?: string
    showLock: boolean
    waitForLogin?: boolean
    redirectToLogin: boolean
    active?: boolean
    tag?: string | React.ComponentClass<any, any> | React.FunctionComponent<any> | undefined
}

export class AuthorizeButton extends Component<AuthorizeButtonProps,AuthorizeButtonState> {
    constructor(props: AuthorizeButtonProps) {
        super(props);

        let r = true;
        if (props.waitForLogin) r = false; 

        this.state = {
            ready: r,
            authenticated: false
        };
    }

    componentDidMount() {
        App.subscribe('app.auth-changed',this.authChanged.bind(this))
    }

    componentWillUnmount() {
        App.unsubscribe('app.auth-changed');
    }

    private authChanged(msg: string, data: AuthEventInfo) {
        if (data!=undefined)
        {
            this.setState({ ready: true, authenticated: data.IsAuthenticated });
        }
        else
        {
            this.setState({ ready: false, authenticated: false });
        }
    }

    render() {
        if (!this.state.ready) {
            return <React.Fragment></React.Fragment>;
            } else {
                if (this.state.authenticated) {
                    return <Button className={this.props.className} 
                            style={{marginTop: '5px'}} 
                            color={this.props.color}
                            href={this.props.to}>{this.props.children}</Button>
                } else {
                    if (this.props.showLock) {
                        return <Button className={this.props.className} 
                                style={{marginTop: '5px'}} 
                                disabled={!this.props.redirectToLogin}
                                color={this.props.color} 
                                onClick={() => {
                                    if (this.props.redirectToLogin) App.publish('user.login',{redirect: this.props.to})
                                }}>
                                <span style={{color: 'darkgray'}}>
                                    <i className="fa-duotone fa-lock fa-xl"></i>
                                    {this.props.children}
                                </span>
                                </Button>    
                    }
                    else {
                    return <React.Fragment></React.Fragment>
                    }
                }
            }
    
    }
}

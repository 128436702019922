import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container, Row, Col, CustomInput, UncontrolledCollapse, Badge } from 'reactstrap';
import { LanguageContext, Language } from '../../backbone/Language';
import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';

import {ProductLogo} from '../../components/ProductLogo';
import { Line } from 'react-chartjs-2';
import { InfoPanel } from '../../panels/InfoPanel';
import { ModalPanel } from '../../panels/ModalPanel';
import { CollapseList, CollapseListItem } from '../../components/CollapseList';
import { User } from '../../api/User';
import { Application } from '../../api/App';
import { AppCore } from '../../backbone/AppCore';
import { Loading } from '../../components/base/Loading';

import moment from "moment";

import teams_logo from "../../theme/img/teams_logo.png";

export interface TeamsModuleState {
    loading: boolean
    noData: boolean
    text: string
    title: string
    isDirty: boolean
    user: User.UserProfile
    app:  Application.AppProfile
    data: any
}

export interface ComponentOnChangeEvent {
    message: string
}

export interface TeamsModuleProps {
    title?: string
    text?: string
    onChange?: ((state: ComponentOnChangeEvent)=> void)
}

export class TeamsModule extends React.Component<TeamsModuleProps, TeamsModuleState> {
    static displayName = 'TeamsModule';
    static contextType = LanguageContext;
    constructor(props:TeamsModuleProps) {
        super(props);
        
        let _title = props.title!=undefined ? props.title: '';
        let _text= props.text!=undefined ? props.text: '';

        this.state = {
            title: _title,
            isDirty: false,
            noData: false,
            text: _text,
            loading: false,
            user: AppCore.GetUserProfile(),
            app: AppCore.GetAppProfile(),
            data: [{title: 'Test Meeting',description: 'description', url: 'url'}]
        };

        this.renderModule = this.renderModule.bind(this);
    }

    componentDidMount() {
        App.subscribe("add.item", this.viewItem.bind(this));

        //Example fetch for data
        //Todo: replace with real 
        // fetch('api/content/test', GlobalAppsettings.GetRequestInit())
        // .then(response => response.json() as Promise<ContentItem>)
        // .then(data => {
        //     // Track Event    
        //     App.publish('track-event', {name: 'event.name', properties:{
        //         'SomePropery': 'Property',
        //         'Title': data.title
        //     }});

        //     //Todo: Handle data

        // }).catch(reason => {
        //     //Track Exception
        //     App.publish('track-exception', {error: reason});
        // });
    }

    componentWillUnmount() {
      App.unsubscribe("add.item");
    }

    private viewItem(msg: string, data: any)
    {
      
        this.setState({isDirty: true});
    }

    private onItemChange() {
        
        this.setState({isDirty: true});
    }

    private onItemSave() {
      //Save data
      //Todo: replace with real 
      // fetch('api/content/test', GlobalAppsettings.GetRequestInit())
      // .then(response => response.json() as Promise<ContentItem>)
      // .then(data => {
      //     // Track Event    
      //     App.publish('track-event', {name: 'event.name', properties:{
      //         'SomePropery': 'Property',
      //         'Title': data.title
      //     }});

      //     //Todo: Handle data

      // }).catch(reason => {
      //     //Track Exception
      //     App.publish('track-exception', {error: reason});
      // });
      this.setState({isDirty: false});
  }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            <Row>
                <Col md="12">
                <img src={teams_logo} style={{ paddingBottom: '5px', width: '85px',marginTop: '-18px' }}/>
                <Button color="secondary" size="sm" outline className="btn--pill float-right"
                                    onClick={() => App.publish('teams.settings',{})} >
                    <i className="fa fa-cog"/>
                    </Button>
                </Col>
                </Row>
                <Row>
                <Col md="12"> <p style={{color: '#6264a7'}}>Microsoft Teams <Badge color="secondary" style={{fontSize: '11px'}} pill>Beta</Badge></p></Col>
            </Row>
          
          {this.state.loading ? <Loading loading={true}/>: 
                    this.state.noData ? <p className="no-data">
                        <i className="fa fa-umbrella-beach"/>{'   '} {lang.Text('no.data')}</p>
          : this.renderModule(lang)}

          <InfoPanel title={lang.Text('workset settings')} cancelButtonLabel={lang.Text('btn.close')} 
              modalPanelOpen="example.settings"
              position="modal-panel-right"
              showHeader={false}>
             <div className="yc-panel-md">
              <Container>
                  <Row>
                  <Col>
                  
                  </Col>
                  </Row>
              </Container>
              </div>
          </InfoPanel>

          <ModalPanel title={lang.Text('add.example')} 
              okButtonLabel={lang.Text('btn.save')}
              cancelButtonLabel={lang.Text('btn.cancel')}
              leaveButtonLabel={lang.Text('btn.leave')}
              unsavedChangesText={lang.Text('modal.unsaved.changes')}
              isDirty = {this.state.isDirty} 
              // showHeader={false}
              modalPanelOpen="add.example"
              onSave={() => this.onItemSave()}
              position="modal-panel-right">
             <div className="yc-panel-md">
              <Container>
              <Row>
                  <Col>
                      <p>Add/Edit Example Form...</p>
                  </Col>
              </Row>
              </Container>
              </div>
          </ModalPanel>

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    
    renderModule(lang: Language) {
    
        return (<React.Fragment>
        <Row>
            <Col>
            <CollapseList id="teamsitemlist" items={this.getSessionLinks(this.state.data ,lang)} lang={lang}/>
            </Col>
        </Row>

        {/* <Row style={{marginTop: '10px'}}>
            <Col>
            <Button color="dark" outline className="btn--pill" 
                onClick={() => App.publish("add.example", {tab: 'organisation'})}>
                    <i className="fas fa-plus" style={{marginRight: '5px'}}></i>
                    {lang.Text('add.examle')}</Button>
            </Col>
        </Row> */}
        </React.Fragment>
        );
    }

    private view(item: any) {

    }

    private getSessionLinks(links: any[], lang: Language) : CollapseListItem[]  {
    
      var list: CollapseListItem[] = new Array<CollapseListItem>();
   
    //    links.forEach((item) => {
    //        list.push(
    //        { title: <React.Fragment> 
    //           <strong>{item.title}</strong></React.Fragment>, 
    //          content: <React.Fragment>
    //                <p className="mb-1">{item.description}</p>
    //                {/* <p className="mb-1">{item.type}</p> */}
   
    //                <Button color="link" href={item.url}>{lang.Text('link.open.url')} {' '}<i className="fa fa-external-link-alt"/></Button>
   
    //                <small>{moment().format('YYYY-MM-DD HH:mm')}</small>
    //                <Button color="link" className="float-right" onClick={() => this.view(item)} >{lang.Text('btn.open')}
    //                </Button>
    //            </React.Fragment>,  }
    //        );
    //    });
   
       return list;
     }

}

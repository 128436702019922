import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container, Row, Col, CustomInput, UncontrolledCollapse, Badge, Progress } from 'reactstrap';
import { LanguageContext, Language } from '../../backbone/Language';
import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';

import {ProductLogo} from '../../components/ProductLogo';
import { Line } from 'react-chartjs-2';
import { InfoPanel } from '../../panels/InfoPanel';
import { ModalPanel } from '../../panels/ModalPanel';
import { CollapseList, CollapseListItem } from '../../components/CollapseList';
import { User } from '../../api/User';
import { Application } from '../../api/App';
import { AppCore } from '../../backbone/AppCore';
import { Loading } from '../../components/base/Loading';

import {EduAdminApi } from './Api/EduAdmin';

import EduAdmin_logo from "../../theme/img/EduAdmin_logo.png";

import moment from "moment";
import { ExamItem } from '../api/Exam';
import { GetAllTestsResult } from '../api/Responses';

import { SecureTestApi } from '../SecureTest/Api/SecureTestApi';

export interface EduAdminModuleState {
    loading: boolean
    loadingResult: boolean
    loadingReport: boolean
    loadingHistory: boolean
    noData: boolean
    error: string
    errorHistory: string
    text: string
    title: string
    isDirty: boolean
    reportResult?: string[]
    exams?: ExamItem[]
    selectedExam?: ExamItem
    user: User.UserProfile
    app:  Application.AppProfile
    data?: EduAdminApi.EduAdminSessions
    history?: EduAdminApi.EduAdminSessions
    current?: EduAdminApi.Session
    results?: SecureTestApi.SessionInfo[]
    isCurrentHistory: boolean
}

export interface ComponentOnChangeEvent {
    message: string
}

export interface EduAdminModuleProps {
    title?: string
    text?: string
    onChange?: ((state: ComponentOnChangeEvent)=> void)
}

export class EduAdminModule extends React.Component<EduAdminModuleProps, EduAdminModuleState> {
    static displayName = 'EduAdminModule';
    static contextType = LanguageContext;
    constructor(props:EduAdminModuleProps) {
        super(props);
        
        let _title = props.title!=undefined ? props.title: '';
        let _text= props.text!=undefined ? props.text: '';

        this.state = {
            title: _title,
            isDirty: false,
            noData: false,
            error: '',
            errorHistory: '',
            text: _text,
            loading: true,
            loadingResult: false,
            loadingReport: false,
            loadingHistory: false,
            user: AppCore.GetUserProfile(),
            app: AppCore.GetAppProfile(),
            isCurrentHistory: false,
        };

        this.renderModule = this.renderModule.bind(this);
    }

    componentDidMount() {
        this.getExams();
        this.updateData();
    }

    private getExams() {
       
        fetch('api/secureteststore/getAllTests', GlobalAppsettings.GetRequestInit())
        .then(response => response.json() as Promise<GetAllTestsResult>)
        .then(data => {
            
            if (data.success) {
                this.setState({
                    exams: data.exams,
                    loading: false
                });

                // if (this.state.exams !=undefined && this.state.exams.length > 0) {
                    
                //     console.info("-> selectedExam");
                //     this.setState({ selectedExam: this.state.exams[0] });
                // }
            }

            this.setState({
                loading: false
            });
        })
        .catch(reason => {
            //Track Exception
            App.publish('track-exception', { error: reason });
            this.setState({
                loading: false
            });
        });
    }

    componentWillUnmount() {
    
    }

    private viewItem(msg: string, data: any)
    {
      
        this.setState({isDirty: true});
    }

    private onItemChange() {
        
        this.setState({isDirty: true});
    }

    private showResult() 
    {
        App.publish("eduadmin.showresult",{});

        this.setState({loadingResult: true, results: undefined});

        fetch('api/eduadmin/GetSessionResults?id=' + this.state.current?.id, GlobalAppsettings.GetRequestInit())
        .then(response => response.json() as Promise<SecureTestApi.SessionInfo[]>)
        .then(data => {
            this.setState({results: data, loadingResult: false});
        })
        .catch(reason => {
            //Track Exception
            App.publish('track-exception', { error: reason });
            this.setState({loadingResult: false, results: undefined});
        });
    }

    

    private copyLinktoClipboard(text: string) {
        if (!navigator.clipboard) {
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
        }, function(err) {
        });
    }

    private updateData() {

        let currentSessionId = this.state.current?.id;
        this.setState({loading: true});
        
        fetch('api/eduadmin/GetCurrentSessions', GlobalAppsettings.GetRequestInit())
            .then(response => response.json() as Promise<EduAdminApi.EduAdminSessions>)
            .then(data => {
                if (data.success) {
                    let currentSession: EduAdminApi.Session | undefined;
                    
                    let selectedTestId = '';

                    data.sessions.forEach((item) => {
                        if (item.id===currentSessionId)
                        {
                            currentSession = item;
                        }
                    });

                    if (currentSession !=undefined && currentSession.participants.length > 0)
                    {
                        selectedTestId = currentSession?.participants[0].testId;
                    }

                    if (selectedTestId.length> 0)
                    {
                        console.info("-> selectedTest: " + selectedTestId);
                    }

                    this.setState({
                        data: data,
                        current: currentSession,
                        noData: data.sessions.length === 0,
                        loading: false,
                    });
                }
                else
                {
                    this.setState({ loading: false, error: data.errorMessage });
                    App.publish('track-exception', { error: data.errorMessage });
                }
                
            }).catch((reason) => {
                this.setState({ loading: false});
                App.publish('track-exception', { error: reason });
            });
    }

    private createCodes() {

        this.setState({ loading: true});

        fetch('api/eduadmin/AddSessionCodeForParticipants?sessionid=' + this.state.current?.id
        + '&testid=' + this.state.selectedExam?.id 
        , GlobalAppsettings.GetPostInit(null))
            .then(response => response.json() as Promise<string>)
            .then(data => {

                this.updateData();                

            }).catch((reason) => {
                
                this.setState({ loading: false});
                App.publish('track-exception', { error: reason });
            });
    }

    private onItemSave() {
        
        this.setState({isDirty: false});
    }

    selectedTestChange(e: any) {
        var exam = this.state.exams?.filter(exam => exam.id == e.target.value)[0];
        if (exam != undefined && exam != null)
            this.setState({ selectedExam: exam });
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
          <Row>
            <Col>
                <img src={EduAdmin_logo} style={{  width: '102px' }} />
                    <Badge color="secondary" style={{fontSize: '11px'}} pill>1.0</Badge>
            </Col>
            <Col md="2" 
            // style={{padding: '20px'}}
            >
              <Button color="secondary" size="sm" outline className="btn--pill float-right"
                      onClick={() => {this.updateData();}} style={{}}>
                <i className="fa fa-redo"/>
              </Button>
            </Col>
          </Row>

          {this.renderModule(lang)}


          <InfoPanel title={lang.Text('eduadmin.view.session')} cancelButtonLabel={lang.Text('btn.close')} 
              modalPanelOpen="eduadmin.view.session"
              position="modal-panel-right"
              showHeader={false}>
              <div className="yc-panel-xl">
              <Container fluid>
                <Row>
                  <Col>
                  <img src={EduAdmin_logo} style={{ paddingBottom: '25px', width: '102px' }} />
                    <Badge color="secondary" style={{fontSize: '11px'}} pill>1.0</Badge>
                 </Col>
                </Row>
                <Row >
                   <Col sm="9">
                    <span className="text-nowrap">
                    <h4>{this.state.current?.title}</h4> </span>
                    <small>{moment(this.state.current?.eventDate).format('YYYY-MM-DD HH:mm')}</small>
                  </Col>
                  <Col>
                    <a href={"https://www.eduadmin.se/lc/event/eventBookings?eID=" + this.state.current?.eduAdminId}>
                    <small>{lang.Text('eduadmin.goto')}: {this.state.current?.eduAdminId}</small>
                    </a>
                   </Col>
                </Row>
                <Row>
                  <Col sm="4">
                  {/* <p><small>Välj prov:</small></p> */}
                    <CustomInput id="customer.exams"  type="select" value={this.state.selectedExam?.id} onChange={(e) => 
                            this.selectedTestChange(e)}>
                        {
                            this.state.exams?.map((exam) => {
                                return (<option value={exam.id} key={exam.id}>{exam.name}</option>);
                            })
                        }
                    </CustomInput >

                    <Button style={{marginTop: '5px'}} color="success" onClick={() => this.createCodes()}>
                         <i style={{fontSize: '17px'}} className="fa fa-check-circle "/> {lang.Text('eduadmin.activate.all')}
                    </Button>
                  </Col>
                  <Col>
                    <p></p>
                  </Col>
                  <Col sm="1">
                        <Button color="secondary" size="sm" outline className="btn--pill float-right"
                            onClick={() => {this.updateData()}} style={{}}>
                            <i className="fa fa-redo"/>
                        </Button>
                    </Col>
                </Row>
                
                <hr/>
                <Row>
                    <Col>
                    {this.state.loading ? <Loading loading={true}/>: 
                    <CollapseList id="participantlist" items={this.getPaticipants(lang, this.state.current?.participants)} lang={lang}/>}
                    </Col>
                </Row>
                <hr/>
                <Row className="p-1">
                  <Col sm="5">
                    <Button color="link" onClick={() => this.showResult()}>
                          {lang.Text('eduadmin.show.result')}
                    </Button>
                  </Col>
                </Row>
                <Row className="p-1">
                        <Col sm="5">
                            <Button color="link" onClick={() => this.reportResult()}>
                                <i style={{fontSize: '17px', color: 'dark-gray'}} className="fa fa-flag"/> {lang.Text('eduadmin.report.result')}
                            </Button>
                        </Col>
                    </Row>
                <Row>
                  
                   <Col>
                    <small style={{color: 'lightgray'}}>{this.state.current?.id}</small>
                   </Col>
                    
                </Row>
              </Container>
              </div>
          </InfoPanel>

          <InfoPanel title="" 
                cancelButtonLabel={lang.Text('btn.cancel')}
                showHeader={false}
                modalPanelOpen="eduadmin.showresult"
                position="modal-panel-right"
                >
                <Container fluid className="yc-panel-md">
                <Row>
                  <Col>
                  <img src={EduAdmin_logo} style={{ paddingBottom: '25px', width: '102px' }} />
                    <Badge color="secondary" style={{fontSize: '11px'}} pill>1.0</Badge>
                 </Col>
                </Row>
                <Loading loading={this.state.loadingResult}>
                <Container fluid>
                    <Row>
                        <Col>
                        <CollapseList id="sessionResultlist" items={this.getResults(lang,this.state.results)} lang={lang}/>
                    </Col>
                    </Row>
                    
                </Container>
                </Loading>
                </Container>
            </InfoPanel>

            <InfoPanel title="" 
                cancelButtonLabel={lang.Text('btn.cancel')}
                showHeader={false}
                modalPanelOpen="eduadmin.showhistory"
                position="modal-panel-right"
                >
                <Container fluid className="yc-panel-md" style={{width: '650px'}}>
                <Row>
                  <Col>
                  <img src={EduAdmin_logo} style={{ paddingBottom: '25px', width: '102px' }} />
                    <Badge color="secondary" style={{fontSize: '11px'}} pill>1.0</Badge>
                 </Col>
                </Row>
                <Loading loading={this.state.loadingHistory}>
                <Container fluid>
                    <Row>
                        <Col>
                            <CollapseList id="sessionHistorylist" items={this.getHistorySessions(lang,this.state.history?.sessions)} lang={lang}/>
                        </Col>
                    </Row>
                </Container>
                </Loading>
                </Container>
            </InfoPanel>

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    reportResult(){
        
        console.info("isCurrentHistoric: " + this.state.isCurrentHistory);
        
        if (this.state.current!=undefined)
        {
            var id = this.state.current.id;
            this.setState({ loading: true});

            fetch('api/eduadmin/GradeSession?sessionid=' + id
            , GlobalAppsettings.GetPostInit(null))
                .then(response => response.json() as Promise<string>)
                .then(data => {
                
                   
                    this.reloadHistory();
                    
                    this.updateData();
                    

                }).catch((reason) => {
                    this.setState({ loading: false});
                    App.publish('track-exception', { error: reason });
                });
        }
    }
    
    renderModule(lang: Language) {
      return (<React.Fragment>
       
       <Row className="p-2 text-secondary">
                <Col><small>{lang.Text("eduadmin.current.events")}:</small></Col>
            </Row>
        <Loading loading={this.state.loading}>
            {this.state.noData ? <p className="no-data mt-3 mb-3">
            <i className="fa fa-umbrella-beach"/>{'   '} {lang.Text('no.data')}</p>
            :
            <>
            <Row className="mt-3 mb-3">
                <Col>
                    <CollapseList id="itemlist" items={this.getSessions(lang, this.state.data?.sessions)} lang={lang}/>
                </Col>
            </Row>
            </>
            }   
        </Loading>
                    
        <Row className="mt-2">
            <Col>
            <Button color="link" className="" onClick={() => this.showHistory()} >
                    {lang.Text('btn.view.history')}
            </Button>
            </Col>
        </Row>
        </React.Fragment>
        );
    }
    private showHistory(): void {
        App.publish("eduadmin.showhistory",{});

        console.info(this.state.isCurrentHistory);

        this.setState({loadingHistory: true});

        fetch('api/eduadmin/GetHistoricSessions', GlobalAppsettings.GetRequestInit())
            .then(response => response.json() as Promise<EduAdminApi.EduAdminSessions>)
            .then(data => {
                console.info(data);
                if (data.success) {
                    this.setState({
                        history: data,
                        loadingHistory: false,
                    });
                }
                else
                {
                    this.setState({ loadingHistory: false, errorHistory: data.errorMessage });
                    
                }
                
            }).catch((reason) => {
                this.setState({ loading: false});
                App.publish('track-exception', { error: reason });
            });
    }

    private reloadHistory(): void {
        this.setState({loadingHistory: true});

        fetch('api/eduadmin/GetHistoricSessions', GlobalAppsettings.GetRequestInit())
            .then(response => response.json() as Promise<EduAdminApi.EduAdminSessions>)
            .then(data => {
               
                if (data.success) {
                    this.setState({
                        history: data,
                        loadingHistory: false,
                    });
                }
                else
                {
                    this.setState({ loadingHistory: false, errorHistory: data.errorMessage });
                    
                }
                
            }).catch((reason) => {
                this.setState({ loading: false});
                App.publish('track-exception', { error: reason });
            });
    }

    private view(item: any) {

        this.setState({current: item, isCurrentHistory: false});

        App.publish("eduadmin.view.session",{});
    }

    private viewHistoric(item: any) {

        this.setState({current: item, isCurrentHistory: true});
        
        App.publish("eduadmin.view.session",{});
    }

    private getSessions(lang: Language,sessions?: EduAdminApi.Session[]) : CollapseListItem[]  {
    
      var list: CollapseListItem[] = new Array<CollapseListItem>();
   
       sessions?.forEach((item) => {
           list.push(
           { title: <React.Fragment> 
               <strong>{item.title} </strong>
               </React.Fragment>, 
             content: <React.Fragment>
                   <p className="mb-1">{item.info}</p>
                   <p className="mb-1">{moment(item.eventDate).format('YYYY-MM-DD HH:mm')}</p>
                   
                   <Button color="link" className="float-right" onClick={() => this.view(item)} >
                       {lang.Text('btn.open')}
                   </Button>

               </React.Fragment>,
               isOpen: true,  }
           );
       });
   
       return list;
     }

     private getHistorySessions(lang: Language,sessions?: EduAdminApi.Session[]) : CollapseListItem[]  {
    
        var list: CollapseListItem[] = new Array<CollapseListItem>();
     
         sessions?.forEach((item) => {
             list.push(
             { title: <React.Fragment> 
                    <div className="float-left">{moment(item.eventDate).format('YYYY-MM-DD')}</div> 
                    <strong>{item.title}</strong>
                 </React.Fragment>, 
               content: <React.Fragment>
                     <small></small>
                     {/* <a href={"https://www.eduadmin.se/lc/event/eventBookings?eID=" + item.eduAdminId}>
                     <small>{lang.Text('eduadmin.goto.event')}: {item.eduAdminId}</small>
                    </a> */}
                     <Button color="link" className="float-right" onClick={() => this.viewHistoric(item)} >
                         {lang.Text('btn.open')}
                     </Button>
                 </React.Fragment>,
                 isOpen: false,  }
             );
         });
     
         return list;
       }

     private getResults(lang: Language, results?: SecureTestApi.SessionInfo[]): CollapseListItem[] {
        var list: CollapseListItem[] = new Array<CollapseListItem>();
        if (results!=undefined)
        results.forEach((item) => {
            list.push(
                {
                    title: <React.Fragment>
                        <Col md="6"><strong className="float-left">{item.name}</strong> </Col>
                            <Col>
                                {item.result?.passed ? 
                                    <i style={{fontSize: '17px'}} className="fa fa-check-circle text-success float-right"/> 
                                    : <i style={{fontSize: '17px'}} className="fa fa-times-circle text-danger float-right"/> }
                            </Col>
                        </React.Fragment>,
                    content: <React.Fragment>
                         <Col>
                         <p><small>{moment(item.timeStamp).format("YYYY-MM-DD HH:mm")}</small></p>
                         <p><small>{item.email}</small></p>
                         <p><small>{item.title}</small></p>
                         <p><strong>Score: {item.result?.score}</strong></p>
                         <hr/>
                          {item.result?.areas.map((area,i) => {
                              return <React.Fragment key={i}>
                               <p><small> {area.title} - {area.correctCount} / {area.totalCount} </small></p>
                               <Progress multi>
                                    <Progress bar color="success" value={area.correctCount} max={area.totalCount}>{area.correctCount}</Progress>
                                    <Progress bar color="danger" value={area.totalCount-area.correctCount} max={area.totalCount}>{area.totalCount-area.correctCount}</Progress>
                                </Progress>
                              </React.Fragment>
                          })}
                         </Col>
                    </React.Fragment>
                });
        });

        return list;
    }

     private getPaticipants(lang: Language, participants?: EduAdminApi.Participant[]): CollapseListItem[] {

        var list: CollapseListItem[] = new Array<CollapseListItem>();

        participants?.forEach((item) => {
            list.push(
                {
                    title: <React.Fragment>
                        <Col md="6"><strong className="float-left">{item.firstName} {' '} {item.lastName}</strong> </Col>
                            <Col>   
                                {item.hasLink ? <i style={{fontSize: '17px'}} className="fa fa-external-link-alt text-primary float-right"/> : <></>}
                            </Col>
                            <Col>
                                {item.hasTest ? <i style={{fontSize: '17px'}} className="fa fa-check-circle text-success float-right"/> : <></>}
                            </Col>
                            <Col>
                                {item.hasResult ? <i style={{fontSize: '17px'}} className="fa fa-user-check text-success float-right"/> : <></>}
                            </Col>

                            <Col>
                                {item.isResultExported ? 
                                    <i style={{fontSize: '17px', color: 'green'}} 
                                    className="fa fa-flag"/> : <></>
                                }
                            </Col>
                        </React.Fragment>,
                    content: <React.Fragment>
                        <div style={{paddingLeft: '17px'}}>
                        <Row>
                        <Col>
                            <p>
                            {item.hasLink? <React.Fragment>
                            {/* <a href={item.sessionLink} target="_new" >
                                {lang.Text('securetest.url')} {' '}</a> */}

                            <small>{item.sessionLink}</small>
                            
                            <Button color="link" onClick={() => this.copyLinktoClipboard(item.sessionLink)}>
                                <i style={{fontSize: '17px'}} className="fa fa-external-link-alt "/> {lang.Text("copy.link")}
                            </Button>
                            
                            {item.hasTest? <>
                            
                            <br/>{lang.Text("eduamdin.testtitle")}{': '}<b>{item.testName}</b>

                             </> : <>

                                <small>{lang.Text('eduadmin.no.test')}</small>
                            
                             </>}
                           
 
                            </React.Fragment>
                            : <React.Fragment>
                                
                                <small>{lang.Text('eduadmin.no.session')}</small>
                                
                            </React.Fragment>
                            }
                             

                            </p>
                            <small>
                                {item.email}
                            </small>
                        </Col>
                        <Col>
                        
                 
                        </Col>
                        <Col>
                           
                        </Col>
                    
                        </Row>
                        </div>
                    </React.Fragment>,
                    isOpen: false
                }
            );
        });

        return list;
    }

}

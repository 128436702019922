import * as App from 'pubsub-js';
import * as React from 'react';
import { GlobalAppsettings } from './GlobalSettings';
import { AppCore } from './AppCore';

/* Import Local for Sweden */
import 'moment/locale/se';
import moment from "moment";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Container, Row, Col, Input, FormGroup, Label, Form } from 'reactstrap';

import lang_sv from '../theme/img/sweden.png'
import lang_eng from '../theme/img/united-kingdom.png'
import lang_usa from '../theme/img/united-states-of-america.png'
import lang_den from '../theme/img/denmark.png'
import lang_nor from '../theme/img/norway.png'
import lang_fin from '../theme/img/finland.png'
import lang_fr from '../theme/img/france.png'
import lang_sp from '../theme/img/spain.png'
import { InfoPanel } from '../panels/InfoPanel';
import { timeStamp } from 'console';


export interface LanguageInfo {
    list:   KeyWord[];
    id:     string;
    langid: string;
}

export interface KeyWord {
    key:   string;
    value: string;
}

export interface LanguageState {
    current?: LanguageInfo;
}

export class Language {

    constructor() {
        var cache = localStorage.getItem(GlobalAppsettings.LanguageId);
        if (cache!=null)
        {
           this.text = JSON.parse(cache);
           console.info('Loaded Language from Cache');
        }
        
    }

    public static GetLocale(): string {

        let storedLocale = localStorage.getItem(GlobalAppsettings.AppId + "-current-lang");
        if (storedLocale!=null)
        {
            return storedLocale;
        }
        
        let locale = moment.locale();
        if (locale==='en') locale = 'en-US';
        if (locale==='se') locale = 'sv';
        var user = AppCore.GetUserProfile();
        if (user.isloggedIn)
        {
            locale = user.langId;
        }
        
        // localStorage.setItem(GlobalAppsettings.AppId + "-current-lang", locale);

        return locale;
    }

    private text?: KeyWord[];
    private id?: string;
    private langid?: string;
    
    public UnMapped() : KeyWord[] {
        return Language._unmapped;
    } 

    private static _unmapped  : KeyWord[] = [];

    public loadData(data: KeyWord[]) {
        this.text = data;
        localStorage.setItem(GlobalAppsettings.LanguageId,JSON.stringify(this.text));
        console.info('Saved Language to Cache');
    }

    private find(data: KeyWord[],search: string) {
      
        let val: string= search;
        if (search!==undefined && search!==null) {
            
            let found = false;
            
            data.forEach((element) => {
                if (element.key.toLowerCase()===search.toLowerCase()) {
                    val = element.value;
                    found=true;
                    return val;
                } 
            });

            if (!found) {
                if (GlobalAppsettings.IsDevelopment) {
                
                    let found_unmapped = false;
            
                    Language._unmapped.forEach((element) => {
                        if (element.key.toLowerCase()===search.toLowerCase()) {
                            val = element.value;
                            found_unmapped=true;
                        } 
                    });
                    if (!found_unmapped)
                        Language._unmapped.push({key: search, value: search});
                }
            }
        }
        else
        {
           return 'empty.value';
        }
        return val;
    }

    public static AddMissingText(key: string,value: string) {
        if (GlobalAppsettings.IsDevelopment) {
            fetch('api/app/AddMissingKeyWord?key='+ key + '&value=' + value + '&lang=' + Language.GetLocale(),
                {credentials: "same-origin", method: 'post'})
                .then(data => {
                    console.info("added keyword " + data);
                });
        }
    }

    public Text(key: string): string {
        if (this.text!=undefined)
        {
            return this.find(this.text, key);
        }
        return "-"; //Default
    }

    public _text(key: string): React.ReactElement | string {
        if (this.text!=undefined)
        {
            return this.find(this.text, key);
        }
        return "-"; //Default
    }
}

export const LanguageContext = React.createContext(new Language());

export const LanguageConsumer = LanguageContext.Consumer;


export interface LanguageProviderState {
    current: Language,
    edit_lang: boolean
}

export interface LanguageProviderProps {
}

export class LanguageProvider extends React.Component<LanguageProviderProps, LanguageProviderState> {
    constructor(props:LanguageProviderProps) {
        super(props);
        let cl: Language = new Language();
        this.state = { current:  cl, edit_lang: false};
    }

    componentDidMount() {
        this.updateLanguage(Language.GetLocale());
        App.subscribe("language.update",this.update.bind(this));
        App.subscribe('change-lang',this.changeLang.bind(this));
    }

    componentWillUnmount() {
        App.unsubscribe("language.update");
        App.unsubscribe('change-lang');
    }

    private changeLang(msg:string, data?: any)
    {
        if (data!=undefined && data.lang!=undefined)
        {
            localStorage.setItem(GlobalAppsettings.AppId + "-current-lang", data.lang);
            
            fetch('api/user/UpdateCurrentLang?lang='+ data.lang,
            {credentials: "same-origin"})
            .then(response => response.json() as Promise<any>)
            .then(data => {
                //OK
                this.updateLanguage(data.lang, true);
            });
           
        }
    }

    private update(msg: string, lang: string) {
        var textdata = new Array<KeyWord>();

        textdata.push({key: "start.message",value: "element.value"});
        textdata.push({key: "engage.buttontext",value: "Test"});
        textdata.push({key: "button.close",value: "Stäng"});

        let cl: Language = new Language();
        cl.loadData(textdata);
        
        this.setState({current: cl});
    }

    private updateLanguage(lang: string, reload: boolean = false) {
        console.info(" updateLanguage -> " + lang);
        fetch('api/app/currentlanguage?lang='+ lang,
            {credentials: "same-origin"})
            .then(response => response.json() as Promise<LanguageInfo>)
            .then(data => {
                var textdata = new Array<KeyWord>();
                if (data.list !=undefined && data.list.length > 0)
                {
                    data.list.map(element => {
                        textdata.push({key: element.key,value: element.value});
                    });
                    let cl: Language = new Language();
                    cl.loadData(textdata);
                    this.setState({current: cl});
                    
                    if (reload) {
                        window.location.reload(); 
                    }
                }
                else
                {
                    console.info("No language entries returned for language " + data.langid);
                }
        }).catch(reason => {
            console.log(reason);
            window.location.reload(); 
        });
    }

    render() {
    let currentLang = lang_sv;
    
    switch (Language.GetLocale()) {
      case 'sv':
        currentLang = lang_sv;
        break;
      case 'en-US':
        currentLang = lang_eng;
        break;
      case 'fr':
        currentLang = lang_fr;
        break;
    }
      return (
        <LanguageContext.Provider value={this.state.current} >
          {this.props.children}
          <UncontrolledButtonDropdown >
              <DropdownToggle className="btn--pill shadow" 
                style={{backgroundColor: 'whitesmoke', borderColor: 'lightgray',
                // position: 'fixed', top: '90px', right: '-15px', 
                position: 'fixed', bottom: '-18px', height: '60px', width: '25px', left: '5px',
                borderRadius: 20,
                zIndex: 800
                }}>
                <img style={{width: '25px',position: 'relative',right: '12px',
                    bottom: '9px'}} src={currentLang} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => App.publish('change-lang',{lang:'sv'})}><img style={{width: '20px'}}  src={lang_sv} />{' '}Svenska</DropdownItem>
                <DropdownItem onClick={() => App.publish('change-lang',{lang:'en-US'})}><img style={{width: '20px'}}  src={lang_eng} />{' '}English</DropdownItem>
                <DropdownItem onClick={() => App.publish('change-lang',{lang:'fr'})}><img style={{width: '20px'}}  src={lang_fr} />{' '}Francais</DropdownItem>
                {/* {GlobalAppsettings.IsDevelopment ? 
                <>
                    <hr/>
                    <Button color="" onClick={() => {
                            App.publish('edit.lang',true);
                            }}>
                            Edit Language
                    </Button>
                </>
                :<></>} */}
              </DropdownMenu>
            </UncontrolledButtonDropdown>

           
        </LanguageContext.Provider>
    );
    }
}

export interface LanguageEditorState {
    current? : KeyWord;
}

export class LanguageEditor extends React.Component<null, LanguageEditorState> {
    static displayName = 'LanguageEditor';
    static contextType = LanguageContext;
    constructor(props:any) {
        super(props);

        this.state = {};

        this.textChange = this.textChange.bind(this);
    }

    render() {
        return <React.Fragment>
        {GlobalAppsettings.IsDevelopment ? 
            <> 
            <InfoPanel title="Edit Language" cancelButtonLabel="Close" 
            modalPanelOpen="edit.lang"
            position="modal-panel-left"
            showHeader={false}>
            <div className="yc-panel-xl">
            <LanguageContext.Consumer>
                { lang => ( <div>
                    <Container>
                    <Row><Col><h4>Edit Language</h4></Col></Row>
                    {lang.UnMapped().map((item) => {
                    return <React.Fragment>
                        <Row>
                            <Col> {item.key}</Col>
                            <Col> 
                                {item.value}
                            </Col>
                            <Col>
                            <Button color="link" onClick={() => this.setKeyword(item)}>
                                <i style={{fontSize: '17px'}} className="fa fa-pen text-secondary float-left"/>
                            </Button>
                            </Col>
                        </Row>
                        
                    </React.Fragment>; 
                    })}
                    <Row className="mt-3">
                            <Col>
                            {this.state.current ?
                            <Form>
                            <FormGroup>
                                <Label for="valText">
                                    {this.state.current.key}:
                                </Label>
                                    <Input
                                    id="valText"
                                    name="valText"
                                    placeholder="enter text"
                                    type="text"
                                    value={this.state.current?.value}
                                    onChange={this.textChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button color="success" onClick={() => this.saveKeyWord()}>Save</Button>
                            </FormGroup>
                            </Form>: <></>}
                            </Col>
                        </Row>
                    </Container>
                </div>)}
                </LanguageContext.Consumer>
            </div>
            </InfoPanel>
            </>
            :<></>}
        </React.Fragment>
    }
    
    textChange(event: any) {
        var c = this.state.current;
        if (c!=undefined)
        {
            c.value = event.target.value;
            this.setState({current: c});
        }
    }

    saveKeyWord(){
        if (this.state.current!=undefined) 
        {
            Language.AddMissingText(this.state.current?.key, this.state.current?.value)
            this.setState({current: undefined});
            App.publish('language.update',null);
        }
    }

    setKeyword(keyword: KeyWord): void {
        this.setState({current: keyword});
    }

    
}






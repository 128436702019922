import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { GlobalAppsettings } from './GlobalSettings';
import * as App from 'pubsub-js';
import {AppCore, AuthEventInfo} from './AppCore';
import { Nav, NavLink, Button, Fade } from 'reactstrap';


export interface MenuItemState {
    ready: boolean,
    authenticated: boolean
}

export interface MenuItemProps {
    to?: string
    className?: string
    active?: boolean
}

export class MenuItem extends Component<MenuItemProps,MenuItemState> {
    constructor(props: MenuItemProps) {
        super(props);

        this.state = {
            ready: true,
            authenticated: false
        };
    }

    componentDidMount() {
        App.subscribe('app.auth-changed',this.authChanged.bind(this))
    }

    componentWillUnmount() {
        App.unsubscribe('app.auth-changed');
    }

    private authChanged(msg: string, data: AuthEventInfo) {
        if (data!=undefined)
        {
            this.setState({ ready: true, authenticated: data.IsAuthenticated });
        }
        else
        {
            this.setState({ ready: false, authenticated: false });
        }
    }

    render() {
        if (!this.state.ready) {
            return <React.Fragment></React.Fragment>;
            } else {
                if (this.state.authenticated) {
                    return <React.Fragment>
                        <div className={this.props.active ? 'menu-item-active ' + this.props.className : 'menu-item ' + this.props.className}>
                        {this.props.children}
                        </div>
                        </React.Fragment>
                } else {
                    return <React.Fragment>
                        <div className={this.props.active ? 'menu-item-active ' + this.props.className : 'menu-item ' + this.props.className}>
                        {this.props.children}
                        </div>
                    </React.Fragment>
                }
            }
    }
}

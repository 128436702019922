import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container, Row, Col, CustomInput, UncontrolledCollapse } from 'reactstrap';
import { LanguageContext, Language } from '../../backbone/Language';
import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';

import {ProductLogo} from '../../components/ProductLogo';
import { Line } from 'react-chartjs-2';
import { InfoPanel } from '../../panels/InfoPanel';
import { ModalPanel } from '../../panels/ModalPanel';
import { CollapseList, CollapseListItem } from '../../components/CollapseList';
import { AppInsights } from '../../backbone/AppInsights';

import { Api } from './api/WorkSet';
import { DraftEditor } from '../../components/drafteditor/DraftEditor';
import { User } from '../../api/User';
import { Application } from '../../api/App';
import { AppCore } from '../../backbone/AppCore';
import { userInfo } from 'os';

import { UserHasModule } from '../../backbone/UserHasModule';

export interface ConstructionModuleState {
    loading: boolean
    enabled: boolean
    text: string
    title: string
    isDirty: boolean
    user: User.UserProfile
    app:  Application.AppProfile
    organisation: Api.OrganisationInfo
}

export interface ComponentOnChangeEvent {
    message: string
}

export interface ConstructionModuleProps {
    title?: string
    text?: string
    onChange?: ((state: ComponentOnChangeEvent)=> void)
}

export class ConstructionModule extends React.Component<ConstructionModuleProps, ConstructionModuleState> {
    static displayName = 'ConstructionModule';
    static contextType = LanguageContext;
    static moduleId = "youcap.io.construction";
    constructor(props:ConstructionModuleProps) {
        super(props);
        
        let _title = props.title!=undefined ? props.title: '';
        let _text= props.text!=undefined ? props.text: '';

        this.state = {
            enabled: false,
            title: _title,
            isDirty: false,
            text: _text,
            loading: false,
            user: AppCore.GetUserProfile(),
            app: AppCore.GetAppProfile(),
            organisation: {
              organisationId: '',
              organisationName: '',
              workSets: [],
              hasExternalTool: false,
              externalToolLink: 'https://construction.youcap.io'
            }
        };

        this.renderModule = this.renderModule.bind(this);
    }

    componentDidMount() {
        App.subscribe("add.workset", this.viewItem.bind(this));

        


        fetch('api/workset/currentorganisation', GlobalAppsettings.GetRequestInit())
        .then(response => response.json() as Promise<Api.OrganisationInfo>)
        .then(data => {
        
            this.setState({loading: false, organisation: data});

        }).catch(reason => {
            //Track Exception
            App.publish('track-exception', {error: reason});
        });

        
    }

    componentWillUnmount() {
      App.unsubscribe("add.workset");
    }

    private viewItem(msg: string, data: any)
    {
      
        this.setState({isDirty: true});
    }

    private onItemChange() {
        
        this.setState({isDirty: true});
    }

    private onItemSave() {
      //Save data
      //Todo: replace with real 
      // fetch('api/content/test', GlobalAppsettings.GetRequestInit())
      // .then(response => response.json() as Promise<ContentItem>)
      // .then(data => {
      //     // Track Event    
      //     App.publish('track-event', {name: 'event.name', properties:{
      //         'SomePropery': 'Property',
      //         'Title': data.title
      //     }});

      //     //Todo: Handle data

      // }).catch(reason => {
      //     //Track Exception
      //     App.publish('track-exception', {error: reason});
      // });
      this.setState({isDirty: false});
  }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
          <Row>
            <Col>
              <ProductLogo type="black" title="Construction"></ProductLogo>
            </Col>
            <Col md="3">
              <Button color="secondary" size="sm" outline className="btn--pill float-right"
                      onClick={() => App.publish('construction.settings',{})} style={{}}>
                <i className="fa fa-cog"/>
              </Button>
            </Col>
          </Row>

        {this.state.organisation.hasExternalTool ? <Button color="link" href={this.state.organisation.externalToolLink}>
          Gå till appen för att se och redigera frågebanker {'  '}<i className="fa fa-external-link-alt" /></Button> :
         this.renderModule(lang) 
        }

        <InfoPanel title={lang.Text('workset settings')} cancelButtonLabel={lang.Text('btn.close')} 
              modalPanelOpen="construction.settings"
              position="modal-panel-right"
              showHeader={false}>
              <div className="yc-panel-md">
              <Container>
                  <Row>
                  <Col>
                  
                  </Col>
                  </Row>
              </Container>
              </div>
          </InfoPanel>

          <ModalPanel title={lang.Text('add.workset')} 
              okButtonLabel={lang.Text('btn.save')}
              cancelButtonLabel={lang.Text('btn.cancel')}
              leaveButtonLabel={lang.Text('btn.leave')}
              unsavedChangesText={lang.Text('modal.unsaved.changes')}
              isDirty = {this.state.isDirty} 
              // showHeader={false}
              modalPanelOpen="add.workset"
              onSave={() => this.onItemSave()}
              position="modal-panel-right">
             <div className="yc-panel-md">
              <Container>
              <Row>
                  <Col>
                      <p>{lang.Text('add.workset.form')}</p>
                  </Col>
              </Row>
              </Container>
              </div>
          </ModalPanel>

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    
    renderModule(lang: Language) {
      return (<React.Fragment>
      
      <Row>
          <Col>
            <CollapseList id="worksetlist" items={this.getWorksets(this.state.organisation.workSets,lang)} lang={lang}/>
          </Col>
      </Row>

      <Row style={{marginTop: '10px'}}>
          <UserHasModule moduleId="youcap.teams">
          <Col>
          <Button color="dark" outline className="btn--pill" 
              onClick={() => App.publish("add.workset", {tab: 'organisation'})}>
                <i className="fas fa-plus" style={{marginRight: '5px'}}></i>
                {lang.Text('add.workset')}</Button>
          </Col>
          </UserHasModule>
        </Row>
        </React.Fragment>
        );
    }

    private getWorksets(worksets: Api.WorkSetInfo[], lang: Language) : CollapseListItem[]  {
    
      var list: CollapseListItem[] = new Array<CollapseListItem>();
   
       worksets.forEach((item) => {
           list.push(
           { title: <React.Fragment><h5 className="mb-1">{item.title}</h5></React.Fragment>, 
            isOpen: false, content: <React.Fragment>
                
                <DraftEditor id="f_description_edit" textOnly={false} readOnly={true} data={item.description}
                        dataId={'f_description_edit'}/>
                        
                {/* <small>{item.summary.total}</small> */}
                <Button color="link" className="float-right" href={"/construction/" + item.id}>{lang.Text('btn.open')}</Button>
            </React.Fragment>,  }
           );
       });
   
       return list;
     }
}

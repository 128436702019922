import * as React from 'react';
import * as App from 'pubsub-js';

import { Button } from 'reactstrap';
import { LanguageContext } from '../backbone/Language';
import { AppCore } from './AppCore';

export interface UserHasModuleState {
    hasModule: boolean
}


export interface UserHasModuleProps {
    moduleId: string
    upgrade?: boolean
    upgradeCommand?: string
}

export class UserHasModule extends React.Component<UserHasModuleProps, UserHasModuleState> {
    static displayName = 'UserHasModule';
    static contextType = LanguageContext;
    constructor(props:UserHasModuleProps) {
        super(props);
        let value  =AppCore.HasModule(this.props.moduleId);
        this.state = {
            hasModule: value
        }
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <React.Fragment> 
                {this.state.hasModule ? <>
                    {this.props.children}
                </> : 
                <>
                {this.props.upgrade ? 
                <Button color="link" className="btn--pill" 
                    onClick={() => App.publish("upgrade.subscription",{module: this.props.moduleId})}>
                    <i className="fas fa-plus" style={{marginRight: '5px'}}></i>
                    {lang.Text('upgrade.subscription')}</Button>
                : <></>}
                </>
                }
            </React.Fragment>
        )}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
}


import React, {Component} from 'react';
import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry } from '@microsoft/applicationinsights-web'
import { GlobalAppsettings } from './GlobalSettings';
import * as App from 'pubsub-js';

import { Application} from '../api/App';
import { User} from '../api/User';

import { AppInsightsPlugin } from './AppInsightsPlugin';

/* Import Local for Sweden */
import 'moment/locale/se';
import moment from "moment";

import { userInfo } from 'os';
import { UncontrolledAlert, Container, Alert } from 'reactstrap';
import { Language } from './Language';

import { Backplane } from './Backplane';

export interface AuthEventInfo {
    IsAuthenticated: boolean;
    
}

export interface userContext {
    id: string;
}

export interface AppCoreState {
    alertvisible: boolean;
    alerttype: string;
    alertmessage: string;
}

export interface AlertMessage {
    alertmessage: string;
    alerttype: string;
}

export interface AppCoreProps {
    
}

export class AppCore extends Component<AppCoreProps,AppCoreState> {
    
    private appInsights: ApplicationInsights;
    private appInfo?: Application.AppProfile;
    //private _backplane : Backplane;

    constructor (props: AppCoreProps) {
        super(props);
        
        this.state = {
            alertvisible: false,
            alerttype: 'danger',
            alertmessage: ''
        }
        
        this.onDismissAlert = this.onDismissAlert.bind(this);
        var _plugin = new AppInsightsPlugin();

        this.appInsights = new ApplicationInsights({ config: {
            instrumentationKey: GlobalAppsettings.AppInsights,
            disableFetchTracking: true,
            disableAjaxTracking: true,
            extensions: [_plugin]
          } });
        
        this.appInsights.loadAppInsights();
        this.trackEvent = this.trackEvent.bind(this);
        this.setUserId = this.setUserId.bind(this);

        var cache = localStorage.getItem(GlobalAppsettings.AppId);
        if (cache!=null)
        {
            this.loadAppData(JSON.parse(cache) as Application.AppProfile);
        }

        let lang = Language.GetLocale();

        sessionStorage.setItem(GlobalAppsettings.AppId + "-user",JSON.stringify(AppCore.GetDefaultUser()));
        localStorage.setItem(GlobalAppsettings.AppId,JSON.stringify(AppCore.GetDefaultAppProfile()));

        //Load App Profile - configuration and other settings
        fetch('/api/app/appprofile',
        {credentials: "same-origin"})
            .then(response => response.json() as Promise<Application.AppProfile>)
            .then(data => {
                console.info("Loaded AppProfile...");
                this.loadAppData(data);
            }).catch(reason => {
                console.error("Appdata Error -> " + reason);
                this.trackException("", { exception: reason })
        });

        this.checkSecurityStatus = this.checkSecurityStatus.bind(this);
        
        //this._backplane = new Backplane();

    }

    public componentDidMount() {
        window.addEventListener("focus", this.onFocus.bind(this));
        window.addEventListener("blur", this.onBlur.bind(this));
    
        App.subscribe('track-event',this.trackEvent.bind(this));
        App.subscribe('track-exception',this.trackException.bind(this));
        App.subscribe('set-user',this.setUserId.bind(this));
        
        App.subscribe('alert',this.showAlert.bind(this));

        this.checkSecurityStatus();

        this.trackEvent("",{ name: 'AppCore.Start', properties: 
        {
            'AppId': GlobalAppsettings.AppId,
            'Environment': 'Prod'
        }});

    }
  
    public componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus);
        window.removeEventListener("blur", this.onBlur);

        App.unsubscribe('track-event');
        App.unsubscribe('alert');
        App.unsubscribe('track-exception');
        App.unsubscribe('set-user');
       
    }

    private onFocus() {
        console.info('focus');
        App.publish("app.focus",{});
        this.checkSecurityStatus();
    }
  
    private onBlur() {
        console.info('blur');
        App.publish("app.blur",{});
    }

    public static GetUserProfile(): User.UserProfile {
        let data = sessionStorage.getItem(GlobalAppsettings.AppId + "-user");
        if (data!=null)
        {
            return JSON.parse(data) as User.UserProfile;
        }
        else
        {
            return AppCore.GetDefaultUser();
        }
    }

    public static HasModule(moduleId: string): boolean {
        let user = this.GetUserProfile();
        let ret = false;

        if (user!=undefined && user.modules!=undefined) {
            user.modules.forEach(m => {
                if (m.moduleId.toLowerCase()==moduleId.toLowerCase() && m.isActive)
                {
                    console.info("module -> " + m.moduleId + " " + m.isActive);
                    ret = true;
                }
            });
        }

        return ret;
    }

    private static GetDefaultUser(): User.UserProfile {
        
        let locale = moment.locale();
        if (locale==='en') locale = 'en-US';
        if (locale==='se') locale = 'sv';
        
        let defaultUser = {
            id: 'default.user.id',
            area: 'default.user.id',
            name: 'default.user.id',
            country: 'default.user.id',
            email: 'default.user.id',
            isloggedIn: false,
            isError: false,
            errorMessage: '',
            langId: locale,

        };

        return defaultUser as User.UserProfile;
    }

   

    public static GetAppProfile(): Application.AppProfile {
        let data = localStorage.getItem(GlobalAppsettings.AppId);
        if (data!=null)
        {
            return JSON.parse(data) as Application.AppProfile;
        }
        else
        {
            return AppCore.GetDefaultAppProfile();
        }
    }

    private static GetDefaultAppProfile() {
        let defaultAppProfile = {
            name: 'default.app',
            version: 'default.version'
        };
        return defaultAppProfile as Application.AppProfile;
    }

    private updateUserProfile() {
        fetch('api/user/current',
        {credentials: "same-origin"})
        .then(response => response.json() as Promise<User.UserProfile>)
        .then(data => {
        
            this.setUserId("",data.id);
            
            data.isloggedIn = true;
            sessionStorage.setItem(GlobalAppsettings.AppId + "-user", JSON.stringify(data));
            
            App.publish('intercom.setup-user', { name: data.name, userid: data.id, email: data.email});

            console.info("Loaded UserProfile.");
            App.publish('app.auth-changed', {IsAuthenticated: data.isloggedIn});

            localStorage.setItem(GlobalAppsettings.AppId + "-current-lang", data.langId);

        }).catch(reason => {
            console.error(reason);
            this.trackException("", { exception: reason })
        });
    }

    private checkSecurityStatus() {
        console.info('Check Security Status...');
        fetch('api/app/checksecuritystatus',{credentials: "same-origin"})
            .then(response => response.json() as Promise<Application.SecurityInfo>)
            .then(data => {
                console.info(data.message);
                if (data.success)
                {
                    this.updateUserProfile();
                }
                else
                {
                    var user = AppCore.GetUserProfile();
                    if (user.isloggedIn) //user was prev logged in
                    {
                        App.publish('user.login',  {message: data.message});
                    }
                }
            }).catch(reason => {
                console.error(reason);
                this.trackException("", { exception: reason })
        });
    }

    private loadAppData(data: Application.AppProfile) {
        this.appInfo = data;
        App.publish('intercom.setup', {appid: data.intercomAppid});

        localStorage.setItem(GlobalAppsettings.AppId,JSON.stringify(data));
    }

    public setUserId(msg: string, user: string) {
        console.info(user);
        this.appInsights.context.user.id = user;
        this.appInsights.setAuthenticatedUserContext(user, user, false);
    }

    public trackEvent(msg:string, data: IEventTelemetry) {
        this.appInsights.trackEvent(data);
        this.appInsights.flush();
    }

    public trackException(msg:string, data: IExceptionTelemetry) {
        this.appInsights.trackException(data);
        this.appInsights.flush();
    }

    private showAlert(msg :string, data: AlertMessage) {
        this.setState({alertmessage: data.alertmessage, alerttype: data.alerttype, alertvisible: true});
    }

    onDismissAlert() {
        this.setState({alertvisible: false})
    }

    render() {
        return <React.Fragment>    
            <Alert 
                style={{zIndex: 1200,position: 'fixed', width: '100%'}}
                className={"no-border sticky rounded-0 text-white bg-" + this.state.alerttype}   
                isOpen={this.state.alertvisible} toggle={this.onDismissAlert}>
                {this.state.alertmessage}
            </Alert>
        </React.Fragment>;
    }

}



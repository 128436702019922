import React, { Component } from 'react';
import { FadingRoute } from './backbone/FadingRoute';

import { BrowserRouter, Router, Route, Switch, useRouteMatch } from 'react-router-dom';

import AuthorizeRoute from './backbone/AuthorizeRoute';


import { Dashboard } from './pages/Dashboard';

import { NotFound } from './pages/Notfound';
import { Error } from './pages/Error';
import { PageHeader } from './layout/PageHeader';
import { NavItem, NavLink, Row, Col, Fade, Button, UncontrolledButtonDropdown, DropdownToggle,
   DropdownMenu, DropdownItem, Container, Badge } from 'reactstrap';
import { ContentPanel } from './panels/ContentPanel';
import { LanguageContext } from './backbone/Language';

import { AuthorizeLink } from './backbone/AuthorizeLink';


import { AuthorizeButton } from './backbone/AuthorizeButton';
import { UrlHelper } from './backbone/UrlHelper';
import { AppCore } from './backbone/AppCore';
import { InfoPanel } from './panels/InfoPanel';
import { MenuItem } from './backbone/MenuItem';
import { ZoomMeetingsPage } from './modules/Zoom/ZoomMeetings';

import { motion } from "framer-motion";

import { default as Lock } from './images/Lock';

import { PreviewPanel } from './pages/PreviewPanel';
import { ContentPage } from './pages/ContentPage';

import { Footer } from './layout/Footer';

import { Workset } from './modules/Construction/Workset';

import {LandingPage} from './pages/LandingPage';

export default class App extends Component {
  static displayName = App.name;
  static baseUrl?: string; 

  constructor(props: any) {
    super(props);
    let base = document.getElementsByTagName('base')[0].getAttribute('href');
    if (base!=null) {
      App.baseUrl = base;
    }

    
  }

  componentDidMount() {

  }

  render () {

    return <React.Fragment>
    <LanguageContext.Consumer>
    { lang => ( 
    <BrowserRouter 
    // forceRefresh
    >

      <div className="d-flex flex-column bd-highlight " style={{padding: '0px !important'}}>
        <div className="p-2 bd-highlight" style={{padding: '0px', height: '85px'}}>
        <PageHeader transparent={false} image={''}> 
          <MenuItem active={false} 
                className="p-2 bd-highlight d-none d-sm-none d-md-block d-lg-block align-self-center">
                  <AuthorizeButton showLock={false} redirectToLogin  
                    color="none"
                    to="/dashboard">
                      {/* <Badge color="danger" style={{top: '-8px',backgroundColor: '#F26D3D'}}>{lang.Text('beta')}</Badge> */}
                      {lang.Text('Säkra prov')}
                  </AuthorizeButton>
            </MenuItem>
        </PageHeader>
        </div>
        <div className="bd-highlight" >
        <Switch>
          
          <Route exact path='/' >
            <LandingPage contentId="7j6zOPqq2JYmEGfvcwqXOS" />
          </Route>
        
          <Route exact path='/index.html' >
            <LandingPage contentId="7j6zOPqq2JYmEGfvcwqXOS" />
          </Route>
          <Route exact path='/loggedout' >
            <LandingPage contentId="7j6zOPqq2JYmEGfvcwqXOS" />
          </Route>
        
          {/* <Route path='/page'  >
            <ContentPage contentType="contentful" preview={false}/>
          </Route> */}

          <AuthorizeRoute path='/dashboard' component={Dashboard}  />

          <AuthorizeRoute path='/start' component={Dashboard}  />

          <AuthorizeRoute path='/construction' component={Workset}  />
           
          <Route path='/error' component={Error} />

          {/* <Route path='/preview/sidebar'  >
            <PreviewPanel />
          </Route>
          <Route path='/preview/landing' >
            <LandingPage preview={true} />
          </Route>

          <Route path='/preview/page'  >
            <ContentPage contentType="contentful" preview={true}/>
          </Route> */}
                    
          <Route path="*">
            <ContentPage contentType="contentful" preview={false} contentId="3qy1y8dCjwUo9BzQ3iwiqn"/>
          </Route>
        </Switch>
        </div>
        {/* <div className="bd-highlight" style={{marginBottom: '-30px'}}>
          <Footer companyInfoId="2hQrMrgbqal8eq0ygCF1Eo"></Footer>
        </div> */}
      </div>
          
      <InfoPanel title={lang.Text('page.login')}  cancelButtonLabel={lang.Text('btn.close')}  
                          modalPanelOpen="user.login" 
                          position="modal-panel-left">
                  <React.Fragment>
                  <div className="yc-panel-md">
                      <Container style={{marginTop: '30px', textAlign: 'center'}}>
                          <Row style={{marginTop: '20px'}}>
                              <Col>
                                  <h5>{lang.Text('page.login.msg')}</h5>
                              </Col>
                          </Row>
                          <Row style={{marginTop: '50px', marginBottom: '50px'}}>
                              <Col>
                                  <i className="fa fa-id-badge" style={{fontSize: '178px', color: '#F26D3D'}}></i>
                              </Col>
                          </Row>
                          <Row style={{marginTop: '20px', textAlign: 'center'}}>
                                <Col >
                                  <Button color="primary" className="btn-pill" onClick={this.login} style={{width: '250px'}}
                                      >{lang.Text('profile.login')}</Button>
                              </Col>
                          </Row>
                          <Row style={{marginTop: '20px', textAlign: 'center'}}>
                                <Col >
                                  <Button color="secondary" className="btn-pill" onClick={this.register} style={{width: '250px'}}
                                      >{lang.Text('profile.register')}</Button>
                              </Col>
                          </Row>
                      </Container>
                  </div>
                  </React.Fragment>
          </InfoPanel>


        <ContentPanel contentType="contentful" contentId="3nI98BtCxkUcfTNBzKvedf" lastUpdated={lang.Text('last.updated')}
          modalPanelOpen="start.demo" cancelButtonLabel={lang.Text('btn.close')}
          position="modal-panel-right" width="780px">
            {/* <Alert color="info">Detta är en alert</Alert> */}
          
        </ContentPanel>

      </BrowserRouter>)}
    </LanguageContext.Consumer>
    </React.Fragment>;
  }

  login() {
    window.open("/session/login","_self");
  }

  register() {
    window.open("/register","_self");
  }

  logout() {
      window.open("/session/logout","_self");
  }

}

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as App from 'pubsub-js';

import {Media, Badge, UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu,
     Nav, Form, FormGroup, Input, Button, Navbar, Col, Row, Popover, PopoverHeader, PopoverBody, 
     Alert, FormText, Label, Progress, Container, Fade} from 'reactstrap';


import {DraftEditor, DraftEditorData} from '../../../components/drafteditor/DraftEditor';
import { InfoPanel } from '../../../panels/InfoPanel';

import { Api } from '../api/WorkSet';
import { ModalPanel } from '../../../panels/ModalPanel';
import { SaveButton } from '../../../components/base/SaveButton';
import { LanguageContext, Language } from '../../../backbone/Language';
import { InfoLink } from '../../../components/base/InfoLink';
import { Loading } from '../../../components/base/Loading';

import { Search } from './Search';
import { GlobalAppsettings } from '../../../backbone/GlobalSettings';
import { ConstructionModule } from '../ConstructionModule';

import { EditPanel } from '../panels/EditPanel';
import { PreviewPanel2 } from '../panels/PreviewPanel2';

interface GroupState {
    groupId: string
    isOpen: boolean
}

interface WorksetState {
    
    _workset?: Api.WorkSet;
    loading: boolean;
    
    dirty_title?: string;
    dirty_description?: string;

    dirty_taskgroups?: Api.TaskChange[]; 
    dirty_tasks?: Api.TaskChange[];
    isSaving: boolean;
    isDirty: boolean;

    isCopyRightSaving: boolean;
    isCopyRightDirty: boolean;

    showTools: boolean;
    search: string;

    groups?: Api.TaskGroup[];

    _currentGroupId?: string;

    _currentGroup?: Api.TaskGroup;

    _currentCopyRightinfo? : Api.CopyRight;
    
}

export interface WorksetProps {
    id: string;
}


export interface SaveTaskResponse {
    success : boolean
}

export class WorksetEdit extends React.Component<WorksetProps, WorksetState> {
    private openGroups: GroupState[];

    constructor(props:any) {
        super(props);
        
        this.state = { 
            loading: true, 
            _workset: undefined, 
            dirty_title: undefined, 
            dirty_description: undefined,
            showTools: false, 
            search: '',
            isDirty: false,
            isSaving: false,
            isCopyRightDirty: false,
            isCopyRightSaving: false
        };

        this.openGroups = new Array<GroupState>();

        var _store = localStorage.getItem('youcap-construction-bank-' + this.props.id);
        if (_store!=null)
        {
            this.openGroups = JSON.parse(_store);
        }

        console.info(this.openGroups);

        this.reload = this.reload.bind(this);
        this.reloadOpenGroups = this.reloadOpenGroups.bind(this);

        this.addSubTask = this.addSubTask.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.loadCurrentStore = this.loadCurrentStore.bind(this);

        this.checkboxChange = this.checkboxChange.bind(this);
        
        this.groupTitleChange = this.groupTitleChange.bind(this);
        this.groupDescChange = this.groupDescChange.bind(this);

        this.titleChange = this.titleChange.bind(this);
        this.descriptionChange = this.descriptionChange.bind(this);

        this.renderToolbar = this.renderToolbar.bind(this);

        this.copyrightAddress2Change = this.copyrightAddress2Change.bind(this);
        this.copyrightAddressChange = this.copyrightAddressChange.bind(this);
        this.copyrightCityChange = this.copyrightCityChange.bind(this);
        this.copyrightEmailChange = this.copyrightEmailChange.bind(this);
        this.copyrightISNIChange = this.copyrightISNIChange.bind(this);
        this.copyrightNameChange = this.copyrightNameChange.bind(this);
        this.copyrightZipChange = this.copyrightZipChange.bind(this);
        
        this.saveCoptyRightInfo = this.saveCoptyRightInfo.bind(this);

    }

    public componentDidMount() {
        this.loadCurrentStore();
        App.subscribe("workset.reload",this.reload.bind(this));
        App.subscribe("workset.savedone",this.saveDone.bind(this));

    }

    public componentWillUnmount() {
        App.unsubscribe("workset.reload");
        App.unsubscribe("workset.savedone");
    }

    public componentDidUpdate() {
        localStorage.setItem('youcap-construction-bank-' + this.props.id,JSON.stringify(this.openGroups));
    }

    private loadCurrentStore() {

        this.setState({  loading: true });
        
        fetch('api/workset/getworkset?id=' + this.props.id
            ,{credentials: "same-origin"})
            .then(response => response.json() as Promise<Api.WorkSet>)
            .then(data => {
                
                console.info(data);

                let loaded: Api.WorkSet = this.reloadOpenGroups(data);
                
                this.setState({ _workset: loaded, loading: false });

                try {
                    App.publish("f_title_edit", {
                        contentStateText: data._store.title,
                        contentStateRaw: undefined
                    });
    
                    if (data._store.description.charAt(0)=='{') //check if json-data
                    {
                        App.publish("f_description_edit", {
                            contentStateText: undefined,
                            contentStateRaw: data._store.description
                        });
                    }
                    else
                    {
                        App.publish("f_description_edit", {
                            contentStateText: data._store.description,
                            contentStateRaw: undefined
                        });
                    }
                } catch (error) {
                    console.error(error);
                }
               
            }).catch(reason => {
                this.setState({ loading: false });
            });
    }


    private reloadOpenGroups(data: Api.WorkSet): Api.WorkSet {
        let w = data;
            w._store.taskGroups.forEach(group =>
            {
                this.openGroups.forEach(item =>
                {
                    if (item.isOpen && item.groupId===group.id)
                    {
                        group.view = true;
                    }
                });
            });
        return w;
    }

    private reload() {
        fetch('api/workset/getworkset?id=' + this.props.id,
        GlobalAppsettings.GetRequestInit())
        .then(response => response.json() as Promise<Api.WorkSet>)
        .then(data => {
           
            let reloaded: Api.WorkSet = this.reloadOpenGroups(data);
            
            this.setState({ _workset: reloaded, isSaving: false });

            App.publish("f_title_edit", {
                contentStateText: data._store.title,
                contentStateRaw: undefined
            });

            if (data._store.description.charAt(0)=='{') //check if json-data
            {
                App.publish("f_description_edit", {
                    contentStateText: undefined,
                    contentStateRaw: data._store.description
                });
            }
            else
            {
                App.publish("f_description_edit", {
                    contentStateText: data._store.description,
                    contentStateRaw: undefined
                });
            }

        }).catch(reason => {
            //this.setState({ loading: false });
            //Todo: show error
        });
    }

    static GetTaskUrl(id: string): string {
        return './task/' + id;
    }

    onSearchChange(event: any) {
       this.setState({ search: event.target.value});

    }
        
    private showSubTasks(groupId: string){
        if (groupId=='')
        {
        
        }
        else {
            if (this.state._workset!=undefined)
            {
                let w = this.state._workset;

                w._store.taskGroups.forEach(item =>
                    {
                        if (item.id===groupId)
                        {
                            item.view= true;
                        }
                    }
                );
                
                let found: boolean= false;
                this.openGroups.forEach(item =>
                {
                    if (item.groupId===groupId)
                    {
                        item.isOpen= true;
                        found = true;
                    }
                });
                
                if (!found) {
                    this.openGroups.push({groupId: groupId,isOpen: true});
                }

                this.setState({_workset: w});
            }
        }
        console.info(this.openGroups);
    }

    private hideSubTasks(groupId: string){
        if (groupId=='')
        {
        
        }
        else {
            if (this.state._workset!=undefined)
            {
                let w = this.state._workset;

                w._store.taskGroups.forEach(item =>
                    {
                        if (item.id===groupId)
                        {
                            item.view= false;
                        }
                    }
                );

                this.openGroups.forEach(item =>
                {
                    if (item.groupId===groupId)
                    {
                        item.isOpen= false;
                    }
                });

                this.setState({_workset: w});
            }
        }
        console.info(this.openGroups);
    }

    private addSubTask(groupId: string){
        
        if (groupId=='')
        {
           
        }
        else {
            this.setState({isSaving: true});
            fetch('/api/workset/newtask?groupId=' + groupId, GlobalAppsettings.GetPostInit(null))
            .then(response => response.json() as Promise<Api.TaskCoreRef>)
            .then(data => { 
                App.publish("workset.reload",{});
            })
            .catch(reason => {
                this.setState({isSaving: false});      
            });
        }
    }

    showTag(link: string) {
        App.publish("tag.open",{ link }); 
    }

    private saveAll() {

        if (!this.state.isDirty) return;

        this.setState({ isSaving: true });

        if (this.state._workset!=undefined)
        {

            //save only if we have changes
            if (this.state.dirty_title==undefined && this.state.dirty_description==undefined)
            {
                console.log('no changes detected to Store.Info');
            }
            else
            {
                var store: Api.Store = this.state._workset._store;
                if (this.state.dirty_title!=undefined)
                {
                    store.title = this.state.dirty_title;
                }
                if (this.state.dirty_description!=undefined)
                {
                    store.description = this.state.dirty_description;
                }
                
                fetch('/api/workset/savestore?id=' + this.props.id
                    , {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(store),
                    credentials: 'same-origin'
                })
                .then(response => {
                    App.publish("workset.savedone",{});
                })
                .catch(reason => {
                    console.log(reason);
                    this.setState({isSaving: false});
                });
            }
        }

        if (this.state.dirty_taskgroups!=undefined)
        {
            this.state.dirty_taskgroups.map(item => console.log(item));

            fetch('/api/workset/savetaskgroupchanges?id=' + this.props.id
            , {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.dirty_taskgroups),
                credentials: 'same-origin'
              })
              .then(response => {
                 App.publish("workset.savedone",{});
              })
              .catch(reason => {
                console.log(reason);
                this.setState({isSaving: false});
            });
        }

        if (this.state.dirty_tasks!=undefined)
        {
            this.state.dirty_tasks.map(item => console.log(item));
            this.setState({isSaving: true});
            fetch('/api/workset/savetaskchanges?id=' + this.props.id, 
            {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.dirty_tasks),
                credentials: 'same-origin'
              })
              .then(response => {
                 App.publish("workset.savedone",{});
              })
              .catch(reason => {
                  console.log(reason);
                  this.setState({isSaving: false});
            });
        }
    }

    private saveDone(status: string) {
        this.setState({ 
            dirty_description: undefined,
            dirty_title: undefined,
            dirty_taskgroups: undefined,
            dirty_tasks: undefined,
            isDirty: false,
            isSaving: false
        });
    }


    private checkboxChange(value: any) {
        //this.setState({ _infoEdit: value})
    }

    private titleChange(id:string, data: DraftEditorData)
    {
        if (data.contentStateText!=undefined && this.state._workset!=undefined &&
            data.contentStateText!=this.state._workset._store.title)
        {
            this.setState({isDirty: true,
            dirty_title: data.contentStateText });
        }        
    }

    private descriptionChange(id:string, data: DraftEditorData)
    {
        this.setState({isDirty: true,
        dirty_description: data.contentStateRaw });
    }

    private addGroupChange(id:string,type:string,text: string) {
        let _dirty = this.state.dirty_taskgroups;

        if (_dirty == undefined) {
            _dirty = [];
        }

        let f: Api.TaskChange | undefined;
        _dirty.forEach((g) => {
            if (g.id == id && g.type == type) {
                f = g;
            }
        });

        if (f != undefined) {
            f.text = text;
        }
        else
        {
            _dirty.push({id: id, type: type, text: text});
        }
        
        return _dirty;
    }

    private addTaskChange(id:string,type:string,text: string) {
        let _dirty = this.state.dirty_tasks;
        if (_dirty == undefined) {
            _dirty = [];
        }
        let f: Api.TaskChange | undefined;
        _dirty.forEach((g) => {
            if (g.id == id && g.type == type) {
                f = g;
            }
        });
        if (f != undefined) {
            f.text = text;
        }
        else
        {
            _dirty.push({id: id, type: type, text: text});
        }
        return _dirty;
    }

    private groupTitleChange(id: string, data: DraftEditorData) {
        if (data.contentStateText!=undefined)
        {
            let _dirty = this.addGroupChange(id,'title.change', data.contentStateText);
            this.setState({isDirty: true, dirty_taskgroups: _dirty});
        }
    }

    private groupDescChange(id: string,data: DraftEditorData) {
        if (data.contentStateRaw!=undefined)
        {
            let _dirty = this.addGroupChange(id,'description.change', data.contentStateRaw);
            this.setState({isDirty: true,dirty_taskgroups: _dirty});
        }
    }

    private taskTitleChange(id: string, data: DraftEditorData) {
        if (data.contentStateText!=undefined)
        {
            let _dirty = this.addTaskChange(id,'title.change', data.contentStateText);
            this.setState({isDirty: true,dirty_tasks: _dirty});
        }
    }

    private taskDescChange(id: string, data: DraftEditorData) {
        if (data.contentStateRaw!=undefined)
        {
            let _dirty = this.addTaskChange(id,'description.change', data.contentStateRaw);
            this.setState({isDirty: true,dirty_tasks: _dirty});
        }
    }

    private copyrightNameChange(event: any) {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            cr.name = event.target.value;
            this.setState({_currentCopyRightinfo: cr,isCopyRightDirty: true});
        }
    }

    private copyrightEmailChange(event: any) {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            cr.email = event.target.value;
            this.setState({_currentCopyRightinfo: cr,isCopyRightDirty: true});
        }
    }

    private copyrightISNIChange(event: any) {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            cr.isni = event.target.value;
            this.setState({_currentCopyRightinfo: cr,isCopyRightDirty: true});
        }
    }

    private copyrightAddressChange(event: any) {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            cr.address = event.target.value;
            this.setState({_currentCopyRightinfo: cr,isCopyRightDirty: true});
        }
    }

    private copyrightAddress2Change(event: any) {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            cr.address2 = event.target.value;
            this.setState({_currentCopyRightinfo: cr,isCopyRightDirty: true});
        }
    }

    private copyrightCityChange(event: any) {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            cr.city = event.target.value;
            this.setState({_currentCopyRightinfo: cr,isCopyRightDirty: true});
        }
    }

    private copyrightZipChange(event: any) {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            cr.postal = event.target.value;
            this.setState({_currentCopyRightinfo: cr,isCopyRightDirty: true});
        }
    }

    private saveCoptyRightInfo() {
        var cr = this.state._currentCopyRightinfo;
        if (cr!=undefined)
        {
            console.info("Save Copyright for " + cr.taskId);

            fetch('/api/workset/UpdateTaskCopyRight'
                    , {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(cr),
                    credentials: 'same-origin'
                })
                .then(response => {
                    this.setState({_currentCopyRightinfo: undefined, isCopyRightDirty: false})
                    App.publish("construction.copyright.close",null)
                })
                .catch(reason => {
                    console.log(reason);
                });
        }
    }

    private num: number = 0;
    //Function to generate unique id:s for components
    private getNextId(id: string): string {
        this.num ++;
        return id + this.num;
    }

    openInfoPanel(task: Api.TaskCoreRef): any {
        App.publish("history.open",task.taskid); 
    }

    openEdit(task: Api.TaskCoreRef): any {
        App.publish("construction.edit.open", task.taskid); 
        
    }

    openClone(task: Api.TaskCoreRef): any {
        App.publish("construction.clone.open", task.taskid); 
    }

    openCopyright(task: Api.TaskCoreRef): any {
        var cr = task.copyright;
        if (cr!=undefined)
        {
            cr.taskId = task.taskid;
        }
        else
        {
            cr = {
                taskId : task.taskid,
                name : '',
                address : '',
                address2 : '',
                city : '',
                copyrightHolder : '',
                copyrightType : '',
                email : '',
                isni : '',
                isniLink : '',
                postal : ''
            }
        }
        this.setState({_currentCopyRightinfo: cr});
        App.publish("construction.copyright.open", task.taskid); 
    }

    public render() {
        this.num = 0;
    
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div style={{cursor: this.state.isSaving ? 'progress': 'default'}}> 
       
        <Loading loading={this.state.loading} style={{height: '500px'}} >
        <React.Fragment>
            <div className="sticky" style={{zIndex: 100, marginLeft: '-40px', marginRight: '-40px', paddingRight: '30px'}}>
                { this.renderToolbar() }
            </div>
        
            <Row style={{marginBottom: '15px', borderBottom: '1px solid whitesmoke', paddingBottom: '10px', paddingTop: '10px'}}>
                <Col >
                    <h1>
                    <DraftEditor id="f_title_edit" textOnly={true} onChange={(dataId,data) => this.titleChange(dataId, data)} 
                        dataId={'f_title_edit'}/>
                    </h1>
                    <div>
                    <DraftEditor id="f_description_edit" textOnly={false} onChange={(dataId,data) => this.descriptionChange(dataId, data)}
                        dataId={'f_description_edit'}/>
                    </div>
                </Col>
                <Col md='5' className="border-left">
                    {this.state._workset !=undefined ? this.renderSummary(this.state._workset.summary, true, false) : <React.Fragment></React.Fragment>}
                </Col>
            </Row>
           
            { this.renderContents() }

            <InfoPanel title={lang.Text('activity.header')} cancelButtonLabel={lang.Text('button.close')} 
                modalPanelOpen="info.open"
                position="modal-panel-right">
                {this.renderActivity()}
            </InfoPanel>

            <InfoPanel title={this.state._currentGroup?.title}
                showHeader={false}
                cancelButtonLabel={lang.Text('button.close')}                                
                modalPanelOpen="group.overview.open"
                position="modal-panel-right">
                {this.renderOverview()}
            </InfoPanel>

            <ModalPanel title={lang.Text('copyright.header')} 
                okButtonLabel={lang.Text('button.save')}
                cancelButtonLabel={lang.Text('button.close')}
                leaveButtonLabel={lang.Text('btn.leave')}
                unsavedChangesText={lang.Text('modal.unsaved.changes')}
                isDirty = {this.state.isCopyRightDirty}
                modalPanelOpen="construction.copyright.open"
                modalPanelClose="construction.copyright.close"
                position="modal-panel-right"
                onSave={this.saveCoptyRightInfo}
            >
                <div className="yc-panel-md">
                <Form>
                    <Row form>
                        <Col md={10}>
                        <FormGroup>
                            <Label for="copyrightname">{lang.Text('copyright.name')}</Label>
                            <Input type="text" name="name" id="copyrightname" 
                            value={this.state._currentCopyRightinfo?.name} 
                            onChange={this.copyrightNameChange}/>
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                        <FormGroup>
                            <Label for="exampleEmail">{lang.Text('copyright.email')}</Label>
                            <Input type="email" name="email" id="exampleEmail" 
                            value={this.state._currentCopyRightinfo?.email} 
                            onChange={this.copyrightEmailChange}/>
                        </FormGroup>
                        </Col>
                        <Col md={6}>
                        <FormGroup>
                            <Label for="isni">{lang.Text('copyright.isni')}</Label> 
                           
                            <Input type="text" name="isni" id="isni" 
                            value={this.state._currentCopyRightinfo?.isni}
                            onChange={this.copyrightISNIChange}/>
                        </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for="exampleAddress">{lang.Text('copyright.address')}</Label>
                        <Input type="text" name="address" id="exampleAddress" 
                        //placeholder={lang.Text('copyright.address.placeholder')}
                        value={this.state._currentCopyRightinfo?.address}
                        onChange={this.copyrightAddressChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleAddress2">{lang.Text('copyright.address2')}</Label>
                        <Input type="text" name="address2" id="exampleAddress2" 
                        //placeholder={lang.Text('copyright.address2.placeholder')}
                        value={this.state._currentCopyRightinfo?.address2}
                        onChange={this.copyrightAddress2Change}/>
                    </FormGroup>
                    <Row form>
                        <Col md={6}>
                        <FormGroup>
                            <Label for="exampleCity">{lang.Text('copyright.city')}</Label>
                            <Input type="text" name="city" id="exampleCity" 
                            //placeholder={lang.Text('copyright.city.placeholder')}
                            value={this.state._currentCopyRightinfo?.city}
                            onChange={this.copyrightCityChange}/>
                        </FormGroup>
                        </Col>
                       
                        <Col md={2}>
                        <FormGroup>
                            <Label for="exampleZip">{lang.Text('copyright.zip')}</Label>
                            <Input type="text" name="zip" id="exampleZip" 
                            //placeholder={lang.Text('copyright.postal.placeholder')}
                            value={this.state._currentCopyRightinfo?.postal}
                            onChange={this.copyrightZipChange}/>
                        </FormGroup>  
                        </Col>
                    </Row>
                    <hr/>
                    <FormGroup>
                        <Label for="exampleSelect">{lang.Text('copyright.type')}</Label>
                        <Input type="select" name="select" id="exampleSelect" disabled={false}>
                        <option>Exclusive Copyright</option>
                        <option disabled>Copyright Transferable</option>
                        <option disabled>Creative Commons</option>
                        <option disabled>Public Domain</option>
                        </Input>
                    </FormGroup>
                    
                    </Form>
                </div>
            </ModalPanel>

            <ModalPanel title={lang.Text('clone.header')} 
                okButtonLabel={lang.Text('button.save')}
                cancelButtonLabel={lang.Text('button.close')}
                leaveButtonLabel={lang.Text('btn.leave')}
                unsavedChangesText={lang.Text('modal.unsaved.changes')}
                isDirty = {true}
                modalPanelOpen="construction.clone.open"
                position="modal-panel-right"
            >
                <div className="yc-panel-md">
                    
                </div>
            </ModalPanel>

            <p style={{color: 'whitesmoke'}}>{this.props.id}</p>

            <EditPanel />

            <PreviewPanel2 modalPanelOpen="task.preview.open"/>

        </React.Fragment>
        </Loading>
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private renderContents() {
       return this.state._workset!=undefined ? 
       this.state._workset._store.taskGroups.map(group =>
       <LanguageContext.Consumer>
       { lang => ( 
           <div key={this.getNextId('media')} style={{paddingBottom: '20px', borderBottom: '1px lightgray solid'}}>
           {/* { this.state._edit==task.id ? task._edit=true : task._edit= false} */}
           <Media className="top-spacing" >
               <Media left onClick={() => this.showGroupOverview(group)} >
                   <div className="group-pre fa fa-layer-group" 
                   style={{width: '40px', height: '40px',color: 'rgb(82, 82, 82)', fontSize: '16px', padding: '20px', marginRight: '20px',
                    cursor: 'pointer'}} >
                    </div>
               </Media>
               <Media body >
                    <Row>
                        <Col md="8">
                            <Row> 
                                <Col >     
                                 <h2>
                                    <DraftEditor id={this.getNextId('taskedit')} 
                                    textOnly={true} text={group.title} dataId={group.id}
                                    onChange={this.groupTitleChange}
                                    />
                                    </h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col > 
                                 <DraftEditor id={this.getNextId('groupedit')} 
                                        text={group.description} dataId={group.id}
                                        onChange={(dataId,data) => this.groupDescChange(dataId, data)}
                                        />
                                    </Col>
                            </Row>
                            <Row style={{marginTop: '35px'}}>
                                <Col>
                                {!group.view ?
                                <button 
                                    // style={{borderColor: 'white'}} 
                                    type="button" onClick={() => this.showSubTasks(group.id)} 
                                    className="btn btn-outline-dark pull-right btn-action">
                                        <i className="fa fa-chevron-circle-down" 
                                        title="plus" aria-hidden="true"></i>
                                        {' ' + lang.Text('toolbar.showtasks')}
                                    </button>
                                :
                                <button 
                                    // style={{borderColor: 'white'}} 
                                    type="button" onClick={() => this.hideSubTasks(group.id)} 
                                    className="btn btn-outline-dark pull-right btn-action">
                                        <i className="fa fa-chevron-circle-up" 
                                        title="plus" aria-hidden="true"></i>
                                        {' ' + lang.Text('toolbar.hidetasks')}
                                    </button>
                                }
                                {/* <button style={{borderColor: 'white'}} 
                                    type="button" 
                                    onClick={() => this.previewAdvanced(group.id)} 
                                    className="btn btn-outline-dark pull-right">
                                        <span className="iconic iconic-clipboard" 
                                        title="plus" aria-hidden="true"></span>
                                        {' ' + lang.Text('toolbar.preview')}
                                </button> */}
                                 {this.renderNewTaskButton(group, lang)}

                                </Col>
                                {/* <Col md="4">
                                   
                                </Col> */}
                            </Row> 
                       </Col>
                       {/* <Col md='4' className="border-left">
                           {this.state._workset !=undefined ? 
                            this.renderSummary(group.summary, false, true) 
                            : <React.Fragment></React.Fragment>}
                           
                       </Col> */}
                  </Row>
                  
                  <Fade in={group.view} className={ group.view ? "": "yc-collapse"}> 
                  <hr/>
                  {group.children.map(task => 
                   <Media className='top-spacing-sm' key={this.getNextId('media')}>
                       <Media left>
                       <UncontrolledButtonDropdown>
                            <DropdownToggle className="task-pre"
                                style={{backgroundColor: this.getStatusColor(task),
                                    color: this.getStatusForwardColor(task), 
                                // height: '30px', width: '20px',
                                borderColor: 'whitesmoke' }}>
                            <i className="fa fa-ellipsis-v"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => this.setTaskState(task,0)}>
                                <i className="fa fa-square mr-2" style={{color: '#e9ecef'}}/>{lang.Text('task.draft')}</DropdownItem>
                                <DropdownItem onClick={() => this.setTaskState(task,1)}>
                                <i className="fa fa-square mr-2" style={{color: '#5bc0de'}}/>{lang.Text('task.revision')}</DropdownItem>
                                <DropdownItem onClick={() => this.setTaskState(task,2)}>
                                <i className="fa fa-square mr-2" style={{color: '#f0ad4e'}}/>{lang.Text('task.test')}</DropdownItem>
                                <DropdownItem onClick={() => this.setTaskState(task,3)}>
                                <i className="fa fa-square mr-2" style={{color: '#5cb85c'}}/>{lang.Text('task.prod')}</DropdownItem>
                                <DropdownItem onClick={() => this.setTaskState(task,4)}>
                                <i className="fa fa-square mr-2" style={{color: '#d9534f'}}/>{lang.Text('task.deleted')}</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.openInfoPanel(task)}>{lang.Text('task.menu.history')}</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                       </Media>
                       {/* <Media left onClick={() => this.openInfoPanel(task)}>
                            <div className="task-pre" style={{width: '25px', height: '64px', cursor: 'pointer',
                                    backgroundColor: this.getStatusColor(task)}}></div>
                       </Media> */}
                       <Media body>
                           <Media heading >
                               <Row>
                                   <Col sm="8" md='7' lg="4">
                                    <DraftEditor id={this.getNextId('taskedit')} 
                                        textOnly={true} 
                                        text={task.state.title}
                                        dataId={task.taskid}
                                        readOnly={task.readOnly}
                                        onChange={(dataId,data) =>this.taskTitleChange(dataId,data)}
                                        />
                                    
                                        {/* {task.isNew?  <Badge color="primary">{lang.Text('taskref.isnew')}</Badge>  : ''}  */}

                                        {/* {task.state..map(tag => <a href='#'
                                            style={{marginLeft: '5px'}} className={tag.color}
                                            role="button" data-toggle="popover" data-trigger="focus" title={tag.desc} data-content={tag.data}
                                            onClick={ () => this.showTag(tag.link)}>
                                        {tag.title}
                                        </a>)}  */}

                                   </Col>
                                   <Col md='4'>
                                        <Button className="btn btn-outline-dark btn-sm pull-right btn-action" style={{marginRight: '5px'}}
                                            onClick={(event: any) => this.openPreview(event,task.taskid)}
                                            >
                                            <i className="fa fa-eye" title="preview" aria-hidden="true">
                                            </i>
                                            {/* {' '}{lang.Text('task.button.preview')} */}
                                        </Button>
                                        
                                       {task.readOnly ? 
                                       <Button type="button" className="btn btn-outline-dark btn-sm pull-right btn-action" 
                                          onClick={() => this.openEdit(task)}
                                          >                                               
                                           <i className="fa fa-lock" title={lang.Text('toolbar.noedit')} aria-hidden="true"></i>
                                       </Button>
                                       :
                                       <Button type="button" className="btn btn-outline-dark btn-sm pull-right btn-action" 
                                            onClick={() => this.openEdit(task)}>
                                           <i className="fa fa-pencil" title={lang.Text('toolbar.edittask')} aria-hidden="true"></i>
                                       </Button>
                                       }

                                       <Button className="btn btn-outline-dark btn-sm pull-right btn-action" style={{marginLeft: '5px'}}
                                            onClick={() => this.openClone(task)}
                                            disabled
                                            >
                                            <i className="fa fa-clone" title={lang.Text('toolbar.clone')} aria-hidden="true">
                                            </i>
                                            {/* {' '}{lang.Text('task.button.preview')} */}
                                       </Button>

                                        <Button className="btn btn-outline-dark btn-sm pull-right btn-action" style={{marginLeft: '5px'}}
                                            onClick={() => this.openCopyright(task)}
                                            >
                                            <i className="fa fa-copyright" title={lang.Text('toolbar.copyright')} aria-hidden="true">
                                            </i>
                                            {/* {' '}{lang.Text('task.button.preview')} */}
                                       </Button>
                                       
                                   </Col>
                               </Row>

                           </Media>
                           <DraftEditor id={this.getNextId('taskedit')} 
                               textOnly={false} dataId={task.taskid}
                               text={task.state.description}
                               onChange={(dataId,data) =>this.taskDescChange(dataId,data)}
                               readOnly={task.readOnly}
                               />
                           
                       </Media>
                   </Media>
                  )}
                  
                   <Row className='top-spacing-sm'>
                       <Col md='2'>
                           {/* {this.renderNewTaskButton(group, lang)} */}
                       </Col>
                       <Col md='10'></Col>
                   </Row>
               
               </Fade>
               </Media>
           </Media>
           
           </div>
           )}
           </LanguageContext.Consumer>                
       ) : <React.Fragment></React.Fragment> 
    }

    private openPreview(event: any,taskid: string) {
        let preview = {
            id: taskid,
            versionId: undefined
        }
        App.publish("task.preview.open", preview);
    }

    private opentask(task: Api.TaskCoreRef, group: Api.TaskGroup): any {
        let url = "task/".concat(task.taskid).concat('?taskgroup=').concat(encodeURIComponent(group.title));
        window.open(url,'_self');
    }
    private renderNewTaskButton(group: Api.TaskGroup, lang: Language) {
        return <button style={{marginLeft: '5px'}} type="button" onClick={() => this.addSubTask(group.id)} 
            className="btn btn-outline-dark pull-right btn-action">
            <i className="fa fa-plus-circle" title="plus" aria-hidden="true"></i>
            {' ' + lang.Text('toolbar.addtask')}
        </button>;
    }

    private setTaskState(task: Api.TaskCoreRef, state: number) {
        if (task.state.state!==state)
        {
            this.setState({isSaving: true});
            fetch('/api/workset/updateTaskState?id=' + task.taskid + "&state=" + state,
            GlobalAppsettings.GetPostInit(null))
              .then(response => {
                this.reload();
              })
              .catch(reason => {
                  console.log(reason);
            });
        }
    }

    private getStatusColor(task: Api.TaskCoreRef): any {
        let color = '#e9ecef';
        switch (task.state.state) {
            case 0: color = '#e9ecef'; break;
            case 1: color = '#5bc0de'; break;
            case 2: color = '#f0ad4e'; break;
            case 3: color = '#5cb85c'; break;
            case 4: color = '#d9534f'; break;
        }
        return color;
    }
    private getStatusForwardColor(task: Api.TaskCoreRef): any {
        let color = '#e9ecef';
        switch (task.state.state) {
            case 0: color = 'rgb(82, 82, 82)'; break;
            case 1: color = 'white'; break;
            case 2: color = 'white'; break;
            case 3: color = 'white'; break;
            case 4: color = 'white'; break;
        }
        return color;
    }

    private previewAdvanced(groupid:string) {
        let preview = {
            id: '',
            versionId: 'undefined',
            groupId: groupid,
            state: 'preview'
        }
        App.publish("preview.open", preview);
    }

    private goback() {
        App.publish("workset.back",{});
    }

    private renderToolbar() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
       
            <div className="d-flex justify-content-between" 
                style={{backgroundColor: 'white', borderBottom: '1px solid whitesmoke', height: '75px', paddingTop:'8px'}}>
                <div className="p-2 bd-highlight" style={{marginLeft: '30px'}}>
                    {/* <Button color="link" 
                        // className="btn btn-pill btn-outline-dark" 
                        onClick={this.goback}>
                         <span className="iconic iconic-caret-left" style={{marginRight: '12px'}}></span>
                         {lang.Text('workset.bank.back')}</Button> */}

                    <Search />
                </div>
                
                {/* <div className="p-2 bd-highlight"></div>
                <div className="p-2 bd-highlight"></div>
                <div className="p-2 bd-highlight"></div> */}

                <div className="p-2 bd-highlight">
                    {this.state.isDirty ?
                        <div className="navbar-margin ">
                            <SaveButton text={lang.Text('button.save')} 
                                saving={this.state.isSaving} onSave={() => this.saveAll()}
                                pill={false}></SaveButton>
                            </div>
                            : <div></div>
                        }
                </div>
            </div>

            </div>)}
            </LanguageContext.Consumer>
            </React.Fragment>
    }

    private renderOverview() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div className="yc-panel-md">
        <Container>
            <Row>
                <Col md='12'>
                        {this.state._workset !=undefined ? 
                        <h4>{this.state._currentGroup?.title}</h4>
                        : <React.Fragment></React.Fragment>}
                </Col>
            </Row>
            <Row>
                <Col md='12'>
                        {this.state._workset !=undefined ? 
                        this.renderSummary(this.state._currentGroup?.summary, false, true) 
                        : <React.Fragment></React.Fragment>}
                </Col>
            </Row>
            <hr/>
            <Row className="mb-3">
            <Col>
                <Label for="exampleSelect">Type of items:</Label>
                <Input type="select" name="select" id="exampleSelect">
                <option>Multiple choice</option>
                <option disabled>Free Text</option>
                <option disabled>Interactive</option>                
                <option disabled>MDX</option>
                <option disabled>Media</option>
                <option disabled>Math - KaTEx</option>
                <option disabled>VR</option>
                <option disabled>Reference</option>
                <option disabled>Dialog</option>
                </Input>
            </Col>
            </Row>
            <hr/>
            {/* <Row className="mb-3">
            <Col>
                <Label for="exampleSelect">SecureTest usage:</Label>
                <Input type="select" name="select" id="exampleSelect">
                <option>Multiple choice</option>
                <option>Free Text</option>
                <option>Interactive</option>
                <option>Reference</option>
                <option>Dialog</option>
                </Input>
            </Col>
            </Row>                   */}
            <small>{this.state._currentGroup?.id}</small>
        </Container>    
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    private renderActivity() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div className="yc-panel-md">
                <Alert color="primary">
                    {lang.Text('activity.info')} 
                    {/* Information om aktivitet och historik för frågan. */}
                </Alert>
                <form>
                    <div className="form-group ">
                        <div className="custom-control custom-checkbox">
                            <Input type="checkbox" className="custom-control-input" 
                                id="customCheck1" role="checkbox" 
                                //checked={this.state._} onChange={value => this.checkboxChange(value)}
                                />
                            <Label className="custom-control-label" for="customCheck1">{lang.Text('activity.showall')}</Label>
                        </div>
                    </div>
                </form>    
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

   
    showGroupOverview(group: Api.TaskGroup): any {
        this.setState({_currentGroup: group});
        App.publish("group.overview.open",{} );
        console.log("group.overview.open");
    }

    private renderSummary(summary?: Api.Summary, renderTarget?: boolean, renderOnlyPopulated?: boolean) {        
        if (summary!=undefined)
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>

        <p>{lang.Text('workset.summary.total')} {summary.total}</p>
            
            {(!renderOnlyPopulated || summary.drafts>0) ? 
            <Row className="top-spacing">
                <Col md="4">
                   {lang.Text('workset.summary.draft')}
                </Col><Col style={{paddingTop: '4px'}}><Progress 
                    className="" color="draft" value={summary.drafts} max={summary.total} /></Col>
                <Col md="1" >
                {summary.drafts}
                </Col>
                <Col md="1" ></Col>
            </Row> 
            : <React.Fragment></React.Fragment>
            }
            
            {(!renderOnlyPopulated || summary.revisions>0) ?
            <Row className="top-spacing">
                <Col md="4">
                   {lang.Text('workset.summary.revision')}
                </Col><Col style={{paddingTop: '4px'}}><Progress color="info" value={summary.revisions} max={summary.total} /></Col>
                <Col md="1" >
                {summary.revisions}
                </Col>
                <Col md="1" ></Col>
            </Row>
            : <React.Fragment></React.Fragment>
            } 

           {(!renderOnlyPopulated || summary.previews>0) ?
            <Row className="top-spacing">
                <Col md="4">
                    {lang.Text('workset.summary.test')}
                </Col>
                <Col style={{paddingTop: '4px'}}>
                    <Progress color="warning" value={summary.previews} max={summary.total} />
                </Col>
                <Col md="1" >
                {summary.previews}
                </Col>
                <Col md="1" ></Col>
            </Row>
            : <React.Fragment></React.Fragment>
            }

            {(!renderOnlyPopulated || summary.production>0) ?
            <Row className="top-spacing">
                <Col md="4">
                    {lang.Text('workset.summary.prod')}
                </Col>
                <Col style={{paddingTop: '4px'}}>
                    <Progress color="success" value={summary.production} max={summary.total} />
                </Col>
                <Col md="1" >
                    {summary.production}
                </Col>
                <Col md="1" ></Col>
            </Row>
            : <React.Fragment></React.Fragment>
            }

            {(!renderOnlyPopulated || summary.deleted>0) ?
            <Row className="top-spacing">
                <Col md="4">
                    {lang.Text('workset.summary.deleted')}
                </Col>
                <Col style={{paddingTop: '4px'}}>
                    <Progress color="danger" value={summary.deleted} max={summary.total} />
                </Col>
                <Col md="1" >
                    {summary.deleted} 
                </Col>
                <Col md="1" ></Col>
            </Row>
            : <React.Fragment></React.Fragment>
            }
           
            {renderTarget ?
            <div>
                <hr/>
                <Row className="top-spacing">
                    <Col md="4">
                    {lang.Text('workset.summary.goal')} <InfoLink title='' keyword={lang.Text('workset.summary.goal')}/>
                    </Col>
                    <Col style={{paddingTop: '4px'}}>
                        <Progress color="success" value={summary.production} max={summary.productionTarget} style={{height: '30px'}}>
                            {summary.production>30 ? summary.production : ''} 
                        </Progress>
                    </Col>
                    <Col md="1" >
                        {summary.productionTarget}
                    </Col>
                    <Col md="1" ></Col>
                </Row>
            </div>
            : <React.Fragment></React.Fragment>
            }
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
}

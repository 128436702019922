import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import './panel.css';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export interface InfoPanelState {
    modal: boolean
}

export interface InfoPanelProps {
    title?: string,
    description?: string,
    header?: React.ReactNode, 
    color?: string,
    cancelButtonLabel?: string,
    modalPanelOpen?: string,
    showHeader?: boolean,
    position?: string ,
    onClosed?: (()=> void)
}

export class InfoPanel extends React.Component<InfoPanelProps, InfoPanelState> {
    constructor(props:any) {
        
        super(props);

        this.state = {
            modal: false
        };
        
        this.close = this.close.bind(this);
    }


    public componentDidMount() {
        App.subscribe(this.props.modalPanelOpen,
            this.open.bind(this));
    }

    public componentWillUnmount() {
        App.unsubscribe(this.open.bind(this));
    }

    open() {
        //Load data
        this.setState({
            modal: true
        });
    }

    close() {
        this.setState({
            modal: false
        });
    }

    render() {
        
        let showHeader = true;

        if (this.props.showHeader!=undefined)
        {
            showHeader = this.props.showHeader;
        }

        return <div>
            <Modal isOpen={this.state.modal} toggle={this.close} contentClassName="modal-panel-content yc-panel-dynamic"
                 modalClassName={this.props.position} onClosed={this.props.onClosed}>
                    {showHeader ?
                    <div className="yc-navbar-panel modal-header">
                        <div className="p-2 flex-grow-1 bd-highlight" 
                        style={this.props.color ? {borderLeft: '10px solid ' + this.props.color, marginLeft: '-16px'}:{}}
                        >
                            {this.props.header ? this.props.header : <div>
                                <h1>{ this.props.title }</h1>
                                <div >
                                    { this.props.description } 
                                </div>
                            </div>}
                        </div>
                        <div className="p-2 bd-highlight">                                       
                            <button type="button" onClick={this.close} className="close" aria-label="Close"><span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                    : <React.Fragment></React.Fragment>}
                    <ModalBody style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    { this.props.children }
                    </ModalBody>
                    <ModalFooter>
                    <button className="btn btn-link"
                        onClick={this.close}>{ this.props.cancelButtonLabel }</button>
                    </ModalFooter>
            </Modal>
        </div>
    }
}

import React, { Component } from 'react';
import { LanguageContext, Language } from '../backbone/Language';
import { Container, Button, Fade, Row, Col, Alert, Card, UncontrolledCarousel, CustomInput } from 'reactstrap';

import * as App from 'pubsub-js';
import { GlobalAppsettings } from '../backbone/GlobalSettings';



import back1 from "../images/back_videoconf.gif";

import { Loading } from '../components/base/Loading';
import { User } from '../api/User';
import { AppCore } from '../backbone/AppCore';

import { default as LogoBlackBlue} from '../images/Logo_BlackBlue';
import { default as LogoWhiteBlue} from '../images/Logo_WhiteBlue';
import { default as LogoColor} from '../images/Logo_Color';

import { Application } from '../api/App';
import { LandingPageContent } from '../api/LandingPage';
import { InfoPanel } from '../panels/InfoPanel';

import { motion, AnimatePresence } from "framer-motion";
import { ContentPanel } from '../panels/ContentPanel';
import { Hero } from '../layout/Hero';

import { UrlHelper } from '../backbone/UrlHelper';
import ReactMarkdown from 'react-markdown';


export interface LandingPageProps {
    contentId?: string
    preview?: boolean
}

export interface LandingPageState {
    loading: boolean;
    user: User.UserProfile;
    app: Application.AppProfile;
    content?: LandingPageContent;
    contentId: string;
    locale: string,
    preview: boolean
}


export class LandingPage extends React.Component<LandingPageProps,LandingPageState> {
  static displayName = LandingPage.name;
  static contextType = LanguageContext;

  constructor(props:LandingPageProps) {
    super(props);

    this.state = {
      loading: true,
      user: AppCore.GetUserProfile(),
      app: AppCore.GetAppProfile(),
      locale: Language.GetLocale(),
      preview:  this.props.preview ? this.props.preview : false,
      contentId: this.props.contentId ?  this.props.contentId : ''      
    }


  }

  componentDidMount() {
    App.subscribe('app.auth-changed', this.authChanged.bind(this));

    let contentId = this.state.contentId;
    if (contentId===null || contentId ==='')
    {
        contentId = UrlHelper.GetIdFromCurrentURL();
    }

    fetch('api/content/getLandingPage?id=' + contentId + '&locale=' + this.state.locale
      + '&preview=' + this.state.preview,
      {credentials: "same-origin"})
      .then(response => response.json() as Promise<LandingPageContent>)
      .then(data => {    
          console.log(data);
          this.setState({content: data, loading: false});
      }).catch(reason => {
          console.log(reason);
          this.setState({loading: false});
      });
  }

  componentWillUnmount() {
    App.unsubscribe('app.auth-changed');
  }

  authChanged(msg: string, data: any) {
    let u = AppCore.GetUserProfile();
    if (u.isloggedIn)
    {
      this.setState({user: u});
      console.log(this.state);
    }
  }

  render () {
    let isLoggedIn = false;
    if (this.state.user!=null)
      isLoggedIn =  this.state.user.isloggedIn;
    
    App.publish('navigation.update',{});

    return ( <React.Fragment>
    <LanguageContext.Consumer>
      {lang => <div>
        <React.Fragment>
          {this.state.loading ? 
            <Loading loading style={{height: '100vh'}}/>
            : this.landingPage(lang)}
        </React.Fragment>
      </div>}
    </LanguageContext.Consumer> 
    </React.Fragment>
    );
  }

  private landingPage(lang: Language): React.ReactNode {
    let app =  AppCore.GetAppProfile();
    return ( <React.Fragment>
      
      <Hero currentHeroItem={this.state.content?.hero}/>
      
      {this.state.content?.sections.map((section,i) => {
          if (section.image!=undefined) {

          }
          else
          if (section.items?.length > 3) {
            var color = section.sectionColor;

            return ( 
             
              <section key={i} style={{backgroundColor: color, minHeight:'250px', padding: '20px', color: section.textColor}} 
              className="text-center u-content-space">
                <div className="container">
                  {section.description ?
                  <header className="text-center w-md-50 mx-auto mb-8">
                    <h2 className="h1">{section.title}</h2>
                    <div className="h5"><ReactMarkdown key={'rm' + i} source={section.description}/></div>
                  </header> : <React.Fragment></React.Fragment>}
                  <div className="row">
                    {section.items?.slice(0,3).map((item,i) => {return(
                    
                      <div key={i} className="col-lg-4 mb-5 mb-lg-0">
                        <div className="display-4 text-primary mb-2">
                        <i className={"fab " + item.icon} style={{color: section.textColor}}/>
                        </div>
                        <h4 className="h5">{item.title}</h4>
                        <p>{item.description}</p>
                        {item.link ? <a href={item.link?.url} style={{color: section.textColor}}>
                          {lang.Text("read.more")}...</a>: <React.Fragment></React.Fragment>}
                      </div>
                     
                      )})}
                  </div>
                  <hr className="my-8"/>
                  <div className="row">
                    {section.items?.slice(4,section.items.length).map((item,i) => {return(
                      <div key={i} className="col-lg-4 mb-5 mb-lg-0">
                        <div className="display-4 text-primary mb-2">
                        <i className={"fab " + item.icon} style={{color: section.textColor}}/>
                        </div>
                        <h4 className="h5">{item.title}</h4>
                        <p>{item.description}</p>
                        {item.link ? <a href={item.link?.url} style={{color: section.textColor}}>
                          {lang.Text("read.more")}...</a>: <React.Fragment></React.Fragment>}
                      </div>
                      )})}
                  </div>
                </div>
              </section>
              );
          }
          else
          {
            var color = section.sectionColor;
            
            return ( 
              // <motion.div
              //          initial={{ opacity: 0 }}
              //          animate={{ opacity: 1 }}
              //          exit={{ opacity: 0 }}
              //          transition={{
              //           restSpeed: 1.5,
              //           type: "spring",
              //           stiffness: 260,
              //           damping: 20,
              //          delay: 3}}
              //        >
                      
              <section key={i} style={{backgroundColor: color, minHeight:'250px', padding: '20px', color: section.textColor}} 
                className="text-center u-content-space">
                <div className="container" style={{marginTop: '40px'}}>
                  {section.description?.length > 0 ?
                  <header className="text-center w-md-50 mx-auto mb-8">
                    <h2 className="h1">{section.title}</h2>
                    <div className="h5"><ReactMarkdown key={'rm' + i} source={section.description}/></div>
                  </header> : <React.Fragment></React.Fragment>}
                  <div className="row">
                  {section.items?.map((item,i) => {return(
                    <div key={i} className="col-lg-4 mb-5 mb-lg-0">
                      <div className="display-4 text-primary mb-4">
                      <i className={"fas " + item.icon} style={{color: section.textColor}}/>
                      </div>
                      <h4 className="h5">{item.title}</h4>
                        <p>{item.description}</p>
                        {item.link ? <a href={item.link?.url} style={{color: section.textColor}}>
                          {lang.Text("read.more")}...</a>: <React.Fragment></React.Fragment>}
                    </div>
                  )})}
                  </div>
                </div>
            </section>
            // </motion.div>
            );
          }
      })}

    
    </React.Fragment>);
  }
  
  
  
}

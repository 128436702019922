import * as React from 'react';
import * as App from 'pubsub-js';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, 
    Row, Col, Card, CardImg, CardBody, CardTitle, Nav, NavItem, 
    TabContent, TabPane, NavLink, Container, Badge, Fade, UncontrolledAlert, CustomInput } from 'reactstrap';

import { LanguageContext, Language } from '../../../backbone/Language';
import { Api } from '../api/WorkSet';
import { DraftEditor, DraftEditorData } from '../../../components/drafteditor/DraftEditor';

import { SaveButton } from '../../../components/base/SaveButton';
import { Logo } from '../../../components/Logo';
import { Loading } from '../../../components/base/Loading';

import { Analytics } from '../components/Analytics';

import Moment from 'react-moment';
import { TaskMedia } from '../components/TaskMedia';
import { Revision } from '../components/Revision';

export interface PreviewPanel2State {
    modal: boolean,
    loading : boolean,
    analyticsLoading : boolean,
    readOnly: boolean,
    currentId?: string,
    currentVersionId?: string,
    task?: Api.TaskCore,
    previewActiveTab: string;
    currentCommentUnsaved: boolean;
    currentCommentIsSaving: boolean;
    tabsOpen: boolean;
    showOpenTaskBtn: boolean;
    currentAnalytics?: Api.TaskAnalytics,
    currentRevisions?: Api.TaskRevision[],
    shallowRefs?: Api.TaskShallowRef[];
    isGroupPreview: boolean;
    groupTaskIndex: number;
    showTools: boolean;
    currentRevisionID?: string;
    showNavigation: boolean;
}

export interface PreviewPanel2Props {
    title?: string;
    cancelButtonLabel?: string;
    modalPanelOpen?: string;
    position?: string;
    onClosed?: (()=> void);
}

export interface PreviewParams {
    id: string;
    versionId: string;
    showTask?: boolean; 
    groupId?: string;
    state?: string;
}

export class PreviewPanel2 extends React.Component<PreviewPanel2Props, PreviewPanel2State> {
    constructor(props:any) {
        
        super(props);
        
        this.state = {
            modal: false,
            loading: true,
            analyticsLoading: true,
            readOnly: false,
            previewActiveTab: '0',
            currentCommentUnsaved: false,
            currentCommentIsSaving: false,
            tabsOpen: false,
            showOpenTaskBtn: true,
            isGroupPreview: false,
            groupTaskIndex: 0,
            showTools: false,
            showNavigation: false,
        };
        
        this.close = this.close.bind(this);
        this.load = this.load.bind(this);

        this.previewComment = null;
        this.commentEnd = null;

        this.togglePreviewTab = this.togglePreviewTab.bind(this);
        this.closePreviewTab = this.closePreviewTab.bind(this);

        this.openTask = this.openTask.bind(this);
        this.history = this.history.bind(this);

        this.renderAnalytics = this.renderAnalytics.bind(this);
    }

    private num: number = 0;
    //Function to generate unique id:s for components
    private getNextId(id: string): string {
        this.num ++;
        return id + this.num;
    }

    private previewComment: DraftEditor | null;

    private _currentComment: string = ""; 
    private _currentCommentPlainText: string = "";

    private commentEnd: HTMLDivElement | null = null;

    public componentDidMount() {
        App.subscribe(this.props.modalPanelOpen,
            this.open.bind(this));
        
            App.subscribe("revision.reload",
                this.reload.bind(this));
    }

    public componentWillUnmount() {
        App.unsubscribe(this.open.bind(this));
        App.unsubscribe(this.reload.bind(this));
    }

    private open(message: string, params: PreviewParams) {
        
        console.info(params);

        let _showTask = true;
        if (params.showTask!=undefined)
        {
            _showTask = params.showTask;
        }

        this.setState({
            modal: true, loading: true, task: undefined, showTools: false,
            showOpenTaskBtn: _showTask, analyticsLoading: true, currentRevisions: undefined
        });

        if (params.groupId!=undefined)
        {
            fetch('api/workset/GetTaskGroupTasks?id='+ params.groupId,
                    {credentials: "same-origin"})
                    .then(response => response.json() as Promise<Api.TaskShallowRef[]>)
                    .then(data => {
                        console.info(data);

                        if (data.length > 0)
                        {
                            this.setState({shallowRefs: data, isGroupPreview: true});
                            this.setState({
                                currentId: data[0].taskId, 
                                currentVersionId: undefined
                            });
                            this.load();
                        }
                       
                        if (this.commentEnd!=null) {
                            this.commentEnd.scrollIntoView({ behavior: "smooth" });
                        }

                    }).catch(reason => {
                        this.setState({ loading: false });
                });
        }
        else {
            
            this.setState({currentId: params.id, currentVersionId: params.versionId});
            this.load();
        }
    
        App.publish('intercom.event','Open PreviewPanel');
    }

    private reload() {
        this.load();
    }

    private closePreviewTab() {
        this.setState({
            previewActiveTab: '0', 
            tabsOpen: false
          });
    }

    private togglePreviewTab(tab: string) {
        if (this.state.previewActiveTab !== tab) {
          this.setState({
            previewActiveTab: tab, 
            tabsOpen: true
          });

          if (this.commentEnd!=null) {
            this.commentEnd.scrollIntoView({ behavior: "smooth" });
          }
          
        }
      }

    private close() {
        this.setState({
            modal: false
        });
    }

    private clearComment() {
        let data: DraftEditorData = {
            contentStateRaw: '',
            contentStateText: '',
            id: ''
        }
        App.publish('preview-comment-input', data)
    }

    private cancelSaveComment() {
        this.setState({currentCommentUnsaved: false, currentCommentIsSaving: false});
        this.clearComment();
    }

    private saveComment() {
       
        if (this.state.task!=undefined)
        {
            this.setState({currentCommentIsSaving: true});
            let newComment = {
                taskId: this.state.task.id,
                plainText: this._currentCommentPlainText,
                comment: { 
                    text: this._currentComment,
                }
            }

            fetch('/api/workset/addcomment', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(newComment),
                credentials: 'same-origin'
            })
            .then(response => {
                this.setState({currentCommentUnsaved: false,currentCommentIsSaving: false});
                this.clearComment();

                this.load();
            })
            .catch(reason => {
                console.log(reason);
                this.setState({currentCommentUnsaved: false,currentCommentIsSaving: false});
            });
        }
    }

    private load() {
        
        if (this.state.currentId!=undefined)
        {
            if (this.state.currentVersionId!=undefined)
            {
                fetch('api/workset/gettaskversion?id='+ this.state.currentId 
                + '&versionid=' + this.state.currentVersionId,
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<Api.TaskCore>)
                .then(data => {
                    this.setState({task: data, loading: false, readOnly: true});
                    
                    if (this.commentEnd!=null) {
                        this.commentEnd.scrollIntoView({ behavior: "smooth" });
                    }

                }).catch(reason => {
                    this.setState({ loading: false });
                });

                fetch('api/revision/GetRevisionsForTaskId?taskid='+ this.state.currentId,
                {credentials: "same-origin"})
                .then(response =>  response.json() as Promise<Api.TaskRevision[]>)
                .then(data => {
                    this.setState({currentRevisions: data});

                }).catch(reason => {
                    this.setState({ currentRevisions: undefined });
                });

                // fetch('api/workset/taskanalytics?id='+ this.state.currentId, 
                // {credentials: "same-origin"})
                // .then(response => response.json() as Promise<Api.TaskAnalytics>)
                // .then(data => {
                //     this.setState({analyticsLoading: false,currentAnalytics: data});
                    
                //     console.info(data);

                // }).catch(reason => {
                //     this.setState({ analyticsLoading: false,currentAnalytics: undefined });
                //     console.info(reason);
                // });
            }
            else
            {
                fetch('api/workset/gettask?id='+ this.state.currentId,
                    {credentials: "same-origin"})
                    .then(response => response.json() as Promise<Api.TaskCore>)
                    .then(data => {

                        console.info(data);

                        this.setState({task: data, loading: false, readOnly: data.readOnly});

                        if (this.commentEnd!=null) {
                            this.commentEnd.scrollIntoView({ behavior: "smooth" });
                        }

                    }).catch(reason => {
                        this.setState({ loading: false });
                });

                fetch('api/revision/GetRevisionsForTaskId?taskid='+ this.state.currentId,
                {credentials: "same-origin"})
                .then(response =>  response.json() as Promise<Api.TaskRevision[]>)
                .then(data => {
                    this.setState({currentRevisions: data});
                }).catch(reason => {
                    this.setState({ currentRevisions: undefined });
                });

                // fetch('api/workset/TaskAnalytics?id='+ this.state.currentId,
                // {credentials: "same-origin"})
                // .then(response =>  response.json() as Promise<Api.TaskAnalytics>)
                // .then(data => {
                //     this.setState({analyticsLoading: false,currentAnalytics: data});
                    
                //     console.info(data);

                // }).catch(reason => {
                //     this.setState({ analyticsLoading: false,currentAnalytics: undefined });
                //     console.info(reason);
                // });
            }


        }
        else {
            console.info('No CurrentTaskId!');
        }
    }

    private onCommentChange(id:string, data: DraftEditorData) {
        if (data.contentStateRaw!=undefined)
        {
            if (id=='task.comment' && data.contentStateRaw!=undefined && data.contentStateText!=undefined)
            {
                this._currentComment = data.contentStateRaw;
                this._currentCommentPlainText = data.contentStateText;
                
                if (!this.state.currentCommentUnsaved)
                {
                    this.setState({currentCommentUnsaved: true})
                }
            }
        }
    }

    private focusPreviewCommentInput() {
        if (this.previewComment!=null)
        {
            this.previewComment.setFocus();
        }
        if (this.commentEnd!=null) {
            this.commentEnd.scrollIntoView({ behavior: "smooth" });
        }
    }

    private openTask() {
        console.info(this.state.currentId);
        if (this.state.currentId!=undefined){
            let url = "task/".concat(this.state.currentId).concat('?taskgroup=').concat('Remisser');
            window.open(url,'_self');
        }
    }

    private history() {
        console.info(this.state.currentId);
        if (this.state.currentId!=undefined){
            App.publish("history.open",this.state.currentId);
        }
    }

    render() {
        
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
        <Modal isOpen={this.state.modal} toggle={this.close}
            contentClassName="modal-panel-content"
            //contentClassName="modal-panel-content-preview"
            modalClassName="modal-panel-right" onClosed={this.props.onClosed}>
            
            <div className="d-flex bd-highlight" style={{height: '100%'}}>
                <div className="p-2 flex-fill bd-highlight" >
                
                <div className="d-flex align-items-start flex-column bd-highlight mb-3" style={{height: '100%'}}>
                    <div className="bd-highlight" style={{
                            // height: '85px',
                            paddingBottom: '5px', 
                            borderBottom: '1px solid whitesmoke', 
                            width: '100%'
                            }}>
                        {this.renderHeader2(this.state.task)}
                    </div>
                    <div className="p-2 flex-fill bd-highlight" style={{width: '100%'}}>
                        {this.state.loading ? 
                            <div style={{minWidth: '750px'}}>
                            <Loading loading={true}/>
                            </div>
                            :
                            <React.Fragment>
                            {this.renderPreview2(this.state.task)}
                            </React.Fragment>
                        }
                    </div>
                    {this.state.showNavigation ?
                    <div className="mt-auto bd-highlight" style={{width: '100%',
                        marginLeft: '0px', marginRight: '0px', padding: '15px',
                        marginBottom: '-1px'}}>
                        {!this.state.loading ?
                            <React.Fragment>
                            <Button color="primary" className="btn-sm btn-pill" disbled>{lang.Text('media.prev')}</Button>
                            <Button color="primary" className="btn-sm btn-pill float-right" disbled>{lang.Text('media.next')}</Button>
                            </React.Fragment> : 
                            <React.Fragment></React.Fragment>
                        }
                    </div> : <React.Fragment></React.Fragment>}
                    <div className="mt-auto bd-highlight" style={{width: '100%',
                        marginLeft: '0px', marginRight: '0px', padding: '0px',
                        marginBottom: '-1px'}}>
                        {!this.state.loading ?
                            <React.Fragment>
                            {this.renderTabs2(this.state.task)}
                            </React.Fragment> : 
                            <React.Fragment></React.Fragment>
                        }
                    </div>
                    <div className="mt-auto  bd-highlight" style={{
                        // height: '85px',
                        // borderTop: '1px solid whitesmoke',
                        width: '100%'}}>
                        <ModalFooter>
                            <div >
                                {this.state.showOpenTaskBtn ? 
                                <Button color="primary" style={{marginLeft: '10px'}} className="pull-left"
                                    onClick={this.openTask}>{lang.Text('previewpanel.opentask')}
                                </Button>
                                : <React.Fragment></React.Fragment>}
                                <Button color="secondary" style={{marginLeft: '10px'}} 
                                className="btn-dark-outline pull-left" 
                                    onClick={this.history}>{lang.Text('previewpanel.history')}</Button>
                            </div>
                            
                            <Button color="link" onClick={this.close}>{lang.Text('button.close')}</Button>
                        </ModalFooter>
                    </div>
                </div>
                </div>
                {this.state.showTools ?
                <div className="p-2 bd-highlight fade show" style={{minWidth: '285px',
                    borderLeft: '1px solid #e1e1e1',
                    backgroundColor: '#f9f9f9'
                    }}>
                    <Revision revisionID={this.state.currentRevisionID} />
                </div>
                : <React.Fragment></React.Fragment>
                }
            </div>


            

        </Modal>
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private renderHeader2(task?: Api.TaskCore) {
        if (task!=undefined)
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => (
            <div className="d-flex bd-highlight" style={{fontSize: '0.8em', color: '#6c6f73'}}>
                <div className="p-2 flex-grow-1 bd-highlight"><h2>{task.title}</h2>
                 <div style={{fontSize: '14px'}}>
                 <DraftEditor
                        textOnly={false} readOnly={true}
                        id='preview-description' dataId='preview.description.data'
                        text={task.description}
                    ></DraftEditor>
                </div>
                </div>
                <div className="p-2 bd-highlight">
                        {lang.Text('task.preview.version')}: <b>{task.versionText}</b><br/>
                        {lang.Text('task.preview.updated')}: <b>
                            <Moment format="YYYY-MM-DD HH:mm"
                                date={task.timeStamp}/></b>
                        <br/>
                        {lang.Text('task.preview.id')}:  <b>{task.id}</b>
                </div>
                
                <div className="p-2 bd-highlight">
                    {lang.Text('task.preview.state')}: {this.getState(task,lang)}
                        
                </div> 
            </div>
        )}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    
    private getState(task: Api.TaskCore, lang: Language): any {
        switch (task.state)
        {
            case 0:
                return <Badge color="dark" style={{fontSize: '13px'}}>{lang.Text('workset.summary.draft')}</Badge>;
                break;
            case 1:
                return <Badge color="primary" style={{fontSize: '13px'}}>{lang.Text('workset.summary.revision')}</Badge>;
                break;
            case 2:
                return <Badge color="warning" style={{fontSize: '13px'}}>{lang.Text('workset.summary.test')}</Badge>;
                break;
            case 3:
                return <Badge color="success" style={{fontSize: '13px'}}>{lang.Text('workset.summary.prod')}</Badge>;
                break;
            case 4:
                return <Badge color="danger" style={{fontSize: '13px'}}>{lang.Text('workset.summary.deleted')}</Badge>;
                break;
        }

        return 
    }
    
    private renderPreview2(task?: Api.TaskCore) {
        if (task!=undefined)
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => (
            <div className="d-flex flex-row align-items-strech justify-content-center" 
                style={{minWidth: '750px'}}>
                <div className="p-2 flex-grow-1  bd-higlight" style={{marginLeft: '50px'}}>
                    <h3 >
                    <DraftEditor
                        textOnly={false} readOnly={true}
                        id='preview-text' dataId='preview.text.data'
                        text={task.question.text}
                    ></DraftEditor>
                    </h3>
                    <div style={{marginTop: '25px', marginLeft: '25px'}}>
                        <CustomInput type="checkbox" id="a1" label={task.question.correctAnswer} checked={true} ></CustomInput>
                        <CustomInput type="checkbox" id="a2" label={task.question.distractor1} checked={false} ></CustomInput>
                        <CustomInput type="checkbox" id="a3" label={task.question.distractor2} checked={false} ></CustomInput>
                        <CustomInput type="checkbox" id="a4" label={task.question.distractor3} checked={false} ></CustomInput>
                    </div>
                </div>
                {task.mediaRefs.length > 0 ?
                <div className="p-2 bd-higlight shadow" 
                    style={{backgroundColor: '', width: '320px',marginLeft: '25px'}}
                    >
                    <TaskMedia task={task} searchCmd='media.open' removeCmd='taskedit.removemedia'
                        renderNav={false} 
                        renderTools={false}
                        renderDirection={true}
                    />                    
                </div> : <React.Fragment></React.Fragment>
                }
            </div>
        )}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

  
    private renderTabs2(task?: Api.TaskCore) {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => (
            <React.Fragment>
                <Nav tabs >
                    <NavItem className="yc-preview-tab-item">
                        <NavLink
                            className={this.state.previewActiveTab === '0'? 'yc-preview-tab active' : 'yc-preview-tab'}
                            onClick={() => { this.closePreviewTab(); }}
                            >
                            {this.state.tabsOpen ?
                                 <span className="fas fa-angle-down"></span>
                            : 
                                <span className="fas fa-angle-up"></span>
                            }
                        </NavLink>
                    </NavItem>
                    <NavItem className="yc-preview-tab-item">
                        <NavLink
                            className={this.state.previewActiveTab === '1'? 'yc-preview-tab active' : 'yc-preview-tab'}
                            onClick={() => { this.togglePreviewTab('1'); }}
                            >
                            {lang.Text('task.preview.comments')}
                        </NavLink>
                    </NavItem>
                    <NavItem className="yc-preview-tab-item">
                        <NavLink
                            className={this.state.previewActiveTab === '2'? 'yc-preview-tab active' : 'yc-preview-tab'}
                            onClick={() => { this.togglePreviewTab('2'); }}>
                            {lang.Text('task.preview.analytics')}
                        </NavLink>
                    </NavItem>
                    <NavItem className="yc-preview-tab-item">
                        <NavLink
                            className={this.state.previewActiveTab === '3'? 'yc-preview-tab active' : 'yc-preview-tab'}
                            onClick={() => { this.togglePreviewTab('3'); }}>
                            {lang.Text('task.preview.statistics')}
                        </NavLink>
                    </NavItem>
                    {(this.state.currentRevisions!=undefined && this.state.currentRevisions.length> 0) ?
                     <NavItem className="yc-preview-tab-item">
                        <NavLink
                            className={this.state.previewActiveTab === '4'? 'yc-preview-tab active' : 'yc-preview-tab'}
                            onClick={() => { this.togglePreviewTab('4'); }}>
                            {lang.Text('task.preview.revision')}
                        </NavLink>
                    </NavItem>
                    : <React.Fragment></React.Fragment> 
                    }
                </Nav>
                {this.state.tabsOpen ?
                // <Fade smooth >
                    <TabContent activeTab={this.state.previewActiveTab} style={this.state.tabsOpen ? {height: '260px'} : {}}>   
                        <TabPane tabId="0">
                        </TabPane>
                        <TabPane tabId="1">
                        <div className="d-flex bd-highlight">
                        <div className="p-2 flex-fill bd-highlight" style={{minWidth: '50vh'}}>
                            <div style={{height: '230px',maxHeight: '230px', 
                                overflowY: 'auto', paddingRight: '5px'}}>
                                    { task!=undefined ?
                                        //this.state.current.comments.slice(0).reverse().map(comment =>
                                        task.comments.length > 0 ?
                                        task.comments.map(comment =>
                                                <Alert color={this.getCommentColor(comment.type)}
                                                    isOpen={true} style={{backgroundColor: this.getCommentDefaultColor(comment.type)}}>
                                                    <span className="iconic iconic-person-genderless" >
                                                    </span><span>{' '}{comment.from} - {comment.timeStamp}</span>
                                                    <p>
                                                        <DraftEditor
                                                            textOnly={false} readOnly={true}
                                                            id={this.getNextId('comment')} dataId={this.getNextId('comment')} 
                                                            text={comment.text}
                                                        ></DraftEditor>
                                                    </p>
                                                </Alert>
                                        ) : <div><i>{lang.Text('task.preview.nocomments')}</i></div>
                                        : <div></div>
                                    }
                                    <div style={{ float:"left", clear: "both" }}
                                        ref={(el) => { this.commentEnd = el; }}>
                                    </div>
                                </div>
                        </div>
                        {!this.state.readOnly ?
                        <div className="p-2 bd-highlight" style={{ minWidth: '30vh' }}>
                            <div className="callout" style={{height: '230px'}}>
                                <div className="callout-content" style={{verticalAlign: 'top'}} 
                                        onClick={this.focusPreviewCommentInput.bind(this)}>
                                        <span>{lang.Text('task.preview.newcomment')}</span>
                                        <div style={{maxHeight: '180px', overflowY: 'auto'}}>
                                            <DraftEditor ref={(component) => { this.previewComment = component; }} 
                                                    textOnly={false} onChange={this.onCommentChange.bind(this)}
                                                    id="preview-comment-input" dataId="task.comment" text=''
                                            ></DraftEditor>
                                        </div>
                                </div>
                                <div className="callout-secondary" style={{verticalAlign: 'top'}}>
                                    {this.state.currentCommentUnsaved ? 
                                        <span>
                                            <SaveButton text={lang.Text('task.preview.savecomment')} 
                                                saving={this.state.currentCommentIsSaving}
                                                onSave={this.saveComment.bind(this)} ></SaveButton>

                                            <Button color="secondary" className="btn-pill"
                                                style={{marginTop: '8px'}}
                                                onClick={this.cancelSaveComment.bind(this)}>
                                                {lang.Text('button.cancel')}</Button>
                                        </span>
                                    : <React.Fragment></React.Fragment>
                                    }
                                </div>
                                </div>
                            </div>
                            : <React.Fragment></React.Fragment> }
                            </div>
                           
                        </TabPane>
                        <TabPane tabId="2" style={{overflowX: 'hidden', overflowY: 'scroll', height: '100%'}}>
                            <div className="d-flex flex-column bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <Analytics taskid={this.state.currentId}/>
                                </div>
                                <div className="p-2 bd-highlight"></div>
                                <div className="p-2 bd-highlight"></div>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="d-flex flex-column bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <Alert color="warning">
                                        <span className="iconic iconic-warning"></span>     
                                        <span>
                                            {'  ' + lang.Text('task.preview.nostats')}
                                        </span>
                                    </Alert>
                                </div>
                                <div className="p-2 bd-highlight"></div>
                                <div className="p-2 bd-highlight"></div>
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className="d-flex flex-column bd-highlight">
                                <div className="p-2 bd-highlight">
                                    {this.renderRevisions(lang,this.state.currentRevisions)}
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                // </Fade> 
                : <React.Fragment></React.Fragment>
                }
            </React.Fragment>
        )}
        </LanguageContext.Consumer>
        </React.Fragment>  
    }

    private renderRevisions(lang: Language,currentRevisions?: Api.TaskRevision[]){
        
        if (currentRevisions!=undefined)
        return <div>
            {currentRevisions.map(revision =>
                 <div className="callout" key={revision.id}>
                 <div className="callout-content">
                     {/* {lang.Text('revision.from')}: <b> {revision.initiatorUserName} </b><br/> */}
                     {lang.Text('revision.date')}: <b><Moment format="YYYY-MM-DD HH:mm"
                         date={revision.initiatedTimeStamp}/></b><br/>
                     {lang.Text('revision.state')}: {revision.isNew ? <Badge color="danger">{lang.Text(revision.state)}</Badge>
                     : <Badge color="primary" style={{color: 'white'}}>{lang.Text(revision.state)}</Badge>}
                     <p></p>
                     <p>
                        {lang.Text('revision.message')}:  {revision.message}
                     </p>
                     <p></p>
                     {lang.Text('revision.changed')}: <b><Moment format="YYYY-MM-DD HH:mm"
                         date={revision.changedTimeStamp}/></b><br/>
                 </div>
                 <div className="callout-secondary" 
                 >
                    {revision.isBounced ?
                    <React.Fragment></React.Fragment>
                    : 
                    <Button disabled={revision.readOnly} onClick={() => this.handleRevision(revision)} 
                        className="btn btn-outline-dark" style={{marginLeft: '5px'}}>
                        {lang.Text('revision.finish')}
                    </Button>
                    }
                 </div>
                 </div>
             )}
        </div>
    }

    private handleRevision(revision: Api.TaskRevision) {
        this.setState({showTools: true, currentRevisionID: revision.id});
    }
    
    private renderAnalytics(lang: Language) {
       
        if (this.state.currentAnalytics!=undefined)
        {
            let question = <span></span>;
            if (this.state.currentAnalytics.questionText.analysis!=undefined)
            {
                var data = this.state.currentAnalytics.questionText.analysis;
                question = <span>Nyckelord: <b>{data.keyPhrases}</b> Tonfall: {data.sentiment.score}%</span>
            }

            let correctA = <span></span>;
            if (this.state.currentAnalytics.correctAnswer.analysis!=undefined)
            {
                var data = this.state.currentAnalytics.correctAnswer.analysis;
                correctA = <span>Nyckelord: <b>{data.keyPhrases}</b> Tonfall: {data.sentiment.score}%</span>
            }

            let dist1 = <span></span>;
            if (this.state.currentAnalytics.distractor1.analysis!=undefined)
            {
                var d1 = this.state.currentAnalytics.distractor1.analysis;
                dist1 = <span>Nyckelord: <b>{d1.keyPhrases}</b> Tonfall: {d1.sentiment.score}%</span>
            }

            let dist2 = <span></span>;
            if (this.state.currentAnalytics.distractor2.analysis!=undefined)
            {
                var d1 = this.state.currentAnalytics.distractor2.analysis;
                dist2 = <span>Nyckelord: <b> {d1.keyPhrases}</b> Tonfall: {d1.sentiment.score}%</span>
            }

            let dist3 = <span></span>;
            if (this.state.currentAnalytics.distractor3.analysis!=undefined)
            {
                var d1 = this.state.currentAnalytics.distractor3.analysis;
                dist3 = <span>Nyckelord: <b>{d1.keyPhrases}</b> Tonfall: {d1.sentiment.score}%</span>
            }

            return ( <React.Fragment>
                <p>Frågestam <span>({this.state.currentAnalytics.questionText.wordCount} ord) </span> {question} </p>
                <p>Svar <span>({this.state.currentAnalytics.correctAnswer.wordCount} ord) </span> {correctA} </p>
                <p>Distraktor 1 <span>({this.state.currentAnalytics.distractor1.wordCount} ord) </span> {dist1} </p>
                <p>Distraktor 2 <span>({this.state.currentAnalytics.distractor2.wordCount} ord) </span> {dist2} </p>
                <p>Distraktor 3 <span>({this.state.currentAnalytics.distractor3.wordCount} ord) </span> {dist3} </p>
                </React.Fragment>
            )
        }
    }


    private getCommentColor(type: number): string {
        switch (type)
        {
            case 0:
                return 'secondary';
                break;
            case 1:
                return 'primary';
                break;
            case 2:
                return 'warning';
                break;
            case 3:
                return 'success';
                break;
            case 4:
                return 'info';
                break;
        }
        return 'info';
    }

    private getCommentDefaultColor(type: number): string {
        switch (type)
        {
            case 0:
                return 'whitesmoke';
                break;
            case 1:
                return '';
                break;
            case 2:
                return '';
                break;
            case 3:
                return '';
                break;
            case 4:
                return '';
                break;
        }
        return 'whitesmoke';
    }

}
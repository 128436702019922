import React, { Component, useReducer } from 'react';
import { Container, Button, Fade } from 'reactstrap';

import * as App from 'pubsub-js';
import { LanguageContext } from '../../backbone/Language';
import { User } from '../../api/User';
import { Application } from '../../api/App';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';
import { ContentItem } from '../../api/Content';
import { Loading } from '../../components/base/Loading';
import { AppCore } from '../../backbone/AppCore';

import { WorksetEdit } from './components/WorksetEdit';
import { UrlHelper } from '../../backbone/UrlHelper';

import { ReferencePanel } from './panels/ReferencePanel';
import { HistoryPanel } from './panels/HistoryPanel';

import "./construction.css";

export interface WorksetProps {

}

export interface WorksetState {
    loading: boolean,
    user: User.UserProfile;
    app:  Application.AppProfile;
    id: string;
}

export class Workset extends React.Component<WorksetProps,WorksetState> {
  static displayName = Workset.name;
  static contextType = LanguageContext;

  constructor(props:WorksetProps) {
    super(props);

    this.state= {
      loading: false,
      user: AppCore.GetUserProfile(),
      app: AppCore.GetAppProfile(),
      id: UrlHelper.GetIdFromCurrentURL()
    }
  }

  componentDidMount() {

    App.publish('track-event',{ name: 'View.Workset', properties: 
    {
        'Id': this.state.id,
    }});

  }

  render () {

    return ( <React.Fragment>
    <LanguageContext.Consumer>
    {lang => <React.Fragment>

      {this.state.loading ? 
      <Loading style={{height: '100vh'}} loading={true}/> :
      <Container fluid style={{marginTop: '20px'}}>
         <div className="yc-module rounded" style={{backgroundColor: 'white', padding: '40px',paddingTop: '0px'}}>
          <WorksetEdit id={this.state.id} />
          </div>
      </Container>

        
      }

        <ReferencePanel okButtonLabel={lang.Text('btn.save')} cancelButtonLabel={lang.Text('btn.cancel')}
        title={lang.Text('reference.title')}  
        position="modal-panel-right" >
        </ReferencePanel>

        <HistoryPanel title={lang.Text('history.title')} cancelButtonLabel={lang.Text('btn.close')}
        modalPanelOpen="history.open" position="modal-panel-right">
        </HistoryPanel>

    </React.Fragment>}
    </LanguageContext.Consumer> 
    </React.Fragment>);
  }
}

import * as React from 'react';
import * as App from 'pubsub-js';

import { Api } from '../api/WorkSet';
import { LanguageContext } from '../../../backbone/Language';
import { Modal, ModalHeader, ModalBody, Button, Carousel, CarouselItem, Row, Col, CarouselControl } from 'reactstrap';



interface TaskMediaProps {
    renderNav?: boolean;
    renderDirection?: boolean;
    renderTools?: boolean;
    task?: Api.TaskCore;
    searchCmd?: string;
    removeCmd?: string;
}

interface TaskMediaState {
    mediaIndex: number;
    focus: boolean;
    focusIndex: number;
    moving: boolean;
    imgDeltaX: number;
    imgDeltaY: number;
    currentMedia?: MediaRefDisplay;
}

interface MediaRefDisplay{
    id: string;
    url: string;
    index: number;
    height: number;
    width: number;
    provider: string;
    description: string;
    searchText: string;
}

export class TaskMedia extends React.Component<TaskMediaProps, TaskMediaState> {
    constructor(props:any) {
        super(props);
    
        this.state = {
            mediaIndex: 0,
            focus: false,
            focusIndex: 0,
            moving: false,
            imgDeltaX: 0,
            imgDeltaY: 0,
        };

        this.media_next = this.media_next.bind(this);
        this.media_previous = this.media_previous.bind(this);
        this.media_goToIndex = this.media_goToIndex.bind(this);
        this.media_onExiting = this.media_onExiting.bind(this);
        this.media_onExited = this.media_onExited.bind(this);
        this.removeMedia = this.removeMedia.bind(this);
        this.media_focus = this.media_focus.bind(this);
        this.toggle = this.toggle.bind(this);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }
    
    private animating: boolean = false;

    private media_focus() {
        console.info("media->focus");
        this.setState({focus: true, focusIndex: this.state.mediaIndex});
    }

    private media_onExiting() {
        this.animating = true;
    }
    
    private media_onExited() {
        this.animating = false;
    }

    private media_next() {
        if (this.animating) return;
        if (this.props.task!=undefined) {
            const nextIndex = this.state.mediaIndex === 
                this.props.task.mediaRefs.length - 1 ? 0 : this.state.mediaIndex + 1;
            this.setState({ mediaIndex: nextIndex });
        }
    }
    
    private media_previous() {
        if (this.animating) return;
        if (this.props.task!=undefined) {
            const nextIndex = this.state.mediaIndex === 0 ? 
                this.props.task.mediaRefs.length - 1 : this.state.mediaIndex - 1;
            this.setState({ mediaIndex: nextIndex });
        }
    }
    
    private media_goToIndex(newIndex: number) {
        if (this.animating) return;
        this.setState({ mediaIndex: newIndex });
    }

    private searchMedia(text?: string) {
        if (this.props.searchCmd!=undefined) {
            App.publish(this.props.searchCmd, text);
        }
    }

    private removeMedia(index: number) {
        if (this.props.removeCmd!=undefined) {
            
            App.publish(this.props.removeCmd, index);
            
            //this.setState({ mediaIndex: 0 });
        }
    }

    toggle() {
        this.setState(prevState => ({
          focus: !prevState.focus
        }));
      }

    private onMouseDown(e: React.SyntheticEvent) {
        if (this.state.moving==false){
            // console.info(e);
            this.setState({moving: true})
        }
        e.preventDefault();
    }

    private onMouseUp(e: React.SyntheticEvent) {
        if (this.state.moving==true){
            // console.info(e);
            this.setState({moving: false})
        }
        e.preventDefault();
    }

    private onMouseMove(e: any) {
        if (this.state.moving==true){
            
            //console.info(e);

            let mx = this.state.imgDeltaX + e.movementX;
            let my = this.state.imgDeltaY + e.movementY;
            
            console.info(mx + " " + my);

            this.setState({imgDeltaX: mx, imgDeltaY: my })
        }
        e.preventDefault();
    }

    private currentMedia? : MediaRefDisplay;

    public render() {
        
        let _items: MediaRefDisplay[] = Array<MediaRefDisplay>();
        let index: number = 0;
        if (this.props.task!=undefined) {
        this.props.task.mediaRefs.forEach(element => {            
            _items.push( { id: element.id, 
                           url: element.url, 
                           index: index,
                           height: element.height,
                           width: element.width,
                           description: element.description,
                           provider: element.provider,
                           searchText: element.searchText
                        });
            index++;
        });
        }

        this.currentMedia = _items[this.state.focusIndex];

        //console.info(this.currentMedia);
    
        return (<React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div >
            
            <Modal isOpen={this.state.focus} 
                modalClassName="modal-panel-media"
                toggle={this.toggle} centered backdrop size="xl" 
                contentClassName="modal-panel-media-xl">
                <ModalHeader toggle={this.toggle}>
                <h3>{this.props.task!=undefined ? this.props.task.question.text : ''}</h3>
                </ModalHeader> 
                <ModalBody 
                    // style={{height: '98vh', width: '98vw'}}
                >
                {this.currentMedia!=undefined ?    
                <div  
                    // onClick={this.toggle}
                    style={{cursor: 'grab',
                        backgroundImage: this.getImageUrl(this.currentMedia),
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '100%',
                        width: '100%',
                        // height: this.currentMedia.provider==="youcap.image" ? '100%' : this.currentMedia.height,
                        // width: this.currentMedia.provider==="youcap.image" ? '100%' : this.currentMedia.width,
                        backgroundPositionX: this.state.imgDeltaX,
                        backgroundPositionY: this.state.imgDeltaY,
                        backgroundPosition: 'center',
                        overflow: 'hidden'
                    }}
                    onMouseDown={this.onMouseDown} 
                    onMouseUp={this.onMouseUp} 
                    onMouseMove={this.onMouseMove}  
                 ></div>
                
                    : <div className="text-center" style={{height: '500px'}}>
                        <span className="iconic iconic-image-landscape text-center" style={{color: 'gray', fontSize: '68px'}}
                        title="image portrait" aria-hidden="true">
                        </span>
                    </div> 
                }

                {/* {_items.slice(this.state.mediaIndex,1).map(item => {
                        return ()
                        )}
                )} */}
                        
                </ModalBody>
                </Modal>
            
            {this.props.renderNav? _items.map(item => 
                <Button color="link" key={item.id} onClick={() =>this.media_goToIndex(item.index)}>
                    {lang.Text('taskedit.media') + ' ' + (item.index + 1)} 
                </Button>
            ): <React.Fragment></React.Fragment>}
            <Carousel style={{}}
                activeIndex={this.state.mediaIndex}
                next={this.media_next}
                previous={this.media_previous}
            >
              {_items.map((item) => {
                    return (
                    <CarouselItem key={item.id}
                    onExiting={this.media_onExiting}
                    onExited={this.media_onExited}>
                    <Row >
                        <Col>
                            <div style={{width: '100%',height: '100%', overflow: 'hidden'}} >
                            {item.provider=="youcap.image" ?
                                <img src={item.url + '&variation=v1'} alt={item.provider} onClick={this.media_focus} style={{cursor: 'zoom-in'}} />
                                :
                                <img src={item.url} alt={item.provider}  onClick={this.media_focus} style={{cursor: 'zoom-in'}}/>
                            }
                            </div>
                        </Col>
                        {this.props.renderTools ?
                        <Col  md="5">
                            <h4>{lang.Text('taskedit.media') + ' ' + (item.index + 1)}</h4>
                            <p><strong>{lang.Text('taskedit.mediadesc')}:</strong> {item.description} </p>
                            {/* <p>{lang.Text('taskedit.mediaprovider')}: {item.provider}</p> */}
                            <p><strong>{lang.Text('taskedit.mediasearchtext')}:</strong>     
                                <Button color="link" onClick={() =>this.searchMedia(item.searchText)}>
                                {item.searchText}
                                </Button>
                            </p>
                            <br/>
                                <Button color="danger" onClick={() => this.removeMedia(item.index)}
                                    className="btn-outline-danger"
                                    style={{color: 'white'}}>{lang.Text('taskedit.removemedia')}
                            </Button>
                        </Col> : <React.Fragment></React.Fragment>
                        }
                    </Row>
                    </CarouselItem>
                    )}
                )}
                {this.props.renderDirection && _items.length > 1 ? <React.Fragment>
                    <CarouselControl direction="prev" directionText="Previous" 
                    onClickHandler={this.media_previous} />
                    <CarouselControl direction="next" directionText="Next" 
                    onClickHandler={this.media_next} />
                    </React.Fragment> 
                    : <React.Fragment></React.Fragment>
                }

            </Carousel>
            {this.props.renderTools ?
            <Button color="link" className="yc-link-btn" style={{marginTop: '15px'}}
                onClick={() => this.searchMedia(undefined)}>
                {lang.Text('taskedit.addmedia')}
            </Button>
            :<React.Fragment></React.Fragment>
            }
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>);
      }

    getImageUrl(media: MediaRefDisplay): string | undefined {
       
        if (media.provider==="youcap.image")
        {
            return "url('" + media.url  + "&variation=original')";
        }
        else
        {
            return "url('" + media.url + "')";
        }
    }
}
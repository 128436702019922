import * as React from 'react';
import * as App from 'pubsub-js';

import { Button, Col, Container, Progress, Row } from 'reactstrap';
import { Language, LanguageContext } from '../../../backbone/Language';
import { ContentItem } from '../../../api/Content';
import { GlobalAppsettings } from '../../../backbone/GlobalSettings';

import { Api } from '../api/Analytics';

import { Loading } from '../../../components/base/Loading';

export interface AnalyticsState {
    loading: boolean
    data?: Api.TaskAnalytics
}


export interface AnalyticsProps {
    taskid?: string
}

export class Analytics extends React.Component<AnalyticsProps, AnalyticsState> {
    static displayName = 'Analytics';
    static contextType = LanguageContext;
    constructor(props:AnalyticsProps) {
        super(props);
    
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
      
        fetch('/api/workset/TaskAnalytics?id=' + this.props.taskid, GlobalAppsettings.GetRequestInit())
        .then(response => response.json() as Promise<Api.TaskAnalytics>)
        .then(data => {
            this.setState({loading: false, data: data});
        }).catch(reason => {
           this.setState({loading: false});
        });
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div >
            <Loading loading={this.state.loading}>

                {this.renderAnalytics(lang,this.state.data)}

            </Loading>
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    renderAnalytics(lang: Language, data?: Api.TaskAnalytics): React.ReactNode {
       if (data!=undefined)
       return(<Container style={{fontSize: 'small'}}>
            <Row>
                {/* {data.questionText.analysis.keyPhrases ?
                <Col md="3"> 
                    <strong>{lang.Text('analytics.keyphrases')}:</strong>
                    <ul className="list-unstyled">
                    {data.questionText.analysis.keyPhrases.map((item) => {
                        return (<li>{item}</li>)
                    })}</ul>

                    <hr/>

                    <strong>{lang.Text('analytics.keyphrases')}:</strong>
                    <ul className="list-unstyled">
                    {data.correctAnswer.analysis.keyPhrases?.map((item) => {
                        return (<li>{item}</li>)
                    })}</ul>
                </Col> : <></>} */}

                
                <Col md="6">
                    {lang.Text('analytics.correct.wordcount')}:
                    <Progress className="m-2"
                        bar
                        value={data.correctAnswer.wordCount}
                        max={30}
                        color="success"
                        >
                        {data.correctAnswer.wordCount}
                    </Progress>
                    {lang.Text('analytics.dist1.wordcount')}:
                    <Progress className="m-2"
                        bar
                        color="secondary"
                        value={data.distractor1.wordCount}
                        max={30}
                    >
                    {data.distractor1.wordCount}
                    </Progress>
                    {lang.Text('analytics.dist2.wordcount')}:
                    <Progress className="m-2"
                        bar
                        color="secondary"
                        value={data.distractor2.wordCount}
                        max={30}
                    >
                    {data.distractor2.wordCount}
                    </Progress>
                    {lang.Text('analytics.dist3.wordcount')}:
                    <Progress className="m-2"
                        bar
                        color="secondary"
                        value={data.distractor3.wordCount}
                        max={30}
                    >
                        {data.distractor3.wordCount}
                    </Progress>
                </Col>
                <Col>
                <strong>{lang.Text('analytics.similar.questions')}:</strong><br/>
                {data.similarQuestions.tasks?.map((item) => {
                        return (<>
                        <Button key={item.id} color="link" onClick={(event) => this.viewTask(item.id)}>{item.title}</Button>
                        <br/></>
                        );
                    })}
                </Col>
                
            
                
            </Row>
           
            <Row>

            </Row>
       </Container>);
    }
    viewTask(id: string){
        let preview = {
            id: id,
            versionId: undefined
        }
        App.publish("task.preview.open", preview);
    }
    getColorFromSentiment(score: number): string {
        
        return "";
    }
}

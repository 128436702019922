import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container, Row, Col, CustomInput, UncontrolledCollapse, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardTitle, CardText, Badge, FormGroup, Input, Label, Form, Progress } from 'reactstrap';
import { LanguageContext, Language } from '../../backbone/Language';
import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';

import {ProductLogo} from '../../components/ProductLogo';
import { Line } from 'react-chartjs-2';
import { InfoPanel } from '../../panels/InfoPanel';
import { ModalPanel } from '../../panels/ModalPanel';

import {CollapseList, CollapseListItem} from '../../components/CollapseList';
import { Loading } from '../../components/base/Loading';
import { UserHasModule } from '../../backbone/UserHasModule';

import { SecureTestApi } from './Api/SecureTestApi';
import { ExamItem, SessionCodeItem } from '../api/Exam';
import { GetExamsForCustomerResult, GetAllTestsResult, GenerateExamResult, GetAllCodesResult } from '../api/Responses';

const moment = require("moment");

export interface SecureTestModuleState {
    loading: boolean
    noData: boolean
    text: string
    title: string
    isDirty: boolean
    loadingTemplates: boolean
    activeTab: string
    exams: ExamItem[]
    sessionGroups:  SecureTestApi.SessionGroup[],
    selectedExam?: ExamItem;
    selectedGroup?: SecureTestApi.SessionGroup;
    sessionCodes: SessionCodeItem[];
    sessions?: SecureTestApi.SessionInfo[]
    current?: SecureTestApi.SessionInfo;
    selectedExamId: string;
    validDays: number
    count: number
}

export interface ComponentOnChangeEvent {
    message: string
}

export interface SecureTestModuleProps {
    title?: string
    text?: string
    onChange?: ((state: ComponentOnChangeEvent)=> void)
}

export class SecureTestModule extends React.Component<SecureTestModuleProps, SecureTestModuleState> {
    static displayName = 'SecureTestModule';
    static contextType = LanguageContext;
    constructor(props:SecureTestModuleProps) {
        super(props);
        
        let _title = props.title!=undefined ? props.title: '';
        let _text= props.text!=undefined ? props.text: '';

        this.state = {
            title: _title,
            noData: false,
            isDirty: false,
            loadingTemplates: false,
            text: _text,
            loading: false,
            activeTab: 'organisation',
            exams: [],
            sessionGroups: [],
            selectedExamId: '',
            sessionCodes: [],
            validDays: 3,
            count: 1,
        };

        this.addCodeLink = this.addCodeLink.bind(this);
        this.generateCode = this.generateCode.bind(this);
    
        this.renderItem = this.renderItem.bind(this);
        this.renderModule = this.renderModule.bind(this);
    }

    componentDidMount() {

        App.subscribe('add.template.type',this.addNewItem.bind(this))
        App.subscribe("securetest.view", this.viewItem.bind(this));

        App.subscribe('reload.codes',this.getCodes.bind(this));

        this.getExams();
        this.getCodes();
    }

    componentWillUnmount() {
        App.unsubscribe('add.template.type');
        App.unsubscribe('securetest.view');
    }

    getExams() {
        this.setState({
            loading: true
        });
        fetch('api/secureteststore/getAllTests', GlobalAppsettings.GetRequestInit())
            .then(response => response.json() as Promise<GetAllTestsResult>)
            .then(data => {
                if (data.success) {

                    this.setState({
                        exams: data.exams,
                        loading: false
                    });

                    if (this.state.exams.length > 0) {
                        this.setState({ selectedExam: this.state.exams[0] });
                    }

                }

                this.setState({
                    loading: false
                });
            })
            .catch(reason => {
                //Track Exception
                App.publish('track-exception', { error: reason });
                this.setState({
                    loading: false
                });
            });
    }

    private getCodes() {
        this.setState({
            loading: true
        });

        fetch('api/securetestapi/getAllCodes', GlobalAppsettings.GetRequestInit())
            .then(response => response.json() as Promise<GetAllCodesResult>)
            .then(data => {
                if (data.success) {

                    this.setState({
                        sessionCodes: data.sessionCodes,
                        loading: false,
                        noData: data.sessionCodes.length == 0
                    });

                }

                this.setState({
                    loading: false
                });
            })
            .catch(reason => {
                //Track Exception
                App.publish('track-exception', { error: reason });

                this.setState({
                    loading: false
                });
            });
    }

    private generateCode() {

            var param = {
                testid: this.state.selectedExam?.id,
                singleUse: true,
                validDays: this.state.validDays,
                count: this.state.count,
                testTime: "default",
                language: "default",
            };

            console.info(param);

            fetch('api/securetestapi/generateCode', GlobalAppsettings.GetPostInit(param))
                .then(response => response.json() as Promise<GenerateExamResult>)
                .then(data => {
                console.info(data);  
                
                    App.publish("add.code.close",{});
                    this.getCodes();
                
                })
                .catch(reason => {
                    //Track Exception
                    App.publish('track-exception', { error: reason });

            });
    }


    selectedTestChange(e: any) {
        var exam = this.state.exams.filter(exam => exam.id == e.target.value)[0];
        if (exam != undefined && exam != null)
            this.setState({ selectedExam: exam });
    }

    addCodeLink() {
        App.publish("add.code.link",{});

    }

    onSessionSave(): void {

        var group = this.state.selectedGroup;

        fetch('api/securetestapi/updateSessionGroup', GlobalAppsettings.GetPostInit(group))
        .then(response => response.json() as Promise<GenerateExamResult>)
        .then(data => {
                console.info(data);  
                //App.publish("add.session.close",{});
                this.getCodes();
        })
        .catch(reason => {
            //Track Exception
            App.publish('track-exception', { error: reason });

        });
    }

    viewResult(examId: string) {

        this.setState({sessions: undefined, selectedExamId: examId});

        App.publish("view.result",{});
        

        fetch('api/securetestapi/GetSessions?TestId=' + examId, GlobalAppsettings.GetRequestInit())
        .then(response => response.json() as Promise<SecureTestApi.SessionInfo[]>)
        .then(data => {
            
            this.setState({sessions: data});

        })
        .catch(reason => {
            //Track Exception
            App.publish('track-exception', { error: reason });

        });  

    }

    private viewItem(msg: string, data: any)
    {
        console.info(data.item);

        this.setState({isDirty: true, selectedExam: data.item});

    }

    private addNewItem(msg: string, data: any)
    {
        this.setState({isDirty: true, loadingTemplates: false});
    }

    private onItemChange() {
        this.setState({isDirty: true});
    }

    private onItemSave() {
        this.setState({isDirty: false});
    }

    private toggle(tab: string) {
        this.setState({activeTab: tab});
    }

    private copyLinktoClipboard(text: string) {
        if (!navigator.clipboard) {
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
        }, function(err) {
        });
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            
            <Row>
                <Col>
                <ProductLogo type="black" title="SecureTest"></ProductLogo>
                </Col>
                  
                <Col md="2" >
                <Button color="secondary" size="sm" outline className="btn--pill float-right"
                                onClick={() => App.publish('reload.codes',{})} style={{}}>
                    <i className="fa fa-redo"/>
                </Button>
                </Col>
            </Row>
            
            <UserHasModule moduleId="youcap.securetest.templates" upgrade>

            <Loading loading={this.state.loading}>
                    {this.renderModule(lang)}
            </Loading>

            <InfoPanel title={lang.Text('customer.statistics')} cancelButtonLabel={lang.Text('btn.close')} 
                modalPanelOpen="stats.open"
                position="modal-panel-right">
               <div className="yc-panel-md">
                <Container>
                    <Row>
                    <Col>
                    
                    
                    </Col>
                    </Row>
                </Container>
                </div>
            </InfoPanel>

            <ModalPanel title={lang.Text('securetest.view')} 
                okButtonLabel={lang.Text('btn.save')}
                cancelButtonLabel={lang.Text('btn.cancel')}
                leaveButtonLabel={lang.Text('btn.leave')}
                unsavedChangesText={lang.Text('modal.unsaved.changes')}
                isDirty = {this.state.isDirty} 
                showHeader={false}
                modalPanelOpen="securetest.view"
                onSave={() => this.onItemSave()}
                position="modal-panel-right"
                >
                <div className="yc-panel-xl">
                    {this.renderItem(lang, this.state.selectedExam)}
                </div>
            </ModalPanel>

            <ModalPanel title={lang.Text('add.code.link')} 
              okButtonLabel={lang.Text('btn.save')}
              cancelButtonLabel={lang.Text('btn.cancel')}
              leaveButtonLabel={lang.Text('btn.leave')}
                unsavedChangesText={lang.Text('modal.unsaved.changes')}
                isDirty={this.state.isDirty} 
              // showHeader={false}
              modalPanelOpen="add.code.link"
              modalPanelClose="add.code.close"
            //   onSave={() => this.generateCode()}
              position="modal-panel-right">
              <div className="yc-panel-md">
              <Container>
              <Row>
                  <Col>
                    <p>{lang.Text('choose.exam')}:</p>
                    <CustomInput id="customer.exams.link"  type="select" value={this.state.selectedExam?.id} onChange={(e) => this.selectedTestChange(e)}>
                        {
                            this.state.exams.map((exam) => {
                                return (<option value={exam.id} key={exam.id}>{exam.name}</option>);
                            })
                        }
                    </CustomInput >
                  </Col>
              </Row>

              <Row style={{marginTop: '15px'}}>
                  <Col>
                  <p>{lang.Text('choose.count')}:</p>
                  <Input
                        type="number"
                        name="number"
                        id="exampleNumber"
                        defaultValue = {this.state.count}
                        value={this.state.count}
                        onChange={(e) => this.setState({count: e.target.valueAsNumber })}
                    />
                  </Col>
              </Row>
              <Row style={{marginTop: '15px'}}>
                  <Col>
                  <p>{lang.Text('choose.validdays')}:</p>
                  <Input
                        type="number"
                        name="number"
                        id="selectvalid"
                        defaultValue = {this.state.validDays}
                        value={this.state.validDays}
                        onChange={(e) => this.setState({validDays: e.target.valueAsNumber })}
                    />
                    
                  </Col>
              </Row>

              <Row style={{marginTop: '25px'}}>
                  <Col>
                    <Button color="success" onClick={() => this.generateCode()}>{lang.Text('create.code')}</Button>
                </Col>
              </Row>
              </Container>
              </div>
          </ModalPanel>

          {/* <ModalPanel title={lang.Text('securetest.session')} 
                okButtonLabel={lang.Text('btn.save')}
                cancelButtonLabel={lang.Text('btn.cancel')}
                leaveButtonLabel={lang.Text('btn.leave')}
                unsavedChangesText={lang.Text('modal.unsaved.changes')}
                isDirty = {this.state.isDirty} 
                showHeader={false}
                modalPanelOpen="add.session"
                onSave={() => this.onSessionSave()}
                position="modal-panel-right"
                >
                <div className="yc-panel-xl">
                    {this.renderSessionGroup(lang, this.state.selectedGroup)}
                </div>
            </ModalPanel> */}

          <InfoPanel title={lang.Text('view.result')} cancelButtonLabel={lang.Text('btn.close')} 
                modalPanelOpen="view.result"
                position="modal-panel-right">
               <div className="yc-panel-md">
                <Container>
                <Row>
                    <Col>
                    <Button color="outline-dark" className="btn--pill"
                        href={"/api/securetestapi/ExportExcelFromSessions?TestId=" + this.state.selectedExamId}
                        ><i className="fa fa-file-excel"></i>{'  '} <small> {lang.Text('export.excel')}  </small> </Button>
                    </Col>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    {this.renderResults(lang)}
                </Row>
                </Container>
                </div>
            </InfoPanel>

            <InfoPanel title={lang.Text('add.template')} cancelButtonLabel={lang.Text('btn.close')} 
                modalPanelOpen="add.template.type"
                position="modal-panel-right">
                <div className="yc-panel-xl">
                <Container>
                <Row>
                    <Col>
                    {this.state.loadingTemplates ? <Loading loading={true} style={{}}/> : <React.Fragment>

                    <Nav tabs>
                        <NavItem>
                            <NavLink style={{cursor: 'pointer'}}
                                className={this.state.activeTab=='new' ? 'active' : ''}
                                onClick={() => { this.toggle("new"); }}
                            >
                               {lang.Text('template.new')}
                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{cursor: 'pointer'}}
                                className={this.state.activeTab=='organisation' ? 'active' : ''}
                                onClick={() => { this.toggle("organisation"); }}
                            >
                              {lang.Text('template.organisation')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{cursor: 'pointer'}} 
                                className={this.state.activeTab=='marketplace' ? 'active' : ''}
                                onClick={() => { this.toggle("marketplace"); }}
                            >
                                {lang.Text('template.marketplace')}<Badge color="danger" style={{fontSize: '11px'}} pill>75</Badge>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Container style={{padding: '20px'}}>

                   
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="new">
                            <Row>
                                <Col sm="12">
                                <h4>Ny provmall</h4>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="organisation">
                            <Row>
                                <Col sm="6">
                                <h4>Organisation</h4>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="marketplace">
                            <Row>
                                <Col sm="12">
                                <h4>Marketplace</h4>
                                {/* <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                </Card>
                                </Col>
                                <Col sm="6">
                                <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                </Card> */}
                                </Col>
                            </Row>
                        </TabPane>
                        </TabContent>
                    </Container>

                    </React.Fragment>}
                    </Col>
                </Row>
                </Container>
                </div>
            </InfoPanel>

        </UserHasModule>

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
   
   
   
    renderModule(lang: Language) {
        return (<React.Fragment>
        
        <Row style={{marginTop: '10px', marginBottom: '10px'}}>
            <Col md="6">
                <Button color="dark" outline className="btn--pill" disabled={this.state.exams == undefined || this.state.exams.length == 0}
                            onClick={this.addCodeLink.bind(this)}>
                    <i className="fas fa-plus" style={{marginRight: '5px'}}></i>
                    {lang.Text('add.code.link')}
                </Button>
            </Col>
            <Col md="6">
                
            </Col>
        </Row>
        
        <Row>
            <Col>
                <Loading loading={this.state.loading}>
                 <small style={{color: 'darkgray'}}>{lang.Text('securetest.codes')}:</small>
                 
                    {this.state.noData ? <p className="no-data">
                        <i className="fa fa-umbrella-beach"/>{'   '} {lang.Text('no.data')}</p>
                    :  <CollapseList id="securetest-code-list" items={this.getCodeLinks(this.state.sessionCodes, lang)} lang={lang} />} 
                </Loading>
            </Col>
        </Row>
        
        <UserHasModule moduleId="youcap.securetest.templates">
            <hr/>
            {/* <small style={{color: 'darkgray'}}>{lang.Text('securetest.templates.and.results')}:</small> */}

            <h5 style={{fontSize: '15px'}}>{lang.Text('securetest.templates')}:</h5>
            
            <CollapseList id="templatelist" items={this.getExamTemplates(this.state.exams,lang)} lang={lang}/>

            <UserHasModule moduleId="youcap.securetest.templates.add">
                <Row style={{marginTop: '10px'}}>
                    <Col>
                        <Button color="dark" outline className="btn--pill" 
                            onClick={() => App.publish("add.template.type",{tab: 'new'})}>
                            <i className="fas fa-plus" style={{marginRight: '5px'}}></i>
                            {lang.Text('add.new')}</Button>
                        </Col> 
                </Row>
            </UserHasModule>
        </UserHasModule>
        </React.Fragment>
        );
    }

    private getExamTemplates(exams: ExamItem[], lang: Language) : CollapseListItem[]  {
    
        var list: CollapseListItem[] = new Array<CollapseListItem>();
     
        exams.forEach((item) => {
             list.push(
             { title: <React.Fragment><h5 className="mb-1">{item.name}</h5><small></small>
                
                <Button color="link" onClick={() => this.viewResult(item.id)}>{lang.Text('show.results')}</Button>

                </React.Fragment>, 
               content: <React.Fragment>
               <p className="mb-1">{item.title}</p>
               <small>{item.updated}</small>
               
               <UserHasModule moduleId="youcap.securetest.templates.edit">
                    <Button color="link" className="float-left" onClick={() => App.publish('securetest.view',{item})}>{lang.Text('btn.securetest.open.template')}</Button>
               </UserHasModule>
            </React.Fragment>,  }
             );
         });
     
         return list;
    }

    private getCodeLinks(codes: SessionCodeItem[], lang: Language): CollapseListItem[] {

        var list: CollapseListItem[] = new Array<CollapseListItem>();

        codes.forEach((item) => {
            list.push(
                {
                    title: <React.Fragment>
                        <Col md="6"><strong className="float-left">{item.title}</strong> </Col>
                            <Col>
                                {item.usage > 0 ? <i style={{fontSize: '17px'}} className="fa fa-user-check text-success float-right"/> : <React.Fragment></React.Fragment>}
                            </Col>
                        </React.Fragment>,
                    content: <React.Fragment>
                        <div style={{paddingLeft: '17px'}}>
                        <p ><small>{item.code}</small></p>
                        <small>{lang.Text('valid.to')}: {moment(item.validTo).format('YYYY-MM-DD')} </small>
                        <p>
                        <Button color="link" onClick={() => this.copyLinktoClipboard('https://securetest.youcap.io/code/' + item.code)}>
                                <i style={{fontSize: '17px'}} className="fa fa-external-link-alt "/> {lang.Text("copy.link")}
                        </Button>
                        
                        
                        {/* <a href={'https://securetest.youcap.io/code/' + item.code} target="_new" >{lang.Text('securetest.url')} {' '}<i className="fa fa-external-link-alt" /></a> */}
                        </p>
                        <p>
                        <small>
                        </small>
                        </p>
                        </div>
                        
                    </React.Fragment>,
                }
            );
        });

        return list;
    }

    renderResults(lang: Language) {
       
        if (this.state.sessions==undefined || this.state.sessions.length ==0)
        {
            return (<Loading loading={true} style={{height: '200px'}}></Loading>)
        }
        else
        {
         return ( <React.Fragment>
             <Col md="12">
                 <CollapseList id="securetest-session-list" items={this.getSessions(this.state.sessions, lang)} lang={lang}/>
             </Col>
         </React.Fragment>
         );
         }
     }
 
     private getSessions(sessions: SecureTestApi.SessionInfo[], lang: Language): CollapseListItem[] {
         var list: CollapseListItem[] = new Array<CollapseListItem>();
         sessions.forEach((item) => {
             list.push(
                 {
                     title: <React.Fragment>
                         <Col md="6"><strong className="float-left">{item.name}</strong> </Col>
                             <Col>
                                 {item.result?.passed ? 
                                     <i style={{fontSize: '17px'}} className="fa fa-check-circle text-success float-right"/> 
                                     : <i style={{fontSize: '17px'}} className="fa fa-times-circle text-danger float-right"/> }
                             </Col>
                         </React.Fragment>,
                     content: <React.Fragment>
                          <Col>
                          <p><small>{moment(item.timeStamp).format("YYYY-MM-DD HH:mm")}</small></p>
                          <p><small>{item.email}</small></p>
                          <p><small>{item.title}</small></p>
                          <p><strong>Score: {item.result?.score}</strong></p>
                          <hr/>
                           {item.result?.areas.map((area,i) => {
                               return <React.Fragment key={i}>
                                <p><small> {area.title} - {area.correctCount} / {area.totalCount} </small></p>
                                <Progress multi>
                                 <Progress bar color="success" value={area.correctCount} max={area.totalCount}>{area.correctCount}</Progress>
                                 <Progress bar color="danger" value={area.totalCount-area.correctCount} max={area.totalCount}>{area.totalCount-area.correctCount}</Progress>
                                 </Progress>
                               </React.Fragment>
                           })}
                          </Col>
                     </React.Fragment>
                 });
         });
 
         return list;
     }

    renderSessionGroup(lang: Language, group?: SecureTestApi.SessionGroup) {
        return (
        <Container style={{width: '100%'}}>
        
        </Container>
        );
    }

  

    renderItem(lang: Language, exam?: ExamItem) {
        return (
        <Container style={{width: '100%'}}>
           <Row>
               <Col md="12">

                <h2>{exam?.title}</h2>
                <p> {exam?.description}
               </p>
               </Col>
           </Row>
           <Row>
               
           </Row>

           <Row>
                {/* <Col md="4">
                <FormGroup>
                    <Label for="exampleDate">Valid From Date</Label>
                    <Input
                    type="date"
                    name="date"
                    id="exampleDate"
                    placeholder="date placeholder"
                    value="2021-03-01"
                    />
                </FormGroup>
                
                </Col>

                <Col md="4">
                <FormGroup>
                    <Label for="exampleDate">Expires at Date</Label>
                    <Input
                    type="date"
                    name="date"
                    id="exampleDate"
                    placeholder="date placeholder"
                    value="2021-03-28"
                    />
                </FormGroup>
                
                </Col> */}
                <Col md="3">
                <FormGroup>
                            <Label for="exampleTime">Provtid</Label>
                            <Input
                            type="time"
                            name="time"
                            id="exampleTime"
                            placeholder="time placeholder"
                            value="00:60"
                            />
                        </FormGroup>
                </Col>

           </Row>
           
           <Row>
            
        <CollapseList style={{width: '90%'}} id="templatelist1" lang={lang} items={[
            { title: <React.Fragment><h5 className="mb-1">Provmall</h5><small></small></React.Fragment>, 
            isOpen: true, content: <React.Fragment>
                <p className="mb-1">Urval av frågor:</p>
                <Form>
                <FormGroup>
                <   Input type="textarea" name="text" id="exampleText" placeholder="#tag #tag2 #tag3"/>       
                    {/* <Input type="select" name="select" id="exampleSelect">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    </Input> */}
                </FormGroup>
                <FormGroup>
                    <CustomInput type="select" id="exampleCustomSelect" name="customSelect">
                    <option value="">Workset</option>
                    <option>Workset1</option>
                    </CustomInput>
                </FormGroup>    
                    <FormGroup>
                        <Label for="exampleCustomRange">Viktning</Label>
                        <CustomInput type="range" id="exampleCustomRange" name="customRange" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleNumber">Antal frågor</Label>
                        <Input
                        type="number"
                        name="number"
                        id="exampleNumber"
                        placeholder="15"
                        />
                    </FormGroup>
                </Form>

                <small></small>
            </React.Fragment>  
        },
            { title: <React.Fragment><h5 className="mb-1">{lang.Text('securetest.identification')}</h5><small><CustomInput type="switch" id="sw1" name="customSwitch" label={lang.Text('sw.active')} checked /></small></React.Fragment>, 
                isOpen: false, content: <React.Fragment>
                    <p className="mb-1">Level of Assurance (LOA)</p>
                    <Form>
                    <FormGroup>
                        <Input type="select" name="select" id="exampleSelect">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        </Input>
                    </FormGroup>
                        <CustomInput id="InputType-checkbox10" type="checkbox" checked >Name</CustomInput>
                        <CustomInput id="InputType-checkbox11" type="checkbox" checked >One-Time-Code</CustomInput>
                        <CustomInput id="InputType-checkbox12" type="checkbox" >2FA</CustomInput>
                        <CustomInput id="InputType-checkbox13" type="checkbox" >BankID</CustomInput>
                        <CustomInput id="InputType-checkbox14" type="checkbox" disabled >Proctoring</CustomInput>
                    </Form>

                    <small>Contact support for more info</small>
                </React.Fragment>  
            },
            { title: <React.Fragment><h5 className="mb-1">{lang.Text('securetest.payment')}</h5><small><CustomInput type="switch" id="sw1" name="customSwitch" label={lang.Text('sw.inactive')} /></small></React.Fragment>, 
                isOpen: false, content: <React.Fragment>
                    <p className="mb-1"></p>
                    <small>No active Payment providers found. Contact support for more info</small>
                </React.Fragment>  
            },
            { title: <React.Fragment><h5 className="mb-1">{lang.Text('securetest.settings')}</h5></React.Fragment>, 
                isOpen: false, content: <React.Fragment>
                    <p className="mb-1"></p>
                   <Form >
                        <CustomInput id="InputType-checkbox1" type="checkbox" checked >SecureTest Web Platform</CustomInput>
                        <CustomInput id="InputType-checkbox2" type="checkbox"  >Mobile App</CustomInput>
                        <CustomInput id="InputType-checkbox3" type="checkbox" checked >Offline capability</CustomInput>
                        <CustomInput id="InputType-checkbox4" type="checkbox" checked >Test Center</CustomInput>
                        <CustomInput id="InputType-checkbox5" type="checkbox" disabled >Proctoring</CustomInput>

                        
                    </Form>
                    <small></small>
                </React.Fragment>  
            },
            { title: <React.Fragment><h5 className="mb-1">{lang.Text('securetest.result')}</h5></React.Fragment>, 
                isOpen: false, content: <React.Fragment>
                    <p className="mb-1"></p>
                    <Form>
                        <CustomInput id="InputType-checkbox6" type="checkbox" checked >Automatic Scoring</CustomInput>
                        <CustomInput id="InputType-checkbox7" type="checkbox" disabled >Automatic Marking of Text Questions</CustomInput>
                        <CustomInput id="InputType-checkbox8" type="checkbox"  >Supervision Mode</CustomInput>
                        <CustomInput id="InputType-checkbox9" type="checkbox" disabled >Proctoring Recording</CustomInput>

                    </Form>

                    <small>Updated 2021-03-01</small>
                </React.Fragment>  
            },
            { title: <React.Fragment><h5 className="mb-1">Rapporter</h5>
                <small><CustomInput type="switch" id="sw1" name="customSwitch" label={lang.Text('sw.inactive')} /></small></React.Fragment>, 
                isOpen: false, content: <React.Fragment>
                    <p className="mb-1"></p>
                    <Form>
                        <CustomInput id="InputType-checkbox21" type="checkbox" checked >SecureTest Statistics</CustomInput>
                        <CustomInput id="InputType-checkbox22" type="checkbox"  >PowerBI</CustomInput>
                        <CustomInput id="InputType-checkbox23" type="checkbox"  disabled >Expert Mode</CustomInput>
                    
                    </Form>
                    <small>Updated 2021-03-01</small>
                </React.Fragment>  
            },
            ]}/>
        </Row></Container>
        )
    }
}

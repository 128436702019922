import React, { Component } from 'react';
import { LanguageContext, Language } from '../backbone/Language';
import { Container, Button } from 'reactstrap';

import * as App from 'pubsub-js';
import { GlobalAppsettings } from '../backbone/GlobalSettings';

export interface ErrorProps {

}

export interface ErrorState {
    currentCount: number;
}

export class Error extends React.Component<ErrorProps,ErrorState> {
  static displayName = Error.name;
  static contextType = LanguageContext;

  constructor(props:ErrorProps) {
    super(props);
  }

  componentDidMount() {
    App.publish('track-event',{ name: 'View.ErrorPage', properties: 
    {
        'Propery': 'Some Value',
    }});

    App.publish('alert', {alertmessage: 'Something went wrong', alerttype: 'danger'});
  }

  render () {
    // var lang = this.context;
    return ( <React.Fragment>
    <LanguageContext.Consumer>
      {lang => <div>
        <Container>
        <div className="yc-text-md">{lang.Text('start.error.title')}</div>
        <h1>{lang.Text('start.error')}</h1>
        <p>
        Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car seemed to float in the middle of an immense dark sphere, whose upper half was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light streaming through a rift in the clouds.
        </p>
        <p>
          
        </p>
        </Container>
      </div>}
    </LanguageContext.Consumer> 
    </React.Fragment>
    );
  }
}

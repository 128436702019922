import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Form, Alert, UncontrolledDropdown, Row, Col } from 'reactstrap';
import { LanguageContext, Language } from '../backbone/Language';
import { ContentItem } from '../api/Content';
import { GlobalAppsettings } from '../backbone/GlobalSettings';
import { User } from '../api/User';
import { AppCore } from '../backbone/AppCore';
import { InfoPanel } from '../panels/InfoPanel';
import { Application } from '../api/App';




export interface ProfileState {
    loading: boolean
    user: User.UserProfile;
    app:  Application.AppProfile;
}

export interface ComponentOnChangeEvent {
    message: string
}

export interface ProfileProps {
    onChange?: ((state: ComponentOnChangeEvent)=> void);
    light: boolean;
}

export class Profile extends React.Component<ProfileProps, ProfileState> {
    static displayName = 'Profile';
    static contextType = LanguageContext;
    constructor(props:ProfileProps) {
        super(props);
 

        this.state = {
            loading: false,
            user: AppCore.GetUserProfile(),
            app: AppCore.GetAppProfile()
        };

    }

    componentDidMount() {
        
    }

    login() {
        window.open("/session/login","_self");
    }

    register() {
        App.publish('intercom.open', {} );
    }

    logout() {
        window.open("/session/logout","_self");
    }

    private renderChanges(changes: Application.Change[]) {
        return changes.map(change => <React.Fragment>
            <h4> {change.version}</h4>
            <p> {change.description}</p>
            <hr/>
            </React.Fragment>
        );
    }

    render() {

        let user= AppCore.GetUserProfile();

        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            {/* {lang.Text('id.text')} */}

            {user.isloggedIn ?
                 <div 
                //  className="d-none d-sm-block float-right" 
                 style={{}}>
                 <Button color="none" className="navbar-margin btn--pill"  
                    accessKey="l" name={lang.Text("profile.signout")} title={lang.Text("profile.signout")}
                    style={{backgroundColor: 'whitesmoke', color: 'black', borderColor: 'lightgray'}}
                     onClick={this.logout}>
                     <span 
                    //  className="d-md-none d-xl-block"
                     >         
                            <i className="fa-duotone fa-right-from-bracket fa-xl" style={{ marginRight: '8px', color: 'darkgray'}}></i>
                            
                            {lang.Text("profile.signout")}
                        </span>
                      {/* <span 
                       className="d-block d-xl-none"
                      >
                       
                      </span> */}
                 </Button> 
                </div>
                 :
                 <div 
                //  className="d-none d-sm-block float-right" 
                 style={{}}>
                    <Button color="none" className="navbar-margin btn--pill" 
                        accessKey="l" name={lang.Text("profile.signin")} title={lang.Text("profile.signin")}
                        style={{backgroundColor: 'whitesmoke', color: 'black', borderColor: 'lightgray'}}
                        onClick={() => App.publish('user.login',{})}>
                        <span 
                        // className="d-none d-xl-block"
                        >
                             <i className="fa-duotone fa-right-to-bracket fa-xl" style={{ marginRight: '8px', color: 'darkgray'}}></i>
                            
                            {lang.Text("profile.signin")}
                        </span>
                        {/* <span 
                        className="d-block d-xl-none"
                        >
                            
                        </span> */}
                    </Button> 
                </div>
            }

            

            <InfoPanel title={lang.Text('about.title')} cancelButtonLabel={lang.Text('btn.close')} 
                modalPanelOpen="appinfo.open"
                position="modal-panel-right">
                <div className="yc-panel-md">
                    <Alert color="primary">
                        <span className="iconic iconic-info"></span>     
                        <span>
                        {'  '} {lang.Text('about.info')}
                        </span>
                    </Alert>
                    <span className="yc-text-md">{this.state.app.orgid}</span><br/>
                    <h3>{this.state.app.name} {'  '} {this.state.app.version}</h3>
                    <hr/>
                    {this.state.app.changes!=undefined ? this.renderChanges(this.state.app.changes) : <div className="d-none"></div>}
                </div>
            </InfoPanel>
        
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    renderProfile(lang: Language, Profile: User.UserProfileInfo): React.ReactNode {
        return <React.Fragment>
                 <UncontrolledDropdown style={{cursor: 'pointer'}}>
                    <DropdownToggle tag="a" className="nav-link" caret>
                      <span style={{marginRight: '8px', color: 'white'}}>
                      <i className="fa-thin fa-user-circle fa-xl" style={{ marginRight: '8px', color: 'darkgray'}}></i>
                            
                      
                            
                      </span>
                      <span style={{position: 'relative', top: '-4px'}}>{Profile.name}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                    <button className="dropdown-item" onClick={this.openProfilePanel}>{lang.Text('profile.show')}</button>
                    <button className="dropdown-item" onClick={this.openAboutPanel}>{lang.Text('app.about')}</button>
                    <button className="dropdown-item" onClick={this.openSupportPanel}>{lang.Text('app.support')}</button>
                    <div className="dropdown-divider"></div>
                    <button className="dropdown-item" onClick={this.logout}>{lang.Text('profile.logout')}</button>
                    </DropdownMenu>
                  </UncontrolledDropdown>
        </React.Fragment>
    }

    private openSupportPanel() {
        //App.publish("support.open",{});
        App.publish("intercom.open",{});
    }

    private openProfilePanel() {
        App.publish("profile.open",{});
    }

    private openAboutPanel() {
        App.publish("appinfo.open",{});
    }
}

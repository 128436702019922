import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Dropdown, DropdownToggle, Input, DropdownMenu, Container, Row, Col } from 'reactstrap';
import { LanguageContext, Language } from '../../../backbone/Language';
import { ContentItem } from '../../../api/Content';
import { GlobalAppsettings } from '../../../backbone/GlobalSettings';
import { Loading } from '../../../components/base/Loading';

import { InfoPanel } from '../../../panels/InfoPanel';

import { SearchApi } from '../api/Search';

import { DraftEditor } from '../../../components/drafteditor/DraftEditor';

export interface SearchTaskState {
    loading: boolean
    searchRes?: SearchApi.SearchRes,
}

export interface SearchTaskProps {
    searchText?: string
}

export class SearchTasks extends React.Component<SearchTaskProps, SearchTaskState> {
    static displayName = 'SearchTasks';
    static contextType = LanguageContext;
    constructor(props:SearchTaskProps) {
        super(props);
        
        this.state = {
            loading: true,
        };

        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    private load() {
        if (this.props.searchText!=undefined)
        fetch('api/workset/SearchTasks?text=' + encodeURIComponent(this.props.searchText),
        {credentials: "same-origin"})
        .then(response => response.json() as Promise<SearchApi.SearchRes>)
        .then(data => {     
            this.setState({ loading: false, searchRes : data });
        }).catch(reason => {
            this.setState({ loading: false, searchRes : undefined });
        });
    }


    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            <Loading loading={this.state.loading}>
                {this.renderAllSearchResult(lang)}
            </Loading>        
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private getStateColor(state: number): any {
        let ret = '#FFFFFF';
        switch (state) {
            case 0:
               ret = '#6c757d';
                break;
            case 1:
                ret = '#5bc0de';
                break;
            case 2:
                ret = '#f0ad4e';
                break;
            case 3:
                ret = '#2595FF';
                break;
            case 4:
                ret = '#d9534f';
                break;
        }
        return ret;
    }

    private openPreview(event: any, taskid: string) {
        let preview = {
            id: taskid,
            versionId: undefined
        }
        App.publish("task.preview.open", preview);
    }

    private num: number = 0;
    //Function to generate unique id:s for components
    private getNextId(id: string): string {
        this.num ++;
        return id + this.num;
    }

    private renderAllSearchResult(lang: Language) {
        if (this.state.searchRes!=undefined)
        return (<div>
            {this.state.searchRes.count > 0 ? 
            <h6>
                {lang.Text('search.others')} ({this.state.searchRes.count} st)
            </h6> 
            :
            <p className="no-data mt-3 mb-3">
                <i className="fa fa-umbrella-beach"/>{'   '} {lang.Text('no.data')}
            </p>}
            <Row>
                <Col>
                {this.state.searchRes.tasks.map(item =>
                    <div key={item.id} style={{borderLeft: '3px solid', 
                        borderLeftColor: this.getStateColor(item.state),
                        paddingLeft: '5px'}}>
                        <Button style={{fontSize: '17px',marginLeft: '-10px',textAlign: 'left'}} 
                            onClick={(event: any) => this.openPreview(event,item.id)}
                            color="link">{item.title}</Button>
                        <DraftEditor
                            textOnly={false} readOnly={true}
                            id={this.getNextId('desc')} dataId={this.getNextId('desc')} 
                            text={item.description}
                        ></DraftEditor>
                        <hr/>
                    </div>
                )}
                </Col>
            </Row>
        </div>)
        else return (
            <p className="no-data mt-3 mb-3">
                <i className="fa fa-umbrella-beach"/>{'   '} {lang.Text('no.data')}
            </p>
        )
    }
}

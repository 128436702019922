import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container, Row, Col, CustomInput, UncontrolledCollapse, Form, FormGroup, Label, Input } from 'reactstrap';
import { LanguageContext, Language } from '../../backbone/Language';
import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';

import {ProductLogo} from '../../components/ProductLogo';
import { Line } from 'react-chartjs-2';
import { InfoPanel } from '../../panels/InfoPanel';
import { ModalPanel } from '../../panels/ModalPanel';
import { CollapseList, CollapseListItem } from '../../components/CollapseList';
import { User } from '../../api/User';
import { Application } from '../../api/App';
import { AppCore } from '../../backbone/AppCore';
import { Loading } from '../../components/base/Loading';

import moment from "moment";
import { SessionApi } from './api/SessionApi';
import { SecureTestApi } from '../SecureTest/Api/SecureTestApi';
import { ContentBase } from '../../components/base/ContentBase';

export interface SessionPersonsModuleState {
    loading: boolean
    noData: boolean
    text: string
    title: string
    isDirty: boolean
    user: User.UserProfile
    app:  Application.AppProfile
    instructors: SessionApi.Instructor[]
    current?: SessionApi.Instructor
}

export interface ComponentOnChangeEvent {
    message: string
}

export interface SessionPersonsModuleProps {
    title?: string
    text?: string
    onChange?: ((state: ComponentOnChangeEvent)=> void)
}

export class SessionPersonsModule extends React.Component<SessionPersonsModuleProps, SessionPersonsModuleState> {
    static displayName = 'SessionPersonsModule';
    static contextType = LanguageContext;
    static moduleId = "youcap.io.SessionPersonsModule";
    constructor(props:SessionPersonsModuleProps) {
        super(props);
        
        let _title = props.title!=undefined ? props.title: '';
        let _text= props.text!=undefined ? props.text: '';

        this.state = {
            title: _title,
            isDirty: false,
            noData: false,
            text: _text,
            loading: false,
            user: AppCore.GetUserProfile(),
            app: AppCore.GetAppProfile(),
            instructors: []
        };

        this.renderModule = this.renderModule.bind(this);
        this.loadInstructors = this.loadInstructors.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onItemSave = this.onItemSave.bind(this);
    }

    componentDidMount() {
        App.subscribe("add.instructor.item", this.viewItem.bind(this));
        this.loadInstructors();
    }

    private loadInstructors() {
      this.setState({loading: true});
      
      fetch('/api/securetestapi/GetInstructors', GlobalAppsettings.GetRequestInit())
      .then(response => response.json() as Promise<SessionApi.Instructors>)
      .then(data => {
        
        if (data.success)
         {
          this.setState({loading: false, instructors: data.data}); 
         }
         else
         {
           console.error(data.errorMessage);
         }
  
      }).catch(reason => {
          this.setState({loading: false});  
          console.error(reason);
          //Track Exception
          App.publish('track-exception', {error: reason});
      });
    }

    componentWillUnmount() {
      App.unsubscribe("add.instructor.item");
    }

    private viewItem(msg: string, data: any)
    {
        this.setState({isDirty: true});
    }

    private onItemSave() {
      
      let save = this.state.current;
      
      if (save!==undefined)
      {
        this.setState({loading: true});
        const postData = GlobalAppsettings.GetPostInit(save);
  
        fetch('/api/securetestapi/SaveInstructor', postData)
        .then(response => response.json() as Promise<SessionApi.SaveSession>)
        .then(data => {
            if (data.success)
            {
              this.setState({loading: false, isDirty: false, current: undefined});
              App.publish('reload.instructors', null);
              this.loadInstructors(); //reload
            }
            else
            {
              console.error(data.errorMessage);
            }
  
        }).catch(reason => {
            this.setState({loading: false});
        });
      }

  }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
          {this.state.user.isAdmin &&
          <Row style={{marginBottom: '25px'}}>
          <Col>
            <div className="yc-module rounded "  style={{color: 'black', padding: '20px'}}>
            <Row>
              <Col>
                <ProductLogo type="black" title="Instruktörer"></ProductLogo>
              </Col>
            </Row>
            <Row>
              <Col >
                <Loading loading={this.state.loading}>
                  {this.state.noData ? <p className="no-data text-center">
                                <i className="fa fa-umbrella-beach"/>{'   '} {lang.Text('no.data')}</p>
                  : this.renderModule(lang)}
                </Loading> 
              </Col>
            </Row>
            </div>
          </Col>
          </Row>}
                    
          <InfoPanel title={lang.Text('workset settings')} cancelButtonLabel={lang.Text('btn.close')} 
              modalPanelOpen="session.settings"
              position="modal-panel-right"
              showHeader={false}>
            <div className="yc-panel-md">
              <Container>
                  <Row>
                  <Col>
                  
                  </Col>
                  </Row>
              </Container>
              </div>
          </InfoPanel>

          <ModalPanel title="Instruktör" 
              okButtonLabel={lang.Text('btn.save')}
              cancelButtonLabel={lang.Text('btn.cancel')}
              leaveButtonLabel={lang.Text('btn.leave')}
              unsavedChangesText={lang.Text('modal.unsaved.changes')}
              isDirty = {this.state.isDirty} 
              // showHeader={false}
              modalPanelOpen="view.instructor"
              onSave={() => this.onItemSave()}
              position="modal-panel-right">
              <div className="yc-panel-md">
              <Container>
                {this.view(this.state.current)}
              </Container>
              </div>
          </ModalPanel>

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }
    

    renderModule(lang: Language) {
    
      return (<React.Fragment>
      
      <Row>
          <Col>
          <CollapseList id="instructorList" items={this.getInstructors(this.state.instructors, lang)} lang={lang}/>
          </Col>
      </Row>

      <Row style={{marginTop: '10px'}}>
          <Col>
          <Button color="dark" outline className="btn--pill" 
              onClick={() => {
                this.setState({current: this.newInstructor(),isDirty: false})
                App.publish("view.instructor", null);
              }}>
                <i className="fas fa-plus" style={{marginRight: '5px'}}></i>
                {lang.Text('add.person')}</Button>
          </Col>
          
        </Row>
        </React.Fragment>
        );
    } 

    private newInstructor() : SessionApi.Instructor {      
      return  {
        name: "",
        email: "",
        info: "",
        status:""
      }
    }

    private view(item?: SessionApi.Instructor) {
      if (item!==undefined)
      return (
        <Form>
              <Row>
                  <Col>
                      <FormGroup>
                        <Label for="instructor.name">
                          Namn
                        </Label>
                        <Input
                          id="instructor.name"
                          name="instructor.name"
                          placeholder=""
                          type="text"
                          onChange={(event: any) => this.onChange('name', event) }
                          value={item.name}
                        />
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <FormGroup>
                        <Label for="instructor.email">
                          Epost
                        </Label>
                        <Input
                          id="instructor.email"
                          name="instructor.email"
                          placeholder=""
                          type="text"
                          onChange={(event: any) => this.onChange('email', event) }
                          value={item.email}
                        />
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                <Col>
                <ContentBase contentID='7iVNY7bvSzolx0oFXiWdAX' contentType='contentful'/>
                </Col>
              </Row>
              </Form>
      )
    }

    private onChange(field: string, event: any): void {
      if (this.state.current!==undefined)
      {
          var update = this.state.current;
          switch(field)
          {
            case 'name':
                update.name = event.target.value;
                break;
            case 'email':
                update.email = event.target.value;
                break;
          }
          this.setState({current: update, isDirty: true});
      } 
    }

    private getInstructors(links: SessionApi.Instructor[], lang: Language) : CollapseListItem[]  {
    
      var list: CollapseListItem[] = new Array<CollapseListItem>();
   
       links.forEach((item) => {
           list.push(
           { title: <React.Fragment> 
               <strong>{item.name}</strong>
               </React.Fragment>, 
             content: <React.Fragment>
                   {/* <p className="mb-1" >{item.status}</p> */}
                   <p className="mb-1" >{item.email}</p>
                   <small style={{color: 'lightgray'}} className="mb-1" >{item.id}</small>
                   {/* <p className="mb-1">{item.type}</p> */}
                   {/* <small>{moment().format('YYYY-MM-DD HH:mm')}</small> */}
                   <Button color="link" className="float-right" onClick={() => {
                     this.setState({current: item, isDirty: false});
                     App.publish("view.instructor", null);
                   }} >
                      Ändra
                   </Button>
               </React.Fragment>,  }
           );
       });
   
       return list;
     }

}

import React, { Component, useReducer } from 'react';
import { Container, Button, Fade, Row, Col, Alert, Badge, ListGroup, ListGroupItem, CustomInput, UncontrolledAlert } from 'reactstrap';

import * as App from 'pubsub-js';
import { LanguageContext, Language } from '../backbone/Language';
import { User } from '../api/User';
import { Application } from '../api/App';
import { GlobalAppsettings } from '../backbone/GlobalSettings';
import { ContentItem } from '../api/Content';
import { Loading } from '../components/base/Loading';
import { AppCore, userContext } from '../backbone/AppCore';
import { InfoPanel } from '../panels/InfoPanel';

import { PortalHomeInfo } from '../api/Portal';
import { ZoomMeetingsPage } from '../modules/Zoom/ZoomMeetings';
import { EduAdminModule } from '../modules/EduAdmin/EduAdminModule';

import { UserHasModule } from '../backbone/UserHasModule';

import { default as LogoBlackBlue} from '../images/Logo_BlackBlue';
import { default as LogoWhiteBlue} from '../images/Logo_WhiteBlue';
import { default as LogoColor} from '../images/Logo_Color';

import {ProductLogo} from '../components/ProductLogo';

import shutterstock_logo from "../theme/img/Shutterstock_logo.png";


import {SecureTestModule} from '../modules/SecureTest/SecureTestModule';
import {LinkGenerator} from '../modules/SecureTest/LinkGenerator';

import {ConstructionModule} from '../modules/Construction/ConstructionModule';

import {TeamsModule} from '../modules/Teams/TeamsModule';
import { CollapseList, CollapseListItem } from '../components/CollapseList';

import {SessionModule} from '../modules/Sessions/SessionModule';
import {SessionPersonsModule} from '../modules/Sessions/SessionPersonsModule';

export interface DashboardProps {

}



export interface DashboardState {
    loading: boolean,
    user: User.UserProfile;
    app:  Application.AppProfile;
    customer?: User.CustomerInfo;
    organisations: User.OrganisationInfo[];
    users: User.UserProfileInfo[];
    alertIsVisible: boolean;
}

export class Dashboard extends React.Component<DashboardProps,DashboardState> {
  static displayName = Dashboard.name;
  static contextType = LanguageContext;

 

  constructor(props:DashboardProps) {
    super(props);

    this.state= {
      loading: false,
      user: AppCore.GetUserProfile(),
      app: AppCore.GetAppProfile(),
      organisations: [],
      users: [],
      alertIsVisible: false,
    }


  }

  componentDidMount() {
      

      fetch('api/user/currentcustomer', GlobalAppsettings.GetRequestInit())
      .then(response => response.json() as Promise<User.CustomerInfo>)
      .then(data => {
          this.setState({loading: false, customer: data});

          if (data.notifications?.length > 0)
          {
            this.setState({loading: false, customer: data, alertIsVisible: true});
          }

      }).catch(reason => {
          //Track Exception
          App.publish('track-exception', {error: reason});
          this.setState({loading: false});
      });

      fetch('api/user/userorganisations', GlobalAppsettings.GetRequestInit())
      .then(response => response.json() as Promise<User.OrganisationInfo[]>)
      .then(data => {
          
        
          this.setState({organisations: data});

          if (data!==undefined && data.length > 1)
          {
            App.publish('customer.organisations.open',null);
          }
          

      }).catch(reason => {
          //Track Exception
          App.publish('track-exception', {error: reason});

          

          this.setState({loading: false});
      });
  }

  render () {

    App.publish('navigation.update',{});

    

    return ( <React.Fragment>
    <LanguageContext.Consumer>
    {lang => <React.Fragment>
      {this.state.loading ? 
      <Loading style={{height: '400px'}} loading={true}/> :
      <React.Fragment>
        
        {this.renderDashboard(lang)}
      </React.Fragment>  
      }
    </React.Fragment>}
    </LanguageContext.Consumer> 
    </React.Fragment>);
  }

  renderDashboard(lang: Language): React.ReactNode {
      
    return (<React.Fragment>
      {this.state.loading ? <Loading style={{height: '400px'}} loading={true}/> :
      <Fade>
       <section className="" 
          // style={{backgroundImage:  'url(' + back1 +')', minHeight: '1200px', backgroundPosition: '50% 0px'}}
          //style={{backgroundPosition: '50% 0px'}}
          >
        <Container fluid style={{paddingTop: '5px',paddingBottom: '40px'}}>
          {/* {lang.Text('start.message')} */} 
            
          <Row>
            <Col>
            <div className="yc-module rounded"  
              style={{
                  // minHeight: '150px',
                  marginTop: '10px', marginBottom: '10px', padding: '20px'}}>
                <Row>
                  <Col md="6"><h4 >{this.state.customer?.name}</h4></Col>
                  
                  {/* <Col md="2">
                    <Button color="link"  onClick={() => App.publish('customer.users.open',{})}>{lang.Text('manage.users')}</Button>
                  </Col>
                  <Col md="2">
                    <Button color="link"  onClick={() => App.publish('customer.subscription.open',{})}>{lang.Text('manage.subscription')}</Button>
                  </Col> */}
                {/* <Col>
                    <Button color="success"  className="btn--pill float-right"
                          onClick={() => App.publish("intercom.open",{})} >
                      <i className="fa fa-headset"></i>   {lang.Text('support')}
                      </Button>
                  </Col> */}
                  </Row>
                  <Row>
                  <Col >
                    <UserHasModule moduleId="youcap.marketplace">
                      <Button color="primary" outline 
                        className="btn--pill" onClick={() => App.publish('add.template.type',{tab: 'marketplace'})}>
                        <i className="fa fa-shopping-cart" 
                        // style={{fontSize: '24px'}}
                        ></i> {lang.Text('add.from.marketplace')}
                        </Button>    
                    </UserHasModule>
                    <Button color="info" outline className="btn--pill m-2" 
                      onClick={() => App.publish('customer.organisations.open',{})}>{lang.Text('customer.settings')}
                    </Button>
                    <UserHasModule moduleId="youcap.admin.users">
                      <Button color="secondary" outline className="btn--pill m-2" 
                        onClick={() => App.publish('customer.users.open',{})}>{lang.Text('customer.users')}
                      </Button>
                    </UserHasModule>
                      
                  </Col>
                  
                </Row>
                {this.state.customer?.notifications.map((alert,i) => {
                  if (alert.isActive)
                  return (
                  <Row key={i} className="pt-2">
                    <UncontrolledAlert  color={alert.notificationType}>
                    <h4 className="alert-heading">{alert.subject}</h4>
                    <p>
                      {alert.message}
                    </p>
                    <hr />
                    <p className="mb-0">
                    {alert.error}
                    </p>
                    </UncontrolledAlert>
                  </Row>)
                })}
                {this.state.user?.notifications.map((alert,i) => {
                  if (alert.isActive)
                  return (
                  <Row key={i} className="pt-2">
                    <Col>
                    <UncontrolledAlert  color={alert.notificationType} >
                    <h4 className="alert-heading">{alert.subject}</h4>
                    <p>
                      {alert.message}
                    </p>
                    <hr />
                    <p className="mb-0">
                    {alert.error}
                    </p>
                    </UncontrolledAlert>
                    </Col>
                  </Row>)
                })}         
            </div>
            </Col>
          </Row>
          <Row style={{marginTop: '20px'}}>
            <Col md="6" xl="5">

            <UserHasModule moduleId="youcap.sessions">
                <SessionModule key="1"/>
            </UserHasModule> 
              
            <UserHasModule moduleId="youcap.securetest">
              <Row style={{marginBottom: '25px'}}>
                <Col>
                <div className="yc-module rounded "  style={{color: 'black', padding: '20px'}}>
                  <SecureTestModule />
                </div>
              </Col>
              </Row>
              </UserHasModule>

              {/* <UserHasModule moduleId="youcap.construction">
              <Row style={{marginBottom: '25px'}}>
                  <Col>
                  <div className="yc-module rounded" 
                      style={{color: 'black', padding: '20px'}}>
                      <ConstructionModule />
                  </div>
                  </Col>
              </Row>
              </UserHasModule> */}

              {/* <Row style={{ marginBottom: '25px' }}>
                <Col>
                <div className="yc-module rounded" style={{color: 'black',  padding: '20px'}}>
                    <ProductLogo type="black" title="Marking">
                      </ProductLogo>

                      <UserHasModule moduleId="youcap.marking" upgrade>

                      <CollapseList style={{marginBottom: '20px'}} id="templatelist" items={[
                            { title: <React.Fragment><h5 className="mb-1">Demo Exams</h5><small><Badge color="danger" style={{fontSize: '11px'}} pill>15</Badge></small></React.Fragment>, 
                            isOpen: true, content: <React.Fragment>
                                <p className="mb-1">ISO 21500 Sve v2</p>
                                <small>Last updated 2020-04-28</small>
                                <Button color="link" className="float-right" onClick={() => App.publish('securetest.marking',{id: '1'})}>{lang.Text('btn.open')}</Button>
                            </React.Fragment>,  },
                        ]}/>


                      <Button color="dark" outline className="btn--pill" 
                          onClick={() => App.publish("add.template.type",{tab: 'new'})}>
                            <i className="fas fa-plus" style={{marginRight: '5px'}}></i>
                            Manage Marking</Button>
                      
                      </UserHasModule>
                  </div>
                </Col>
             </Row> */}
              
            
            </Col>
          <Col md="6" xl="4">
            {/* <Row style={{ color: 'black', marginBottom: '25px' }}>
              <Col>
              <div className="yc-module rounded" style={{ padding: '20px'}} >
                
                  <LinkGenerator></LinkGenerator>
                  
                </div>
              </Col> 
            </Row> */}

            

            
            {/* <UserHasModule moduleId="youcap.revision" >
            <Row style={{ marginBottom: '25px' }}>
                <Col>
                <div className="yc-module rounded" style={{color: 'black',  padding: '20px'}}>
                    <ProductLogo type="black" title="Revision"> */}
                      {/* <Badge color="secondary" style={{fontSize: '11px'}} pill>Beta</Badge> */}
                      {/* </ProductLogo>
                      <CollapseList style={{marginBottom: '20px'}} id="rev1" items={[
                            { title: <React.Fragment><h5 className="mb-1">Projekt Baseline</h5><small><Badge color="danger" style={{fontSize: '11px'}} pill>15</Badge></small></React.Fragment>, 
                            isOpen: false, content: <React.Fragment>
                                <p className="mb-1">April Revision</p>
                                <small>Last updated 2020-04-28</small>
                                <Button color="link" className="float-right" onClick={() => App.publish('revision.show',{id: '1'})}>{lang.Text('btn.open')}</Button>
                            </React.Fragment>,  },
                        ]}/>
                  </div>
                </Col>
             </Row>
             </UserHasModule> */}
            
            <UserHasModule moduleId="youcap.sessions.persons">
                <SessionPersonsModule />
            </UserHasModule> 
          
             <UserHasModule moduleId="youcap.experts" >
             <Row style={{ marginBottom: '25px' }}>
                <Col>
                <div className="yc-module rounded" style={{color: 'black',  padding: '20px'}}>
                    <ProductLogo type="black" title="Experts">
                      {/* <Badge color="secondary" style={{fontSize: '11px'}} pill>Beta</Badge> */}
                      </ProductLogo>
                  </div>
                </Col>
             </Row>
             </UserHasModule>

             <UserHasModule moduleId="youcap.construction">
              <Row style={{marginBottom: '25px'}}>
                  <Col>
                  <div className="yc-module rounded" 
                      style={{color: 'black', padding: '20px'}}>
                      <ConstructionModule />
                  </div>
                  </Col>
              </Row>
              </UserHasModule>

             <UserHasModule moduleId="youcap.eduadmin">
              <Row style={{ color: 'black', marginBottom: '25px' }}>
                  <Col>
                  <div className="yc-module rounded " style={{ padding: '20px'}}>
                      <EduAdminModule></EduAdminModule>
                    </div>
                  </Col>
              </Row>
            </UserHasModule>
            
            {/* <UserHasModule moduleId="youcap.zoom">
              <Row style={{ color: 'black', marginBottom: '25px'}}>
                <Col>
                <div className="yc-module rounded " style={{ padding: '20px'}}>
                  
                      <ZoomMeetingsPage></ZoomMeetingsPage>
                  
                  </div>
                </Col> 
              </Row>
              </UserHasModule> */}


          
              <UserHasModule moduleId="youcap.teams">
              <Row style={{ color: 'black', marginBottom: '25px' }}>
                <Col>
                <div className="yc-module rounded" style={{ padding: '20px'}} >
                    
                      <TeamsModule />
                    
                </div>
              </Col> 
              </Row>
              </UserHasModule>
            
            <UserHasModule moduleId="youcap.dna">
            <Row style={{ color: 'black', marginBottom: '25px' }}>
                <Col>
                <div className="yc-module rounded" style={{color: 'black', padding: '20px'}}>
                  <ProductLogo type="color" title="DNA"><Badge color="danger" style={{fontSize: '11px'}} pill>New Beta</Badge></ProductLogo>
                 
                  
                  </div>
                </Col>
             </Row>
             </UserHasModule>

             <UserHasModule moduleId="youcap.advanced.statistics" >
             <Row style={{ color: 'black', marginBottom: '25px' }}>
                <Col>
                <div className="yc-module rounded" style={{color: 'black', marginTop: '25px', padding: '20px'}}>
                    <ProductLogo type="black" title="Advanced Statistics">
                        <Badge color="secondary" style={{fontSize: '11px'}} pill>Beta</Badge>
                    </ProductLogo>
                   
                  </div>
                </Col>
             </Row>
             </UserHasModule>
            
             <UserHasModule moduleId="youcap.shutterstock" >
            <Row style={{ color: 'black', marginBottom: '25px' }}>
              <Col>
              <div className="yc-module rounded" style={{ padding: '20px'}} >
                  <Row>
                    <Col md="12">
                    <img src={shutterstock_logo} style={{ paddingBottom: '25px', width: '136px' }}/>
                    {/* <Button color="secondary" size="sm" outline className="btn--pill float-right"
                                      onClick={() => App.publish('shutterstock.settings',{})} >
                        <i className="fa fa-cog"/>
                      </Button> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    

                   
                    </Col>
                  </Row>
                  
                </div>
            </Col> 
            </Row>
            </UserHasModule>
            
            <UserHasModule moduleId="youcap.marketplace.integrations">
            <Row>
              <Col>
                <Alert color="primary" 
                  style={{backgroundColor: '#0460D9', color: 'white', marginBottom: '25px' }}>
                    <Button style={{color: 'white'}} color="link" onClick={() => App.publish('config.open',{})}>
                      <i className="fa fa-plus"></i> {lang.Text('add.integrations')}
                      </Button>
                </Alert>
              </Col>
            </Row>
            </UserHasModule>


            </Col>
          </Row>



        </Container>
        </section>
      </Fade>}

      <InfoPanel title={lang.Text('add.integrations')} cancelButtonLabel={lang.Text('btn.close')} 
          modalPanelOpen="config.open"
          position="modal-panel-right">
          <div className="yc-panel-md">
          <Container>
            <Row>
              <Col>
            
              </Col>
            </Row>

          </Container>
          </div>
      </InfoPanel>

      

      <InfoPanel title={lang.Text('upgrade.subscription')} cancelButtonLabel={lang.Text('btn.close')} 
          modalPanelOpen="upgrade.subscription"
          position="modal-panel-right">
         <div className="yc-panel-md">
          <Container>
            <Row>
              <Col>
              
              
              </Col>
            </Row>

          </Container>
          </div>
      </InfoPanel>

      <InfoPanel title={lang.Text('custmomer.settings')} cancelButtonLabel={lang.Text('btn.close')} 
          modalPanelOpen="customer.organisations.open"
          position="modal-panel-right"
          showHeader={false}>
         <div className="yc-panel-md">
          <Container fluid>
          <Row>
            <Col>
                <h5>{lang.Text('my.organisations')}</h5>
            </Col>
          </Row>
            <Row className="mt-3">
              <Col>
              <CollapseList id="organisationList" items={this.getOrganisations(this.state.organisations,lang)} lang={lang}/>
              </Col>
            </Row>
          </Container>
          </div>
      </InfoPanel>


      <InfoPanel title={lang.Text('customer.users')} cancelButtonLabel={lang.Text('btn.close')} 
          modalPanelOpen="customer.users.open"
          position="modal-panel-right">
         <div className="yc-panel-md">
          <Container fluid>
            <Row className="mt-1">
              <Col>
                  <h6>Administratörer:</h6>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <CollapseList id="adminList" items={this.getAdmins(this.state.users,lang)} lang={lang}/>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
              <Button color="link" className="float-right" 
                onClick={() => this.AddAdmin()}>
                  {lang.Text('btn.add.admin')}
                </Button>
                </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                  <h6>Användare:</h6>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <CollapseList id="userList" items={this.getUsers(this.state.users,lang)} lang={lang}/>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
              <Button color="link" className="float-right" 
                onClick={() => this.AddUser()}>
                  {lang.Text('btn.add.user')}
                </Button>
                </Col>
            </Row>
            
           

          </Container>
          </div>
      </InfoPanel>


    </React.Fragment>);
  }
  
  AddAdmin(): void {
    throw new Error('Method not implemented.');
  }
  AddUser(): void {
    throw new Error('Method not implemented.');
  }

  private getOrganisations(orgs: User.OrganisationInfo[], lang: Language) : CollapseListItem[]  {
    
    var list: CollapseListItem[] = new Array<CollapseListItem>();
 
    orgs.forEach((item) => {
         list.push(
         { title: <React.Fragment><h5 className="mb-1">{item.name}</h5></React.Fragment>, 
          isOpen: this.state.user.customerId==item.id, content: <React.Fragment>
              <small>{item.orgid}</small><br/>
              <small>{item.id}</small>
              {this.state.user.customerId==item.id ? <>
                <i style={{fontSize: '17px'}} className="fa fa-check text-success float-right"/>
              </> :
              <Button color="link" className="float-right" onClick={() => this.changeOrg(item.id)}>
                  {lang.Text('btn.change.org')}
                </Button>}
          </React.Fragment>,  }
         );
     });

    
     return list;
   }

   private getAdmins(users: User.UserProfileInfo[], lang: Language): CollapseListItem[] {
    var list: CollapseListItem[] = new Array<CollapseListItem>();
    list.push(
      { title: <React.Fragment><h5 className="mb-1">Samuel Danielsson</h5></React.Fragment>, 
       isOpen: false, content: <React.Fragment>
           
           {/* <small>{item.id}</small>
           {this.state.user.customerId==item.id ? <>
             <i style={{fontSize: '17px'}} className="fa fa-check text-success float-right"/>
           </> :
           <Button color="link" className="float-right" onClick={() => this.changeOrg(item.id)}>
               Gå till
             </Button>} */}
             <small>f3b3e5f0-9df9-46cb-b8f2-3a6c91ff1b6d</small>
              <i style={{fontSize: '17px'}} className="fa fa-check text-success float-right"/>
       </React.Fragment>,  }
      );
 
     return list;
   }

   private getUsers(users: User.UserProfileInfo[], lang: Language) : CollapseListItem[]  {
    
    var list: CollapseListItem[] = new Array<CollapseListItem>();
 
    users.forEach((item) => {
         list.push(
         { title: <React.Fragment><h5 className="mb-1">{item.name}</h5></React.Fragment>, 
          isOpen: false, content: <React.Fragment>
              
              {/* <small>{item.id}</small>
              {this.state.user.customerId==item.id ? <>
                
              </> :
              <Button color="link" className="float-right" onClick={() => this.changeOrg(item.id)}>
                  Gå till
                </Button>} */}
              <small>{item.id}</small>
              <i style={{fontSize: '17px'}} className="fa fa-check text-success float-right"/>
          </React.Fragment>,  }
         );
     });

     list.push(
      { title: <React.Fragment><h5 className="mb-1">Samuel Danielsson</h5></React.Fragment>, 
       isOpen: false, content: <React.Fragment>
           
           {/* <small>{item.id}</small>
           {this.state.user.customerId==item.id ? <>
             <i style={{fontSize: '17px'}} className="fa fa-check text-success float-right"/>
           </> :
           <Button color="link" className="float-right" onClick={() => this.changeOrg(item.id)}>
               Gå till
             </Button>} */}
             <small>f3b3e5f0-9df9-46cb-b8f2-3a6c91ff1b6d</small>
              <i style={{fontSize: '17px'}} className="fa fa-check text-success float-right"/>
       </React.Fragment>,  }
      );
 
     return list;
   }

  changeOrg(id: string): void {
    fetch('api/user/ChangeCurrentOrganisation?orgid='+id, GlobalAppsettings.GetPostInit(null))
      .then(response => response.json() as Promise<string>)
      .then(data => {
          console.log(data);
          window.location.reload();
      }).catch(reason => {
          //Track Exception
          App.publish('track-exception', {error: reason});
      });
  }

  onDismiss(): void {
    this.setState({alertIsVisible: !this.state.alertIsVisible});
  }

  
}

import * as App from 'pubsub-js';

declare global {
    interface Window {
        Intercom?: any;
        intercomSettings?: any;
    }
}

export interface IntercomUser {
    name: string
    userid: string
    email: string
}

export interface IntercomApp {
    appid: string
}

export class Intercom {
    constructor() {
       
        window.intercomSettings = {
            alignment: 'right',
            horizontal_padding: 8, 
            vertical_padding: 70,
            hide_default_launcher: true
          };

        App.subscribe("intercom.update",this.update);
        App.subscribe("intercom.open",this.show);
        App.subscribe("intercom.hide",this.hide);

        App.subscribe("intercom.start-tour",this.startTour);
        App.subscribe("intercom.show.newmessage",this.showNewMessage);

        App.subscribe("intercom.setup-user",this.setupUser);
        App.subscribe("intercom.setup",this.setup);

        App.subscribe("intercom.event",this.trackEvent);

        
        /*
        Subscribe to 'intercom.unreadcount.change' to get badge count on laucher
        */
    }

    private showNewMessage(msg : string, data : any) {
        
        if (data!=undefined)
        {
            console.info("intercom ShowMessages -> " + data.id);
            if (window.Intercom!=undefined)
            {
                window.Intercom('showNewMessage', data.id);
            }
        }
        else
        {
            console.info("intercom ShowMessages");
            if (window.Intercom!=undefined)
            {
                window.Intercom('showNewMessage');
            }
        }
    }

    

    private startTour(msg : string, data : any) {
        
        if (data!=undefined)
        {
            console.info("Start Intercom Tour -> " + data.id);

            if (window.Intercom!=undefined)
            {
                window.Intercom('startTour', data.id);
            }
        }
    }

    private setup(msg : string, app : IntercomApp) {
        
        console.info("Setup Intercom...");
        
        if (window.Intercom!=undefined)
        {
            console.info("Boot Intercom: " + app.appid);
            
            window.Intercom('boot',
            {
                app_id: app.appid 
            });

            //TODO: add eventhandlers
            //
            //Intercom('onHide', function() { // Do stuff });
            //Intercom('onShow', function() { // Do stuff });
            window.Intercom('onUnreadCountChange', function(unreadCount: number) {
                App.publish('intercom.unreadcount.change',{count: unreadCount});
            });
        }
    }

    private setupUser(msg : string, user : IntercomUser) {
        if (window.Intercom!=undefined) {
            window.Intercom('update',{
                name: user.name,
                user_id: user.userid,
                email: user.email
            });
        }
    }

    private trackEvent(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('trackEvent', data);
        }
    }    

    private update(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('update');
        }
    }

    private show(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('show');
        }
    }

    private hide(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('hide');
        }
    }

}
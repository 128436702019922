import * as App from 'pubsub-js';


export class UrlHelper {

    public static GetIdFromCurrentURL() : any {
        try {
            var path = window.location.pathname.split('/');
            var id = path[path.length-1];
            return id;
        } catch (error) {
            console.error(error);   
        }
    }

    public static GetCurrentURL() : any {
        try {
            return window.location.pathname;
        } catch (error) {
            console.error(error);   
        }
    }

    public static GetParamsFromCurrentURL() : any {
        try {
            var path = window.location.href.split('?')
            var id = path[path.length-1];
            return id;
        } catch (error) {
            console.error(error);   
        }
    }
}

import * as React from 'react';

import './spinner.css';

interface LoadingProps {
    loading?: boolean;
    style?: any;
}

export class Loading extends React.Component<LoadingProps, {}> {
    constructor(props:LoadingProps) {
        super(props);
    }

    public render() {
        return <React.Fragment>
        {this.props.loading ? 
            <div style={this.props.style} className="d-flex justify-content-center">
                <div className="d-flex align-items-center">
                    <div className="spinner-grow-p1 text-dark"
                        role="status">
                        </div>
                        <div className="spinner-grow-p2 text-dark" 
                        role="status">
                        </div>
                        <div className="spinner-grow-p3 text-dark" 
                        role="status">
                    </div>
                </div>
            </div>
        : <React.Fragment>
            {this.props.children}
        </React.Fragment>
        }</React.Fragment>
    }
}
import * as React from 'react';
import * as base64 from 'base-64';

export class GlobalAppsettings {
    
    protected static api_key: string = "*l1^-;L^p1|G%956n_*Q520b"; //Todo: Change this!
    
    
    static GetApiHeaders(): HeadersInit {
        return new Headers({
            "Authorization": `Basic ${base64.encode(`API-TOKEN:${GlobalAppsettings.api_key}`)}`,
            "Content-Type": "application/json"
        })
    }

    static GetRequestInit() : RequestInit {
        return {
            credentials: "same-origin",
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
    }

    static GetPostInit(data: any) : RequestInit {
        return {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
    }
    
    /**
     * Configuration 
     * 
     * 
     */

    static AppId : string = "yc-online"  
    static LanguageId: string =  "yc-online-language"; 
    
    static AppInsights: string  = ""; 
    static PolicyUrl: string = "";

    static LoginUri: string ="/session/login";

    static IsDevelopment: boolean = true;

    static SecureTestUri: string = "/securetest";


    
    

}
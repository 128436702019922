
/* Polyfills for ie-11 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
/* Polyfills for ie-9, ie-10 and ie-11
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
*/

import 'bootstrap/dist/css/bootstrap.css';

/** Theme */
import './theme/web-theme.css';
//import './theme/dashboard-theme.css';

// * Fonts */
import './theme/fonts.css';

//** Local App css */
import './theme/site.css';

import React from 'react';
import ReactDOM from 'react-dom';


import App from './App';

import {AppCore} from './backbone/AppCore';
import { __RouterContext } from 'react-router';
import { LanguageProvider,LanguageEditor } from './backbone/Language';

/** Import FontAwesom Icons */
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons'
// library.add(fas);

import { Intercom } from './backbone/Intercom';
import { GlobalAppsettings } from './backbone/GlobalSettings';


const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.Fragment>
    <LanguageProvider>
    <AppCore />
    <App />
    </LanguageProvider>
    <LanguageEditor/>
  </React.Fragment>,
  rootElement);

var intercom = new Intercom();

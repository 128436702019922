import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { GlobalAppsettings } from './GlobalSettings';
import * as App from 'pubsub-js';
import {AppCore, AuthEventInfo} from './AppCore';

export interface AuthorizeRouteState {
    ready: boolean,
    authenticated: boolean
}

export default class AuthorizeRoute extends Component<any,AuthorizeRouteState> {
    constructor(props: any) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        App.subscribe('app.auth-changed',this.authChanged.bind(this))
        //this.populateAuthenticationState();
    }

    componentWillUnmount() {
        App.unsubscribe('app.auth-changed');
    }

    private authChanged(msg: string, data: AuthEventInfo) {
        if (data!=undefined)
        {
            this.setState({ ready: true, authenticated: data.IsAuthenticated });
        }
        else
        {
            this.setState({ ready: false, authenticated: false });
        }
    }

    render() {
        
        const redirectUrl = GlobalAppsettings.LoginUri;

        if (!this.state.ready) {
            return <div style={{height: '400px'}}></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (this.state.authenticated) {
                        return <Component {...props} />
                    } else {
                        return <div style={{height: '400px'}}>
                        {/* <Redirect to={redirectUrl} /> */}
                        </div>
                    }
                }} />
        }
    }
}

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as App from 'pubsub-js';


import {Media, Badge, Row, Col, Button, Navbar, Nav, Form, FormGroup, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    CardText, Card, CardTitle, Alert, Container, CardImg, CardBody, CardSubtitle, Tooltip, TooltipProps, Breadcrumb, BreadcrumbItem,
     NavItem, NavLink, TabContent, TabPane, Label, FormFeedback, FormText, CustomInput, CarouselItem, CarouselCaption, Carousel, 
     CarouselIndicators, CarouselControl } from 'reactstrap';
import { UrlHelper } from '../../../backbone/UrlHelper';
import { ModalPanel } from '../../../panels/ModalPanel';
import { DraftEditor, DraftEditorData } from '../../../components/drafteditor/DraftEditor';
import { InfoPanel } from '../../../panels/InfoPanel';
import { Api } from '../api/WorkSet';
import { CustomCheckBox } from '../../../components/CustomCheckBox';
import { SaveButton } from '../../../components/base/SaveButton';
import { LanguageContext, Language } from '../../../backbone/Language';

import { InfoLink } from '../../../components/base/InfoLink';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components/base/Loading';

import Moment from 'react-moment';
import { MediaPanel } from '../panels/MediaPanel';
import { TaskMedia } from './TaskMedia';

interface TaskEditState {
    current?: Api.TaskCore;
    loading: boolean;
    dataIsLoaded: boolean;
    history?:  Api.TaskCore[];
    currentId: string;
    currentVersionId: string;
    changes?: Api.TaskChange[];
    disable: boolean;
    isDirty: boolean;
    saving: boolean;
    readonly: boolean;
    preview_text: string;
    preview_answer1: string;
    preview_answer2: string;
    preview_answer3: string;
    preview_answer4: string;
    groupName: string;
    orgName: string;
    worksetName: string;
    showChat: boolean;
    remissEmailValid?: boolean;
    remissLoading: boolean;
    remissConfig?: Api.TaskRevisionConfig;
    remissTarget?: Api.RevisionTarget;
    remissSending: boolean;
    remissSuccessMessage?: string;
    remissErrorMessage?: string;
    mediaIndex: number;
    currentRevisions?: Api.TaskRevision[],
    revisionMessage?: string,
    revisionBounceMessage: string,
}

interface MediaRefDisplay{
    id: string;
    url: string;
    index: number;
    height: number;
    provider: string;
    description: string;
    searchText: string;
}

interface TaskEditProps {
    id: string
}

export class TaskEdit extends React.Component<TaskEditProps, TaskEditState> {
    
    constructor(props:any) {
        super(props);
        this.state = { loading: true, 
                       dataIsLoaded: false, 
                       currentId: '', 
                       currentVersionId: '',
                       isDirty: false,
                       preview_text: '',
                       preview_answer1: '',
                       preview_answer2: '',
                       preview_answer3: '',
                       preview_answer4: '',
                       readonly: false,
                       groupName: '',
                       saving: false,
                       orgName: 'Organisation',
                       worksetName: 'Workset',
                       showChat: false,
                       remissLoading: false,
                       remissSending: false,
                       mediaIndex: 0,
                       revisionBounceMessage:'',
                       disable: false,
                     };

        this.addChange = this.addChange.bind(this);
        this.oneditorChange = this.oneditorChange.bind(this);
        this.ontextChange = this.ontextChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.renderBreadCrumbs = this.renderBreadCrumbs.bind(this);
        this.sendOther = this.sendOther.bind(this);
 
    }

    public componentDidMount() {

        App.publish("app.update-securityinfo",{});
    
        App.subscribe("taskedit.addmedia",this.addMedia.bind(this));

        App.subscribe("taskedit.removemedia",this.removeMedia.bind(this));

        App.subscribe("taskedit.save",this.saveChanges.bind(this));
     
        this.load();
       
    }

    load() {

        fetch('api/workset/gettask?id='+ this.props.id,
        {credentials: "same-origin"})
        .then(response => response.json() as Promise<Api.TaskCore>)
        .then(data => {
            
            this.setState({currentId: this.props.id, currentVersionId: data.versionId, 
                current: data, loading: false, dataIsLoaded: true, saving: false,
                preview_text: data.question.text,
                preview_answer1: data.question.correctAnswer,
                preview_answer2: data.question.distractor1,
                preview_answer3: data.question.distractor2,
                preview_answer4: data.question.distractor3,
                groupName: '',
                readonly: data.readOnly
            });

          

        }).catch(reason => {
            this.setState({ loading: false });
        
        });
    }

    componentWillUnmount() {
        App.publish("leftmenu.deactivate", {});
        App.unsubscribe('taskedit.save');
        App.unsubscribe('taskedit.addmedia');
        App.unsubscribe('taskedit.removemedia');
        
    }

    private showTag(link: string) {
        // App.publish("tag.open",{link});
        this.openHistory();  
    }

    private openStatus() {
        App.publish("status.open",{ }); 
    }

    private openExpert() {
        App.publish("expert.open",{ }); 
    }

    private addMedia(msg:string, mediaRef?: Api.TaskMediaRef) {
        if (mediaRef!=undefined && this.state.current!=undefined) {   
            let cur = this.state.current;
            cur.mediaRefs.push(mediaRef);
 
            let _dirty = this.addMediaChange(this.state.currentId, 'task.addmedia' , mediaRef.url);
            this.setState({current: cur, isDirty: true, changes: _dirty});
        }
    }

    private removeMedia(msg: string, index: number) {
        if (this.state.current!=undefined) {   
            let cur = this.state.current;
            
            let ref =  cur.mediaRefs.splice(index,1);

            let _dirty = this.addChange(this.state.currentId, 'task.removemedia' , ref[0].url);
            this.setState({current: cur, isDirty: true, changes: _dirty});
        }
    }

    private openRemiss() {
        this.setState({ remissLoading: true,remissTarget: undefined, remissSending: false,
            currentRevisions: undefined});

        App.publish("review.open",{ });
        App.publish('intercom.event','Open ReviewPanel');

        fetch('api/revision/GetRevisionConfig',
            {credentials: "same-origin"})
            .then(response => response.json() as Promise<Api.TaskRevisionConfig>)
            .then(data => {
                console.info(data);

                this.setState({remissConfig: data, remissLoading: false});

            }).catch(reason => {
                this.setState({ loading: false, saving: false,remissLoading: false });
        });

        fetch('api/revision/GetRevisionsForTaskId?taskid='+ this.state.currentId,
            {credentials: "same-origin"})
            .then(response =>  response.json() as Promise<Api.TaskRevision[]>)
            .then(data => {
                this.setState({currentRevisions: data});
            }).catch(reason => {
                this.setState({ currentRevisions: undefined });
        });
    }

    private remissTargetChange(event: any) {
        let targetId: string = event.target.value;
        if (this.state.remissConfig!=undefined) {

            if (targetId.length>0 && targetId != 'none') {
                this.state.remissConfig.revisionTargets.forEach(element => {
                    if (element.isActive && element.userid==targetId){
                        this.setState({remissTarget: element,
                            remissErrorMessage: undefined});
                    }
                });
            }
            else {
                this.setState({remissTarget: undefined,
                    remissErrorMessage: undefined})
            }
        }

    }

    private onRevisionMessageChange(event: any) {
        this.setState({revisionBounceMessage: event.target.value});
    }

    private sendOther(currentRevision: Api.TaskRevision) {
        if (currentRevision!=undefined && this.state.remissTarget!=undefined)
        {
            this.setState({remissLoading: true, revisionMessage: undefined});

            fetch('api/revision/sendto?id=' + currentRevision.id
                + "&userid=" + this.state.remissTarget.userid ,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
                })
                .then(response => response.json() as Promise<Api.RevisionResponse>)
                .then(data => {
                    
                    this.setState({ remissSuccessMessage: data.message, remissLoading: false});
                    
                    if (data.success) {
                        //App.publish("revision.reload",{});
                    }

                }).catch(reason => {
                    this.setState({ remissLoading: false });
                });
        }
    }

    private sendRemiss() {
        if (this.state.remissTarget!=undefined && this.state.current!=undefined) {

            this.setState({remissSending: true,
                remissSuccessMessage: undefined,
                remissErrorMessage: undefined});

            let remissInfo: Api.RevisionRequest = {
                targetuserid: this.state.remissTarget.userid,
                taskId: this.state.current.id,
                userid: ''
            };

            fetch('/api/revision/NewRevision', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(remissInfo),
                credentials: 'same-origin'
              })
              .then(response => response.json() as Promise<Api.RevisionResponse>)
              .then(data => {
                    console.info(data);

                    if (data.success) {
                        this.setState({remissSending: false,
                            remissSuccessMessage: data.message,
                            remissTarget: undefined});
                    }
                    else
                    {
                        this.setState({remissSending: false,remissErrorMessage: data.message});
                    }
              })
              .catch(reason => {
                  this.setState({remissSending: false, remissErrorMessage: ''});
                  App.publish('intercom.event','Exception ' + reason);
            });

        }
    }

    private openPreview() {
        
        let preview = {
            id: this.state.currentId,
            versionId: undefined, //always open latest version
            showTask: false    
        }
        
        App.publish("preview.open", preview);
    }

    private openSettings() {
        App.publish("settings.open",{ }); 
    }

    private openSearch() {
        App.publish("media.open", undefined); 
    }

    private searchMedia(text: string) {
        App.publish("media.open", text); 
    }

    private openHistory() {
        if (this.state.current!=undefined)
        {
            App.publish("history.open",this.state.currentId);
        }
    }

    private addMediaChange(id:string,type:string,text: string) {
        let _dirty = this.state.changes;
        
        if (_dirty == undefined) {
            _dirty = [];
        }

        _dirty.push({id: id, type: type, text: text});
    
        return _dirty;
    }

    private addChange(id:string,type:string,text: string) {
        let _dirty = this.state.changes;

        if (_dirty == undefined) {
            _dirty = [];
        }

        let f: Api.TaskChange | undefined;
        _dirty.forEach((g) => {
            if (g.id == id && g.type == type) {
                f = g;
            }
        });

        if (f != undefined) {
            f.text = text;
        }
        else
        {
            _dirty.push({id: id, type: type, text: text});
            console.log("add change: " + type);
        }
        
        return _dirty;
    }

    private ontextChange(id:string, data: DraftEditorData)
    {
        if (data.contentStateText!=undefined)
        {
            if (id=='task.question.text' && data.contentStateText!=undefined)
            {
                this.setState({preview_text: data.contentStateText});
            }

            if (id=='task.question.correctAnswer' && data.contentStateText!=undefined)
            {
                this.setState({preview_answer1: data.contentStateText});
            }
            if (id=='task.question.distractor1')
            {
                this.setState({preview_answer2: data.contentStateText});
            }
            if (id=='task.question.distractor2')
            {
                this.setState({preview_answer3: data.contentStateText});
            }
            if (id=='task.question.distractor3')
            {
                this.setState({preview_answer4: data.contentStateText});
            }

            let _dirty = this.addChange(this.state.currentId, id, data.contentStateText);
            this.setState({isDirty: true, changes: _dirty});

            App.publish("taskedit.dirty",null);
        }           
    }

    private oneditorChange(id:string, data: DraftEditorData)
    {
        if (data.contentStateRaw!=undefined)
        {
            let _dirty = this.addChange(this.state.currentId, id, data.contentStateRaw);
            this.setState({isDirty: true, changes: _dirty});

            App.publish("taskedit.dirty",null);
        }     
    }

    private saveChanges() {
        this.setState({saving: true});

        if (this.state.isDirty && this.state.changes!=undefined && this.state.current!=undefined)
        {
            let changeBundle = {
                changes: this.state.changes,
                media: {
                    taskid: this.state.currentId,
                    mediaRefs: this.state.current.mediaRefs
                }
            }
            
            fetch('/api/workset/savechanges', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(changeBundle),
                credentials: 'same-origin'
              })
              .then(response => {
                console.log('SaveDone.');
                
                console.info(App.publish("construction.edit.close",null));

                 console.log('Save response');
                 console.log(response); 
                 
                 this.setState({isDirty: false});

              })
              .catch(reason => {
                  console.info('Save Error' + reason);
                  this.setState({saving: false});
            });            

        }
    }

    private saveDone() {
        //console.log("SaveDone");
        
    }

    private navBack() {
        //window.history.back();
    }

    public render() {

        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div> 
        
           <Loading loading={this.state.loading}>

            {this.state.current==undefined ? 
             <Alert color="danger">
                {lang.Text('taskedit.stderror')}
            </Alert>
            : <React.Fragment>
            <Container>
                {/* <Row style={{marginTop: '-15px',marginBottom: '-15px',padding: '0px'}}>
                    {this.renderBreadCrumbs(this.state.current)}
                </Row> */}
                <Row>
                    {/* <Col sm='1' md='1' 
                    style={{verticalAlign: 'top', padding: '5px', paddingTop: '0px',
                    paddingRight: '15px', borderRight: 'lightgray 1px solid'}} >
                        
                    </Col> */}
                    <Col md={this.state.showChat ? '7' : '12'} 
                    // style={{borderRight: 'lightgray 1px solid'}}
                    >
                        { this.renderMessages() }
                        <Row >
                            <Col sm='12' md='12'>
                                { this.renderMain(this.state.current) }
                            </Col>
                        </Row>
                        <Row className="">
                            <Col sm='12' md='12'>
                                { this.renderAlerts(this.state.current) }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12' md='12'>
                            { this.renderReferences(this.state.current) }
                            </Col>
                        </Row>
                    </Col>
                    <Col  md={this.state.showChat ? '4': '0'}>
                        {this.state.showChat ? this.renderChat() 
                            : <React.Fragment></React.Fragment>}
                    </Col>
                </Row>
            

            <InfoPanel title="Expert" cancelButtonLabel={lang.Text('button.close')} 
                  modalPanelOpen="expert.open" 
                 position="modal-panel-right">
                 <div className="yc-panel-sm">
                    <Alert color="warning">
                        <span className="iconic iconic-warning"></span>     
                        <span>
                        {'  '} {lang.Text('task.expert.nodata')}
                        </span>
                    </Alert>
                 {/* <Card body inverse style={{ backgroundColor: '#333', borderColor: '#333' }}>
                     <CardTitle>Domänexpert</CardTitle>
                     <CardText>Expert inom ett område som frågan berör.</CardText>
                     <Button disabled>Välj</Button>
                 </Card>
                 <Card body inverse color="success" style={{marginTop: '20px'}}>
                     <CardTitle>Frågekonstruktör</CardTitle>
                     <CardText>Expert på frågekonstruktion.</CardText>
                     <Button color="secondary" disabled>Välj</Button>
                 </Card>
                 <Card body inverse color="info" style={{marginTop: '20px'}}>
                     <CardTitle>Illustratör</CardTitle>
                     <CardText></CardText>
                     <Button color="secondary" disabled>Välj</Button>
                 </Card>
                 <Card body inverse color="warning" style={{marginTop: '20px'}}>
                     <CardTitle>Fotograf</CardTitle>
                     <CardText></CardText>
                     <Button color="secondary" disabled>Välj</Button>
                 </Card> */}
             </div>
           </InfoPanel>
           
            <MediaPanel title={lang.Text('mediapanel.title')} cancelButtonLabel={lang.Text('button.close')}
                modalPanelOpen="media.open" position="modal-panel-right"
                >
            </MediaPanel>

           <InfoPanel
                 title="Status" cancelButtonLabel={lang.Text('button.close')} 
                 modalPanelOpen="status.open" 
                 position="modal-panel-right">
                 <div className="yc-panel-sm">
 
                 <Alert color="primary">
                 <span className="iconic iconic-info"></span>     
                 <span>
                 {'  '} {lang.Text('task.status.info')} 
                 </span>
                 </Alert>
                    {/* <Button color="link" disabled>Publicera till Provsystem (produktion)</Button><br/>
                    <Button color="link" disabled>Publicera till Provsystem (test)</Button><br/> */}
                    {/* <Button color="link" disabled> {lang.Text('task.status.publish.internal')} </Button><br/>
                    <br/>
                    <Button color="link" disabled> {lang.Text('task.status.lock')} </Button><br/> */}

                    <CustomInput type="switch" id="sw1" name="sw1" 
                        checked={this.state.disable}
                        label={"Avpublicera"} />
                 </div>
           </InfoPanel>

           <InfoPanel title={lang.Text('revision.title')} cancelButtonLabel={lang.Text('button.close')}
                //  okButtonLabel={lang.Text('button.save')} 
                 modalPanelOpen="review.open" 
                //  modalPanelSave="review.save"
                 position="modal-panel-right">
                 <div className="yc-panel-md">
                 {/* <Alert color="primary">
                 <span className="iconic iconic-info"></span>     
                 <span>
                 {'  '} {lang.Text('task.remiss.nodata')}
                 </span>
                 </Alert>

                 <Alert color="warning">
                 <span className="iconic iconic-warning"></span>     
                 <span>
                 {'  '} {lang.Text('task.remiss.missingkonfig')} 
                 </span>
                 </Alert> */}
                 {this.state.currentRevisions!=undefined && this.state.currentRevisions.length>0 ?
                 <React.Fragment>
                    {this.renderActiveRevisions(lang,this.state.current,this.state.remissConfig, this.state.currentRevisions)}
                 </React.Fragment>
                : <React.Fragment>
                    {this.renderRevision(this.state.current,this.state.remissConfig)}
                </React.Fragment>                 
                 }
                 </div>
           </InfoPanel>

           </Container>
           </React.Fragment>
        }
        </Loading>
      </div>)}
    </LanguageContext.Consumer>
    </React.Fragment>
    }

    private renderActiveRevisions( lang: Language,task?: Api.TaskCore, remissConfig?: Api.TaskRevisionConfig, currentRevisions?: Api.TaskRevision[]
       ) {
        if (currentRevisions!=undefined)
        {
            return <React.Fragment>
            {this.state.remissSuccessMessage !=undefined ?
                <Alert color="success">
                    <span className="iconic iconic-check"></span>     
                    <span>
                    {'  '}{this.state.remissSuccessMessage}
                    </span>
                </Alert> 
                : <React.Fragment>
                    {currentRevisions.map(revision =>
                        
                        <React.Fragment>
                            <Alert color="primary">
                                <span className="iconic iconic-info"></span>     
                                <span>
                                {'  '} {lang.Text('task.remiss.bounce.info')}<br/><br/>
                                <p><b>{revision.message}</b></p>
                                <p></p>
                                <p>{lang.Text('task.remiss.bounce.text')}<br/><br/></p>
                                </span>
                            </Alert>
                            {/* <FormGroup>
                                <Label for="exampleText">{lang.Text('revision.bounce.message')}:</Label>
                                <Input type="textarea" name="text" id="exampleText" 
                                value={this.state.revisionBounceMessage}
                                onChange={this.onRevisionMessageChange.bind(this)}
                                />
                            </FormGroup>     */}
                            <FormGroup>
                                <Label for="remissCustomSelect">{lang.Text('task.remiss.bounce.target')}</Label>
                                <CustomInput type="select" id="remissCustomSelect" name="remissSelect"
                                    onChange={this.remissTargetChange.bind(this)}
                                    >
                                    <option value='none'>{lang.Text('task.remiss.choose')}</option>
                                    {remissConfig !=undefined ? remissConfig.revisionTargets.map(target =>
                                        <option value={target.userid} >
                                            {target.name} ({target.area})
                                        </option>
                                    ) : <React.Fragment></React.Fragment> }
                                </CustomInput>
                            </FormGroup>
                            <FormGroup className="top-spacing ">
                                {this.state.remissTarget!=undefined ?
                                    <div className="pull-right">
                                    <SaveButton saving={this.state.remissSending} 
                                        text={lang.Text('task.remiss.bounce.send')} pill={true}
                                        onSave={() => this.sendOther(revision)}/>
                                    </div>
                                    : <React.Fragment></React.Fragment>
                                }
                            </FormGroup>
                        </React.Fragment> 
                    )}
                </React.Fragment>}  
            </React.Fragment>   
        }
    }
    
    private renderRevision(task?: Api.TaskCore, remissConfig?: Api.TaskRevisionConfig) {
        if (task!=undefined) {
        
        let allowed: boolean = false;
        if (task.state!=1)
        {
            allowed = true;
        }

        return <React.Fragment>
            <LanguageContext.Consumer>
            { lang => ( <div>
                
                {this.state.remissLoading ? 
                    <Loading loading={true}/>
                :
                <React.Fragment>
                {allowed ? 
                    <div>
                    {this.state.remissSuccessMessage !=undefined ?
                    <Alert color="success">
                        <span className="iconic iconic-check"></span>     
                        <span>
                        {'  '}{this.state.remissSuccessMessage}
                        </span>
                    </Alert> 
                    : <React.Fragment>

                    <Alert color="primary">
                        <span className="iconic iconic-info"></span>     
                        <span>
                        {'  '} {lang.Text('task.remiss.info')}
                        </span>
                    </Alert>
                    <FormGroup>
                        <Label for="remissCustomSelect">{lang.Text('task.remiss.target')}</Label>
                        <CustomInput type="select" id="remissCustomSelect" name="remissSelect"
                            onChange={this.remissTargetChange.bind(this)}
                            >
                            <option value='none'>{lang.Text('task.remiss.choose')}</option>
                            {remissConfig !=undefined ? remissConfig.revisionTargets.map(target =>
                                <option value={target.userid} >
                                    {target.name} ({target.area})
                                </option>
                            ) : <React.Fragment></React.Fragment> }
                        </CustomInput>
                    </FormGroup>
                    <FormGroup className="top-spacing ">
                        {this.state.remissTarget!=undefined ?
                            <div className="pull-right">
                            <SaveButton saving={this.state.remissSending} 
                                text={lang.Text('task.remiss.send')} pill={true}
                                onSave={() => this.sendRemiss()}/>
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </FormGroup>
                
                    {this.state.remissErrorMessage !=undefined ?
                    <Alert color="danger">
                        <span className="iconic iconic-circle-x"></span>     
                        <span>
                        {'  '}{this.state.remissErrorMessage}
                        </span>
                    </Alert> 
                    : <React.Fragment></React.Fragment> }

                    </React.Fragment> }
                </div>
                :
                <Alert color="primary">
                 <span className="iconic iconic-info"></span>     
                 <span>
                 {'  '} {lang.Text('task.remiss.notallowed')}
                 </span>
                 </Alert>
                }</React.Fragment>
            }

            </div>)}
            </LanguageContext.Consumer>
            </React.Fragment>
        }
    }

    private renderChat() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>

        {/* {lang.Text('id.text')} */}
        {/* <Assistant backplaneUrl="/assistant" fixed={true} ></Assistant> */}

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }

    private renderTags(task: Api.TaskCore) {
        return task.tags.map(tag => 
        <div key="" style={{ margin: '5px'}} className={'yc-tag btn btn-sm btn-' + tag.color} role="button" data-toggle="popover" data-trigger="focus" 
        title={tag.desc} data-content={tag.data} onClick={() => this.showTag(tag.link)}>
            <span style={{fontSize: '9px'}}>{tag.desc}</span><br/>
            {tag.title}
            </div>);
    }


    private renderAlerts(task: Api.TaskCore) {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            {task.mediaRefs.length > 0 ? 
                <div style={{marginLeft: '10px'}}>
                    <hr/>
                    <TaskMedia task={task} searchCmd='media.open' removeCmd='taskedit.removemedia'
                        renderNav={true} 
                        renderTools={true}
                        renderDirection={false}
                    />
                    <hr/>
                </div>
            :
            <Alert color="primary">
                <span className="iconic iconic-info"></span> {lang.Text('task.alerts.nomedia')}{' '}
                <Button color="link" className="yc-link-btn" onClick={this.openSearch}>{lang.Text('task.selectmedia')}</Button>
            </Alert>
            }
            {/* <Alert color="warning">
                <span className="iconic iconic-warning"></span>     Frågan finns publicerad i testversion till provsystemet.
            </Alert> */}

        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>
    }


    private renderReferences(task: Api.TaskCore) {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div style={{marginLeft: '10px'}}>
        
            <InfoLink title={lang.Text('taskedit.purpose')} keyword={lang.Text('taskedit.purpose')}></InfoLink>                    
            
            <DraftEditor onChange={this.oneditorChange} id="reason_edit" 
                dataId="task.reason" text={task.reason} readOnly={this.state.readonly}
                placeHolder={lang.Text('task.reason.placeholder')}/>                
           
            <hr/>

            <InfoLink title={lang.Text('taskedit.classification')} keyword={lang.Text('taskedit.classification')}></InfoLink>
            <DraftEditor onChange={this.oneditorChange} id="reference_edit" 
                        dataId="task.responsible" text={task.responsible} readOnly={this.state.readonly}
                        placeHolder={lang.Text('task.classification.placeholder')}/>
            <hr/>
            <p className="small" style={{color: 'lightgrey'}}>{task.copyRightInfo?.copyrightHolder}</p>       
            
        </div>)}
        </LanguageContext.Consumer>
        </React.Fragment>

    }

    private renderMain(task: Api.TaskCore) {
       return <React.Fragment>
       <LanguageContext.Consumer>
       { lang => ( <div className="card" style={{border: '0'}} >
            <div 
            // className="card-header edit-card"
            >
                <Row style={{borderBottomColor: 'whitesmoke',
                    borderBottomStyle: 'solid', borderBottomWidth: '1px' }}>
                    <Col sm='8'>
                        <h3 >
                        <DraftEditor onChange={this.ontextChange} textOnly={true} 
                        id="title_edit" dataId="task.title" text={task.title}
                        readOnly={this.state.readonly}/>
                        </h3>
                    </Col>
                    <Col>
                        {lang.Text('task.edit.timestamp')} {': '}
                        <Moment format="YYYY-MM-DD HH:mm"
                            date={task.timeStamp}/> 
                    </Col>
                </Row>
                <Row style={{backgroundColor: 'white',borderBottomColor: 'whitesmoke',
                    borderBottomStyle: 'solid', borderBottomWidth: '1px' }}>
                    <Col sm='12' style={{paddingBottom: '10px', paddingTop: '10px'}}>
                        <DraftEditor onChange={this.oneditorChange} 
                            textOnly={false} id="description_edit"
                            dataId="task.description" 
                            text={task.description}
                            readOnly={this.state.readonly}
                            placeHolder={lang.Text('task.desc.placeholder')}/>
                    </Col>
                </Row>

                {/* <Row style={{backgroundColor: 'white',borderBottomColor: 'lightgrey',
                    borderBottomStyle: 'solid', borderBottomWidth: '1px' }}>
                    <Col sm='12' style={{paddingBottom: '10px', paddingTop: '10px'}}>
                        <DraftEditor textOnly={false} id="competence_edit" text={'#Kompetenskrav1 #Kompetenskrav2 #Kompetenskrav3'}/>
                    </Col>
                </Row> */}
                
                <Row style={{paddingTop:'10px', borderBottomColor: 'lightgrey',
                    borderBottomStyle: 'solid', borderBottomWidth: '1px' }}>
                    {/* <Col md='3'><h5 className="card-title">Frågestam</h5></Col> */}
                    <Col md='12' style={{marginLeft: '-5px'}}>
                    { this.renderTags(task) }
                    
                    {/* <Button className='btn-outline-dark btn-sm navbar-margin' onClick={() => this.openStatus()}>
                        <span className="iconic iconic-fork" title="status" aria-hidden="true">
                        </span>
                        {' '}{lang.Text('task.button.state')}
                    </Button> */}
                    {/*
                    <Button className='btn-outline-dark btn-sm navbar-margin' onClick={() => this.openCollab()}>
                        <span className="iconic iconic-chat" title="collaboration" aria-hidden="true">
                        </span>
                        {' '}Samarbete
                    </Button>*/}
                    {/* <Button className='btn-outline-dark btn-sm navbar-margin' onClick={() => this.openRemiss()}
                        disabled={this.state.readonly}>
                        <span className="iconic iconic-comment-square" title="remiss" aria-hidden="true">
                        </span>
                        {' '}{lang.Text('task.button.referral')}
                    </Button> */}
                    {/* <Button className='btn-outline-dark btn-sm navbar-margin' onClick={() => this.openExpert()}
                        disabled={this.state.readonly}>
                        <span className="iconic iconic-lightbulb-alt-on" title="expert" aria-hidden="true">
                        </span>
                        {' '}{lang.Text('task.button.expert')}
                    </Button> */}
                    {/* <Button className='btn-outline-dark btn-sm navbar-margin' onClick={() => this.openPreview()}>
                        <span className="iconic iconic-browser-viewport" title="preview" aria-hidden="true">
                        </span>
                        {' '}{lang.Text('task.button.preview')}
                    </Button> */}
                    {/* <Button className='btn-outline-dark btn-sm navbar-margin' onClick={() => this.openHistory()}>
                        <span className="iconic iconic-clock" title="historik" aria-hidden="true">
                        </span>
                        {' '}{lang.Text('task.button.history')}
                    </Button> */}
                    {/* <Button disabled className='btn-outline-dark btn-sm navbar-margin' onClick={() => this.openSettings()}>
                        <span className="iconic iconic-settings" title="settings" aria-hidden="true">
                        </span>
                        {' '}Inställningar
                    </Button>  */}
                    {/* {this.state.isDirty ? 
                        <span style={{marginLeft: '5px',marginTop: '5px'}}>
                            <SaveButton text={lang.Text('button.save')} saving={this.state.saving} onSave={() => this.saveChanges()}></SaveButton>
                        </span>
                    :<div></div> 
                    } */}
                    </Col>
                </Row>
            </div>
            <div className="card-body">
                <div className="card-text" >
                    <div style={{marginLeft: '-9px'}}>
                        <InfoLink title={lang.Text('task.question.info')} keyword={lang.Text('task.question.info')}></InfoLink>
                    </div>
                    <h5>
                        <DraftEditor onChange={this.ontextChange}
                            textOnly={false}
                            id="question_edit" dataId="task.question.text"
                            text={task.question.text} readOnly={this.state.readonly}
                            placeHolder={lang.Text('task.question.text.placeholder')}/>
                    </h5>
                </div>
            </div>
                
                <ul className="list-group list-group-flush" >
                    <li className="list-group-item" style={{backgroundColor: '#e9ffee'}}>
                        <div style={{marginLeft: '-9px'}}>
                            <InfoLink title={lang.Text('task.answer.info')} keyword={lang.Text('task.answer.info')}></InfoLink>
                        </div>
                        <DraftEditor onChange={this.ontextChange}
                            textOnly={false}
                            id="answer_edit" dataId="task.question.correctAnswer" text={task.question.correctAnswer}
                            readOnly={this.state.readonly}
                            placeHolder={lang.Text('task.question.correctanswer.placeholder')}/>
                    </li>
                    <li className="list-group-item">
                        <DraftEditor onChange={this.ontextChange}
                            textOnly={true}
                            id="answer_edit2" dataId="task.question.distractor1" text={task.question.distractor1}
                            readOnly={this.state.readonly}
                            placeHolder={lang.Text('task.question.distractor1.placeholder')}/></li>
                    <li className="list-group-item">
                        <DraftEditor onChange={this.ontextChange}
                            textOnly={true}
                            id="answer_edit3" dataId="task.question.distractor2" text={task.question.distractor2}
                            readOnly={this.state.readonly}
                            placeHolder={lang.Text('task.question.distractor2.placeholder')}/></li>
                    <li className="list-group-item">
                        <DraftEditor onChange={this.ontextChange}
                            textOnly={true}
                            id="answer_edit4" dataId="task.question.distractor3" text={task.question.distractor3}
                            readOnly={this.state.readonly}
                            placeHolder={lang.Text('task.question.distractor3.placeholder')}/></li>
                </ul>
                </div>)}
            </LanguageContext.Consumer>
            </React.Fragment>
        }
       

        
        private renderMessages() {
            return <React.Fragment>
            <LanguageContext.Consumer>
            { lang => ( 
                this.state.readonly ? 
                    <Row>
                        <Col sm='12' md='12' style={{height: '35px'}}>
                        <Alert color='danger' style={{marginTop: '-8px'}}><span className="iconic iconic-warning"></span>
                          {' '} {lang.Text('taskedit.readonly.message')}</Alert>        
                        </Col>
                        </Row>
                :
                    <div className='d-none'></div>
                
            )}
            </LanguageContext.Consumer>
            </React.Fragment>
        }

        private renderBreadCrumbs(task: Api.TaskCore) {
            return <Col>
                <Breadcrumb style={{marginLeft: '-5px'}} listClassName="yc-breadcrumbs">
                    <BreadcrumbItem><a href="/organisation">{this.state.orgName}</a></BreadcrumbItem>
                    <BreadcrumbItem><a href="#">{this.state.worksetName}</a></BreadcrumbItem>
                    <BreadcrumbItem><Button className="btn-sm yc-btn-nopad" color="link" onClick={this.navBack}>{this.state.groupName}</Button></BreadcrumbItem>
                    {/*<BreadcrumbItem active>{task.title}</BreadcrumbItem>*/}
                </Breadcrumb> 
            </Col>
        }
}


import * as React from 'react';
import * as App from 'pubsub-js';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Alert,Label, FormGroup, Input, Row, Col, InputGroup, } from 'reactstrap';
import { DraftEditor, DraftEditorData } from '../../../components/drafteditor/DraftEditor';

import { Reference } from '../api/Reference';
import { SearchTasks } from '../components/SearchTasks';
import { LanguageContext } from '../../../backbone/Language';

import Moment from 'react-moment';

export interface ReferencePanelState {
    modal: boolean,
    loading: boolean,
    keyword: string,
    reference: Reference.ReferenceInfo | undefined,
    new_reference_text: string | undefined,
    new_public_value: boolean | undefined,
    new_document_links: string[] |undefined,
    currentUploadFile : File | undefined,
    message: string
}

export interface ReferencePanelProps {
    onClosed?: (()=> void);
    title: string;
    position: string;
    cancelButtonLabel?: string;
    okButtonLabel?: string;
}

export class ReferencePanel extends React.Component<ReferencePanelProps, ReferencePanelState> {
    constructor(props:ReferencePanelProps) {
        super(props);
        
        this.state = {
            modal: false,
            loading: true,
            keyword: '',
            reference: undefined,
            new_reference_text: undefined,
            new_public_value: undefined,
            new_document_links: undefined,
            currentUploadFile: undefined,
            message: ''
        };
        
        this.close = this.close.bind(this);
        this.editorSave = this.editorSave.bind(this);
        this.save = this.save.bind(this);
        this.checkboxChange = this.checkboxChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.uploadfile = this.uploadfile.bind(this);
    }


    public componentDidMount() {
        App.subscribe("ref.open",
            this.open.bind(this));
            
        App.subscribe("ref.reload",
            this.reload.bind(this));
    }

    public componentWillUnmount() {
        App.unsubscribe("ref.open");
        App.unsubscribe("ref.reload");
    }

    private open(id: string, ref: string) {
        this.setState({
            modal: true,
            loading: true,
            keyword: ref
        });
    
        App.publish("amplitude.event","Open Reference Panel");
        App.publish("intercom.event","Open ref: " + ref);

        //Load data
        fetch('api/reference/get?key=' + ref,
          {credentials: "same-origin"}
          )
          .then(response => response.json() as Promise<Reference.ReferenceInfo>)
          .then(data => {
             
            this.setState({reference: data, keyword: ref, loading: false, 
                currentUploadFile: undefined, message: ''});

          }).catch(reason => {
              this.setState({ loading: false });
          });
    }

    private reload() {
        this.setState({
            loading: true
        });
    
        //Load data
        fetch('api/reference/get?key=' + this.state.keyword,
          {credentials: "same-origin"}
          )
          .then(response => response.json() as Promise<Reference.ReferenceInfo>)
          .then(data => {
             
            this.setState({reference: data,  loading: false, 
                currentUploadFile: undefined, message: ''});

          }).catch(reason => {
              this.setState({ loading: false });
          });
    }

    private close() {
        this.setState({
            modal: false
        });
        if (this.props.onClosed!=null) this.props.onClosed();
    }

    private save() {
        //Save data

        if (this.state.reference!=null && this.state.new_reference_text!=undefined)
        {
            var data: Reference.ReferenceInfo = this.state.reference;
            data.text = this.state.new_reference_text; 

            if (this.state.new_public_value!=undefined) {
                data.isPublic = this.state.new_public_value;
            }

            fetch('/api/reference/save', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                credentials: 'same-origin'
            })
            .then(response => response.json() as Promise<Reference.ReferenceInfo>)
            .then(data => { 
                this.setState({reference: data, keyword: data.id, loading: false});
            })
            .catch(reason => {

            });
        }

        this.setState({
            modal: false
        });
    }

    private checkboxChange(value: any) {
        this.setState({ new_public_value: value})
    }

    private editorSave(id:string, data: DraftEditorData) {
        this.setState({ new_reference_text:  data.contentStateRaw})
    }

    private fileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if ( e.target.files!=undefined && e.target.files[0]!=undefined)
       {
            this.setState({currentUploadFile: e.target.files[0]});
       }
    }

    private uploadfile() {

        //e.preventDefault();

        var inputfile = this.state.currentUploadFile;
    
        if (inputfile!=undefined)
        {
            var formData = new FormData();
            formData.append(inputfile.name, inputfile);

            fetch('/api/files/uploadto?_ref=' + this.state.keyword 
                + '&reftype:reftype.reference', { 
                method: 'POST',
                headers: {
                'Accept': 'application/json'
                // 'Content-Type': 'multipart/form-data' //Boundary Error
                },
                body: formData,
                credentials: 'same-origin'
            }).then(response => {
                
                App.publish("ref.reload",{});

                //this.setState({currentUploadFile: undefined, message: 'Dokumentet laddades upp' });

            }).then(
                success => console.log(success) 
            ).catch(
                error => console.log(error) 
            );
        }
    
    }

    render() {
        return <React.Fragment>
        <LanguageContext.Consumer>
        { lang => ( <div>
            <Modal isOpen={this.state.modal} toggle={this.close} contentClassName="modal-panel-content"
                modalClassName={this.props.position} onClosed={this.props.onClosed}>
                    <ModalHeader className="youcap-navbar-panel" toggle={this.close}> { this.props.title }</ModalHeader>
                    <ModalBody style={{overflowY: 'auto', overflowX: 'hidden'}}>
                    <div style={{width: '500px'}}>
                        {this.state.loading ? 
                        <Alert color="primary">
                            {lang.Text('reference.loading')}
                        </Alert> :
                        <p></p>
                        }
                        {this.state.loading ? 
                        <div className="loading">
                            <p><img src="./img/_loading.gif"/><br/></p>
                        </div>
                        : 
                        <Form>
                            {this.state.reference !=undefined ?
                            <div>
                                <Alert color="primary">
                                {this.state.reference.isNew ? 
                                <React.Fragment>
                                   {lang.Text('reference.new')}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                     {lang.Text('reference.updated')} {' '}  
                                    <b>
                                        <Moment format="YYYY-MM-DD HH:mm"
                                            date={this.state.reference.changedTime}/> 
                                    </b> 
                                    {' '} {lang.Text('reference.updated.by')} <b>{this.state.reference.changeBy}</b>
                                </React.Fragment>
                                }
            
                                </Alert>
                                <FormGroup row>
                                       <Col>
                                       <h2 className=""
                                            style={{borderBottom: '1px solid lightgray',color: '#6c757d'
                                            ,paddingBottom: '4px'}}> 
                                            {'#' + this.state.keyword}  
                                        </h2>
                                       </Col>
                                </FormGroup>
                                {/* <FormGroup>
                                    <CustomCheckBox id='custom1' checked={this.state.reference.isPublic} 
                                        text="Detta är en tagg" 
                                        onChange={() =>this.checkboxChange}
                                        disabled={false}></CustomCheckBox>
                                </FormGroup> */}
                                <FormGroup style={{minHeight: '280px'}}>
                                    <DraftEditor id={'referensText'} text={this.state.reference.text} 
                                        textOnly={false} onChange={this.editorSave} dataId={'reftext'}
                                    />
                                </FormGroup>
                                {/* <hr/>
                                <FormGroup>
                                    <Label>{lang.Text('reference.documents')}</Label>
                                    {this.state.reference.documentLinks.map(doc =>
                                        <div className="col-5" 
                                            style={{textAlign: 'center'}}>
                                            <span className={"iconic iconic-md iconic-file-" + doc.documentType } 
                                                style={{color: 'text-secondary'}}
                                                title="file" aria-hidden="true"></span>
                                            <Button color="link" href={doc.url} target="_blank">{doc.title}</Button>
                                        </div>         
                                    )}
                                    
                                    <Button color="link">{lang.Text('reference.documents.add')}</Button>
                                </FormGroup> */}
                                <hr/>
                                <FormGroup>
                                    <SearchTasks searchText={this.state.keyword}/>
                                </FormGroup>
                                {/* <FormGroup>

                                    <InputGroup>
                                        <div className="custom-file">
                                            <Input type="file" className="custom-file-input" id="customFile"
                                                onChange={e => this.fileUpload(e)} />
                                            <Label className="custom-file-label" for="customFile">
                                                {this.state.currentUploadFile!=undefined ? 
                                                    this.state.currentUploadFile.name : 'Välj fil att ladda upp'}
                                            </Label>
                                        </div>
                                        <div className="input-group-append">
                                            <Button  
                                                color="secondary" className="btn-outline-dark"
                                                onClick={this.uploadfile}>{lang.Text('reference.upload')}
                                            </Button>
                                        </div>
                                    </InputGroup>
                                
                                </FormGroup> */}
                                
                            </div>
                            :
                            <Alert color="danger">
                                {lang.Text('reference.failed')}
                            </Alert>
                            }
                        </Form>
                        }
                        { this.props.children }
                    </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>{ this.props.okButtonLabel }</Button>{' '}
                        <Button color="link" onClick={this.close}>{ this.props.cancelButtonLabel }</Button>
                    </ModalFooter>
            </Modal>
            </div>)}
    </LanguageContext.Consumer>
    </React.Fragment>
    }
}

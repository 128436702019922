import * as React from 'react';
import { Link } from 'react-router-dom';
import * as App from 'pubsub-js';

import { Button, Container, Row, Col, CustomInput, UncontrolledCollapse, FormGroup, Label, Input, Form, NavItem } from 'reactstrap';
import { LanguageContext, Language } from '../../backbone/Language';
import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';

import { ProductLogo } from '../../components/ProductLogo';
import { Line } from 'react-chartjs-2';
import { InfoPanel } from '../../panels/InfoPanel';
import { ModalPanel } from '../../panels/ModalPanel';
import { CollapseList, CollapseListItem } from '../../components/CollapseList';
import { User } from '../../api/User';
import { Application } from '../../api/App';
import { AppCore } from '../../backbone/AppCore';
import { Loading } from '../../components/base/Loading';

import {SessionApi} from './api/SessionApi';

import moment from "moment";
import Moment from 'react-moment';

import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import { Customer } from '../../api/Customer';

export interface SessionModuleState {
  loading: boolean
  loadingInstructor: boolean
  loadingHistory: boolean
  loadingDetails: boolean
  noData: boolean
  text: string
  title: string
  isDirty: boolean
  user: User.UserProfile
  app: Application.AppProfile
  customer?: Customer,
  events: SessionApi.Session[]
  history?: SessionApi.Session[]
  instructors: SessionApi.Instructor[]
  currentEvent?: SessionApi.Session
  newEvent?: SessionApi.Session
}

export interface ComponentOnChangeEvent {
  message: string
}

export interface SessionModuleProps {
  title?: string
  text?: string
  onChange?: ((state: ComponentOnChangeEvent) => void)
}

export class SessionModule extends React.Component<SessionModuleProps, SessionModuleState> {
  static displayName = 'SessionModule';
  static contextType = LanguageContext;
  static moduleId = "youcap.io.SessionModule";
  constructor(props: SessionModuleProps) {
    super(props);

    let _title = props.title != undefined ? props.title : '';
    let _text = props.text != undefined ? props.text : '';

    this.state = {
      title: _title,
      isDirty: false,
      noData: false,
      text: _text,
      loading: true,
      loadingInstructor: true,
      loadingHistory: true,
      loadingDetails: false,
      user: AppCore.GetUserProfile(),
      app: AppCore.GetAppProfile(),
      events: [],
      instructors: []
    };

    this.renderModule = this.renderModule.bind(this);
    this.onSessionSave = this.onSessionSave.bind(this);
    this.getInstructors = this.getInstructors.bind(this);
  }

  componentDidMount() {
    this.getCustomer();
    this.getEvents();
    this.getInstructors();

    App.subscribe("reload.instructors",this.getInstructors);
    
  }
  
  componentWillUnmount() {
    App.unsubscribe("reload.instructors");
  }

  private getCustomer() {
    fetch('api/user/currentcustomer', GlobalAppsettings.GetRequestInit())
      .then(response => response.json() as Promise<Customer>)
      .then(result => {
        console.info(result);

        this.setState({loading: false, customer: result});
    
      }).catch(reason => {
          //Track Exception
          App.publish('track-exception', {error: reason});
          this.setState({loading: false});
      });
  }

  private getInstructors() {
    this.setState({loadingInstructor: true});
    
    fetch('/api/securetestapi/GetInstructors', GlobalAppsettings.GetRequestInit())
    .then(response => response.json() as Promise<SessionApi.Instructors>)
    .then(data => {
      
      if (data.success)
       {
        this.setState({loading: false, instructors: data.data}); 
       }
       else
       {
         console.error(data.errorMessage);
       }

    }).catch(reason => {
        this.setState({loading: false});  
        console.error(reason);
        //Track Exception
        App.publish('track-exception', {error: reason});
    });
  }

  private getEvents()
  {
    this.setState({loading: true}); 
    
    fetch('/api/securetestapi/GetEventSessions', GlobalAppsettings.GetRequestInit())
    .then(response => response.json() as Promise<SessionApi.Sessions>)
    .then(data => {
      
      if (data.success)
       {
        var events = data.data;
        events.forEach(item => {
          item.isNew = false;
        });
        this.setState({loading: false, events: events}); 
       }
       else
       {
         console.error(data.errorMessage);
         this.setState({loading: false}); 
       }

    }).catch(reason => {
        this.setState({loading: false});  
        console.error(reason);
        //Track Exception
        App.publish('track-exception', {error: reason});
    });
  }

  private getHistory()
  {
    this.setState({loadingHistory: true}); 
    
    fetch('/api/securetestapi/GetEventSessionHistory', GlobalAppsettings.GetRequestInit())
    .then(response => response.json() as Promise<SessionApi.Sessions>)
    .then(data => {
      
      if (data.success)
       {
        var events = data.data;
        events.forEach(item => {
          item.isNew = false;
        });
        this.setState({loadingHistory: false, history: events}); 
       }
       else
       {
         console.error(data.errorMessage);
         this.setState({loadingHistory: false}); 
       }

    }).catch(reason => {
        this.setState({loadingHistory: false});  
        console.error(reason);
    });
  }

  private onSessionSave() {
    
    var save = this.state.currentEvent;
    if (save===undefined)
    {
        save = this.state.newEvent;
    }

    if (save!==undefined)
    {
      this.setState({loading: true});
      const postData =GlobalAppsettings.GetPostInit(save);

      fetch('/api/securetestapi/SaveEventSession', postData)
      .then(response => response.json() as Promise<SessionApi.SaveSession>)
      .then(data => {
          if (data.success)
          {
            this.setState({loading: false, isDirty: false, currentEvent: undefined, newEvent: undefined});
            this.getEvents(); //reload
          }
          else
          {
            console.error(data.errorMessage);
          }

      }).catch(reason => {

          this.setState({loading: false});
      });
    }
    
  }

  private onCurrentChange(field: string, event: any): void {
    if (this.state.currentEvent!==undefined)
    {
        var update = this.state.currentEvent;
        switch(field)
        {
          case 'title':
            update.title = event.target.value;
            break;
          case 'category':
              update.category = event.target.value;
              let exam = this.state.customer?.exams[event.target.selectedIndex];
              if (exam!==undefined) {
                update.examId= exam?.Key;
                update.examName= exam?.Value;
              }
              break;
          case 'info':
              update.info = event.target.value;
              break;
          case 'date':
              update.eventDate = event.target.value;
              break;
          case 'instructor':
              let instructor = this.state.instructors[event.target.selectedIndex];  
              update.instructorId =  instructor.id;
              update.instructorName = instructor.name;
              console.info(instructor);
              break;
          case 'active':
                update.isActive = event;
                break;
        }
        this.setState({currentEvent: update, isDirty: true});
    } 
  }

  private newEvent()
  {
    if (this.state.customer!==undefined)
    this.setState({newEvent: {
      eventDate: moment(Date()).format(),
      info: '',
      instructorId: this.state.instructors[0].id,
      instructorName: this.state.instructors[0].name,
      title: 'Kurs',
      category: this.state.customer?.exams[0].Value,
      customerId: '',
      instructions: '',
      isActive: true,
      participants: [],
      url: '',
      eventDateString: '',
      eventTimeString: '',
      isNew: true,
      examId: this.state.customer?.exams[0].Key,
      examName: this.state.customer?.exams[0].Value
    }});
    App.publish("new.session", null);
  }

  private onNewChange(field: string, event: any): void {
    if (this.state.newEvent!==undefined)
    {
        var update = this.state.newEvent;
        switch(field)
        {
          case 'title':
            update.title = event.target.value;
            break;
          case 'category':
              update.category = event.target.value;
              let exam = this.state.customer?.exams[event.target.selectedIndex];
              if (exam!==undefined) {
                update.examId= exam?.Key;
                update.examName= exam?.Value;
              }
              break;
          case 'info':
              update.info = event.target.value;
              break;
          case 'date':
              update.eventDate = event.target.value;
              break;
          case 'instructor':
              update.instructorName = event.target.value;
              break;
        }
        this.setState({newEvent: update, isDirty: true});
    } 
  }

  render() {
    return <React.Fragment>
      <LanguageContext.Consumer>
        {lang => (<div>
          <Row style={{ marginBottom: '25px' }}>
            <Col>
              <div className="yc-module rounded " style={{ color: 'black', padding: '20px' }}>
                <Row>
                  <Col>
                    <ProductLogo type="black" title="Tillfällen"></ProductLogo>
                  </Col>
                </Row>
                <Row>
                  <Col className="">
                    <Loading loading={this.state.loading}>
                      {this.state.noData ? <p className="no-data text-center">
                        <i className="fa fa-umbrella-beach" />{'   '} {lang.Text('no.data')}</p>
                        : this.renderModule(lang)}
                    </Loading>
                  </Col>
                </Row>
                
              </div>
            </Col>
          </Row>

          <ModalPanel title={lang.Text('workset settings')}
            modalPanelOpen="view.session"
            position="modal-panel-right"
            okButtonLabel={lang.Text('btn.save')}
            cancelButtonLabel={lang.Text('btn.cancel')}
            leaveButtonLabel={lang.Text('btn.leave')}
            unsavedChangesText={lang.Text('modal.unsaved.changes')}
            isDirty = {this.state.isDirty}
            onSave = {this.onSessionSave}
            showHeader={false}>
            <div className="yc-panel-md">
              <Container fluid>
                {this.renderEvent(lang, this.state.currentEvent)}
              </Container>
            </div>
          </ModalPanel>

          <ModalPanel title="Nytt tillfälle"
            okButtonLabel={lang.Text('btn.save')}
            cancelButtonLabel={lang.Text('btn.cancel')}
            leaveButtonLabel={lang.Text('btn.leave')}
            unsavedChangesText={lang.Text('modal.unsaved.changes')}
            isDirty={true}
            // showHeader={false}
            modalPanelOpen="new.session"
            onSave = {this.onSessionSave}
            position="modal-panel-right">
            <div className="yc-panel-md">
              <Container>
                {this.renderNewEvent(lang)}
              </Container>
            </div>
          </ModalPanel>

          <InfoPanel title="Länk till prov"
            cancelButtonLabel={lang.Text('btn.cancel')}
            modalPanelOpen="open.link"
            position="modal-panel-right">
            <div className="yc-panel-md" style={{width: '800px'}}>
              <Container className='text-center'>
              <Row>
                <Button color="link">
                  {/* {this.state.currentEvent &&
                     <QRCodeSVG value={this.state.currentEvent?.url} size={450}
                    />} */}
                  {this.state.currentEvent &&
                    <QRCodeCanvas value={this.state.currentEvent?.url} size={350}
                    />}
                </Button>
              </Row>
              <Row>
                <Button color="link" onClick={()=> window.print()}>Skriv ut QR kod</Button>
              </Row>
              <Row>
                <Button color="link" onClick={() => this.copyLinktoClipboard(this.state.currentEvent?.url)}>
                                <i style={{fontSize: '17px'}} className="fa fa-external-link-alt "/> {lang.Text("copy.link")}
                </Button>
              </Row>
              <Row>
                <Button color="link" onClick={() => this.openSessionLink(this.state.currentEvent?.url)}>
                  Länk
                </Button>
              </Row>
              </Container>
            </div>
          </InfoPanel>

          <InfoPanel title="Historik"
            cancelButtonLabel={lang.Text('btn.cancel')}
            modalPanelOpen="view.history"
            position="modal-panel-right">
            <div className="yc-panel-md" style={{width: '800px'}}>
              <Container className='p-2'>
                {this.renderHistory(lang)}
              </Container>
            </div>
          </InfoPanel>

          <InfoPanel title={""}
            header={false}
            cancelButtonLabel={lang.Text('btn.cancel')}
            modalPanelOpen="open.session"
            position="modal-panel-right">
            <div className="yc-panel-md">
              <Container className='text-center'>
                {this.renderEventWithParticipants(this.state.currentEvent, lang)}
              </Container>
            </div>
          </InfoPanel>

        </div>)}
      </LanguageContext.Consumer>
    </React.Fragment>
  }
  renderEventWithParticipants(currentEvent: SessionApi.Session | undefined, lang: Language) {
    if (currentEvent!==undefined)
    return (
      <Loading loading={this.state.loadingDetails}>
      <Container className=''>
      <Row className='text-left'>
        <Col ><h5>{currentEvent.category} {' '} {currentEvent.title}</h5> <br/>{currentEvent.info}</Col>
        <Col className='font-weight-bold'>{moment(currentEvent.eventDate).format('YYYY-MM-DD')}</Col>
      </Row>
      <Row>
        <Col></Col>
        <Col  className='p-2 d-flex flex-row-reverse'>
          <Button color="secondary" outline
          // onClick={() => }
          >Uppdatera listan</Button>
        </Col>
      </Row>
      <hr/>
      <Row className='text-left' style={{minHeight: '250px'}}>
        <CollapseList id="sessionItems" style={{width: '100%'}}
          items={this.getSessionParticipants(currentEvent.participants, lang)} lang={lang}/>
      </Row>
      <hr/>
      <Row>
      <Col className="p-2 d-flex">
          {currentEvent.isActive ?
            <Button color="danger" size='xs' onClick={() => {
              this.onCurrentChange('active', false);
            }}>Avaktivera prov</Button>
            :<Button color="success" size='xs' onClick={() => {
              this.onCurrentChange('active', true);
            }}>Aktivera prov</Button>
          }
        </Col>
        <Col  className='p-2 d-flex flex-row-reverse'>
          <Button color="secondary" outline
          // onClick={() => }
          >Exportera till Excel</Button>
        </Col>
      </Row>
      <Row className="mt-3">
                  
                </Row>
      </Container>
      </Loading>
    )
  }

  private getSessionParticipants(participants: SessionApi.Participant[], lang: Language): CollapseListItem[] {
    console.info(participants);

    var list: CollapseListItem[] = new Array<CollapseListItem>();

    participants.forEach((item) => {
      list.push(
        {
          title: <div className='d-flex flex-row' style={{width: '100%'}}>
            <div><strong>{item.firstName} {' '} {item.lastName}</strong>
            </div>
            <div  className='d-flex flex-row-reverse'>
              {item.result.passed ?
              <i style={{color: 'green'}} className='ml-4 fa fa-circle-check'></i>
              :
              <i style={{color: 'indianred'}} className='ml-4 fa fa-circle-xmark'></i>
              }
             </div>
          </div>,
          content: <>
            <p className='p-2'><b>Datum:</b> {item.testDate}</p>
            <p className='p-2'><b>Antal rätt:</b> {item.result.score}</p>
            
            <Button color="link" 
                href={'https://securetest-stage.azurewebsites.net/result?' + item.sessionId} 
            >Visa resultat</Button>
            <Button color="link" onClick={() => this.copyLinktoClipboard('https://securetest-stage.azurewebsites.net/result?' + item.sessionId)}>
                                <i style={{fontSize: '17px'}} className="fa fa-external-link-alt "/>Kopiera länk till resultat-sida
                        </Button>
            
            <p className='p-2'><b>ID06-medgivande:</b> {item.consent ? 'Ja': 'Nej'}</p>
            <p className='p-2'><b>Exporterat till ID06:</b> {item.isResultExported ? 'Ja': 'Nej'}</p>
            
            <p className='p-2'><b>Epost:</b> {item.email}</p>
          
          </>
        }
      );
    });

    return list;
  }

  private getEventLinks(links: SessionApi.Session[], lang: Language): CollapseListItem[] {

    var list: CollapseListItem[] = new Array<CollapseListItem>();

    links.forEach((item) => {
      // if (item.isActive)
      list.push(
        {
          title: <div style={{width: '100%'}} className="">
            <strong>{item.category} : {item.title}</strong>
            <div className='float-right'>{moment(item.eventDate).format('YYYY-MM-DD')}</div>
          </div>,
          isOpen: true,
          content: <>
            <p className="mb-1">{item.info} </p>
            {/* <p className="mb-1">{item.type}</p> */}
            <p className="mb-1">{item.instructorName} </p>
            <Button color="link" onClick={() =>  this.viewQR(item)}>{lang.Text('link.open.url')} {' '}<i className="fa fa-external-link-alt" /></Button>
            <Button color="link" onClick={() => {
              this.setState({currentEvent: item})
              App.publish('open.session',null);
            }}>Visa deltagare</Button>
                  
            <small></small>
            {/* {this.state.user.isAdmin && */}
            <Button color="link" className="float-right" onClick={() => this.view(item)} >Ändra
            </Button>
            {/* } */}
            
          </>,
        }
      );
    });

    return list;
  }

  private getHistoryEventLinks(links: SessionApi.Session[], lang: Language): CollapseListItem[] {

    var list: CollapseListItem[] = new Array<CollapseListItem>();

    links.forEach((item) => {
      // if (item.isActive)
      list.push(
        {
          title: <div style={{width: '100%'}} className="">
            <strong>{item.category} : {item.title}</strong>
            <div className='float-right'>{moment(item.eventDate).format('YYYY-MM-DD')}</div>
          </div>,
          isOpen: true,
          content: <>
            <p className="mb-1">{item.info} </p>
            {/* <p className="mb-1">{item.type}</p> */}
            <p className="mb-1">{item.instructorName} </p>
            {/* <Button color="link" onClick={() =>  this.viewQR(item)}>{lang.Text('link.open.url')} {' '}<i className="fa fa-external-link-alt" /></Button> */}
            <Button color="link" onClick={() => {
              this.setState({currentEvent: item})
              App.publish('open.session',null);
            }}>Visa deltagare</Button>
                  
            <small></small>
            {/* {this.state.user.isAdmin && */}
            {/* <Button color="link" className="float-right" onClick={() => this.view(item)} >Ändra
            </Button> */}
            {/* } */}
          </>,
        }
      );
    });

    return list;
  }


  renderHistory(lang: Language) {
    return (
      <>
      <Loading loading={this.state.loadingHistory}>
      {this.state.history?
      <Row >
        <Col>
          <CollapseList id="sessionItems" items={this.getHistoryEventLinks(this.state.history, lang)} lang={lang}/>
        </Col>
      </Row>: <></>}
      </Loading>
      </>
    )
  }

  openSessionLink(url: string | undefined): void {
      window.open(url);
  }

  private copyLinktoClipboard(text?: string) {
    if (!navigator.clipboard) {
        return;
    }
    if (text!==undefined)
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {
    });
  }

  renderNewEvent(lang: Language){
    if (this.state.newEvent!==undefined)
    return (
      <>
     <Row>
      <Col>
        <Form>
        <FormGroup>
            <Label for="exampleSelectMulti">
              Välj certifieringstest
            </Label>
            <Input
              id="exampleSelectMulti"
              name="selectMulti"
              type="select"
              onChange={(event: any) => this.onNewChange('category', event) }
              value={this.state.newEvent?.category}
            >
              {this.state.customer?.exams.map((item,key) => {
                return (<option itemID={item.Key} key={key}>
                  {item.Value}
                </option>
                )
              })}
              
            </Input>
          </FormGroup>
         
          <FormGroup>
            <Label for="new-session-title">
              Titel
            </Label>
            <Input
              id="new-session-title"
              name="title"
              placeholder=""
              type="text"
              onChange={(event: any) => this.onNewChange('title', event) }
              value={this.state.newEvent?.title}
            />
          </FormGroup>
          <FormGroup>
            <Label for="new-session-place">
              Plats / lokal och Ort
            </Label>
            <Input
              id="new-session-place"
              name="text"
              placeholder=""
              type="text"
              onChange={(event: any) => this.onNewChange('info', event) }
              value={this.state.newEvent?.info}
            />
          </FormGroup>
          <FormGroup>
            <Label for="new-session-date">
              Datum för prov
            </Label>
            <Input
              id="new-session-date"
              name="date"
              placeholder=""
              type="date"
              onChange={(event: any) => this.onNewChange('date', event) }
              value={this.state.newEvent?.eventDate}
            />
          </FormGroup>
       
          <FormGroup>
            <Label for="new-session-instructor">
              Välj instruktör
            </Label>
            <Input
              id="new-session-instructor"
              name="select"
              type="select"
              value={this.state.newEvent.instructorName}
              onChange={(event) => this.onNewChange('instructor',event)}
            >
              {this.state.instructors.map((item) => {
                return(
                    <option key={item.id}>{item.name}</option>
                );
              })}
            </Input>
          </FormGroup>
         
        </Form>
      </Col>
    </Row>
    </>
    );
  }
  
  
  renderEvent(lang: Language, current?: SessionApi.Session) {
    if (current!==undefined)
    return (
      <>
      <Row className="mt-1">
                  <Col>
                    <h5>{current.category} : {current.title}</h5>
                  </Col>
                  <Col>
                    <h6></h6>
                  </Col>
                </Row>
                <FormGroup>
            <Label for="exampleSelectMulti">
              Certifieringstest
            </Label>
            <Input
              id="exampleSelectMulti"
              name="selectMulti"
              type="select"
              value={current.category}
              onChange={(event: any) => this.onCurrentChange('category', event) }
            >
              {this.state.customer?.exams.map((item,key) => {
                return (<option itemID={item.Key} key={key}>
                  {item.Value}
                </option>
                )
              })}
            </Input>
          </FormGroup>
                <Row className="mt-3">
                  <Col>
                    <Label for="examplePassword">
                      Information (t.ex. plats/lokal och Ort)
                    </Label>
                    <Input
                      id="examplePassword"
                      name="password"
                      placeholder=""
                      type="text"
                      onChange={(event: any) => this.onCurrentChange('info', event) }
                      value={current.info}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <FormGroup>

                    </FormGroup>
                    <FormGroup>
                      <Label for="event-date">
                        Datum för prov
                      </Label>
                      <Input
                        id="event-date"
                        name="event-date"
                        placeholder=""
                        type="date"                        
                        onChange={(event: any) => this.onCurrentChange('date', event) }
                        value = {current.eventDate}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="selecinstructor">
                        Välj instruktör
                      </Label>
                      <Input
                        id="selecinstructor"
                        name="select"
                        type="select"
                        value={current.instructorName}
                        onChange={(event: any) => this.onCurrentChange('instructor', event) }
                      >
                        {this.state.instructors.map((item) => {
                          return(
                              <option key={item.id} itemID={item.id}>{item.name}</option>
                          );
                        })}
                      </Input>
                    </FormGroup>

                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <Button color="link" onClick={() => {
                        // this.setState({currentEvent: item})
                        App.publish('open.session',null);
                      }}>Visa deltagare</Button>
                  </Col>
                </Row>
                {/* <Row className="mt-3">
                  <Col>
                    {current.isActive ?
                      <Button color="danger" size='xs' onClick={() => {
                        this.onCurrentChange('active', false);
                      }}>Avaktivera prov</Button>
                      :<Button color="success" size='xs' onClick={() => {
                        this.onCurrentChange('active', true);
                      }}>Aktivera prov</Button>
                    }
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col>
                    <CollapseList id="sessionPartList" items={this.getSessionUsers(current.participants, lang)}  lang={lang}/>
                  </Col>
                </Row> */}
              </>);
  }

  renderModule(lang: Language) {

    return (<React.Fragment>

      <Row >
        <Col>
          <CollapseList id="sessionItems" items={this.getEventLinks(this.state.events, lang)} lang={lang}/>
        </Col>
      </Row>

      <Row style={{ marginTop: '10px' }}>
      <Col>
        <Button color="link" onClick={() => 
          {
            this.getHistory();
            App.publish("view.history",null);
          }
          }>{lang.Text("Visa Historik")}</Button>
      </Col>
    </Row>

      {/* {this.state.user.isAdmin && */}
      <Row style={{ marginTop: '10px' }}>
        <Col>
          <Button color="dark" outline className="btn--pill"
            onClick={() => this.newEvent()}>
            <i className="fas fa-plus" style={{ marginRight: '5px' }}></i>
            {lang.Text('add.session')}</Button>
        </Col>

      </Row>
      {/* } */}

    </React.Fragment>
    );
  }

 

  private view(item: SessionApi.Session) {
    
    console.info(item);

    item.eventDate = moment(item.eventDate).format("YYYY-MM-DD");

    if (item.exams!==undefined && item.exams.length>0) {
      item.examId = item.exams[0].id;
      item.examName = item.exams[0].name;
    } 
    
    this.setState({currentEvent: item});
    App.publish("view.session", null);

  }

  

  viewQR(item: SessionApi.Session): void {
    
    this.setState({currentEvent: item});  
    App.publish("open.link", {url: item.url});
  }

  

}

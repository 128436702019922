import * as React from 'react';
import * as App from 'pubsub-js';

import { Button, Fade } from 'reactstrap';

export interface SaveButtonState {
    text: string,
    title: string
}

export interface SaveButtonProps {
    title?: string,
    text?: string,
    saving: boolean,
    small?: boolean,
    pill?: boolean,
    onSave?: (()=> void)
}

export class SaveButton extends React.Component<SaveButtonProps, SaveButtonState> {
    constructor(props:SaveButtonProps) {
        super(props);
        
        let _title = props.title!=undefined ? props.title: '';
        let _text= props.text!=undefined ? props.text: '';

        this.state = {
            title: _title,
            text: _text
        };
    }

    private onClick() {

        if (this.props.onSave!=undefined) this.props.onSave();
    }

    render() {
        return <Button color="primary" title={this.state.title}
                className={this.props.small ? 'btn-sm navbar-margin' : 'navbar-margin' 
                + this.props.pill ? 'btn-pill' : ''}  style={{minWidth: '65px'}} onClick={() => this.onClick()}>
                {this.props.saving ? 
                    // <span className="spinner-240"></span>
                    <React.Fragment>
                        <div className="spinner-grow-p1 text-light" 
                        role="status">
                        </div>
                        <div className="spinner-grow-p2 text-light" 
                        role="status">
                        </div>
                        <div className="spinner-grow-p3 text-light" 
                        role="status">
                        </div>
                    </React.Fragment>
                    : 
                    <span>{this.state.text}</span>
                }
            </Button>
    }
}

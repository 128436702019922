import * as React from 'react';
import * as App from 'pubsub-js';

import './drafteditor.css';

import {Button, Col, Row, Popover, PopoverHeader, PopoverBody, Alert, Form, FormGroup, Label, Input} from 'reactstrap';

import {Editor, EditorState, ContentState, convertFromRaw,
    convertToRaw, RawDraftContentState,
    convertFromHTML,
    ContentBlock,
    RichUtils,
    CompositeDecorator,
    SelectionState} from 'draft-js';

// import { Analytics } from '../api/Analytics';


export interface DraftEditorProps {
  id: string;
  readOnly?: boolean;
  textOnly?: boolean;
  text?: string;
  data?: string;
  onChange?: ((item: any, data: DraftEditorData)=> void);
  onSave?: ((data: DraftEditorData)=> void);
  dataId?: string;
  placeHolder?: string;
  analytics?: boolean;
}

interface DraftEditorState {
  readOnly: boolean;
  loading: boolean;
  editorState: EditorState;
  hasFocus: boolean;
  showToolbar: boolean;
  textOnly: boolean;
  currentSelection: string;
}

export interface DraftEditorData {
  id?: string;
  contentStateText?: string;
  contentStateRaw?: string;
}

interface DraftEditorLeafProps {
  text: string | undefined;
}

interface DraftEditorLeaf {
  props: DraftEditorLeafProps | undefined; 
}
/**
 * Component that wraps draft-js editor with custom strategys for references and mentions
 * integration with textanalytics panel
 * 
 */
export class DraftEditor extends React.Component<DraftEditorProps, DraftEditorState> {
  
  private readonly HandleSpan: (p: any) => JSX.Element;
  private readonly HandleHttp: (p: any) => JSX.Element;
  private readonly HandleMention: (p: any) => JSX.Element;
  private readonly compositeDecorator: CompositeDecorator;
  
  private _loaded: boolean;
  private _dirty: boolean;
  private _editor: Editor | null;

  constructor(properties: any) {
        
        super(properties);
        
        this._loaded = false;
        this._dirty = false;
        this._editor = null;

        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
        this.editfocus = this.editfocus.bind(this);
        this.editBlur = this.editBlur.bind(this);
        this.onBoldBtn = this.onBoldBtn.bind(this);
        this.onItalicBtn = this.onItalicBtn.bind(this);
        this.onUnderlineBtn = this.onUnderlineBtn.bind(this);
        this.onLinkBtn = this.onLinkBtn.bind(this);
        this.onSettingsBtn = this.onSettingsBtn.bind(this);
        this.getBlockStyle = this.getBlockStyle.bind(this);
        this.findWithRegex = this.findWithRegex.bind(this);
        this.hashtagStrategy = this.hashtagStrategy.bind(this);
        this.httpStrategy = this.httpStrategy.bind(this);
        this.mentionStrategy = this.mentionStrategy.bind(this);

        this.receiveData = this.receiveData.bind(this);

        this.openRef = this.openRef.bind(this);

        // this.HandleSpan = (p) => {
        //   return  <span {...p} className='drafteditor-tag'>
        //   <Button className="btn-sm btn-info" onClick={() =>this.openRef(p.children)}>
        //   {p.children}
        //   </Button>
        //   </span>;
        // };

        this.HandleSpan = (p) => {
          return  <span {...p} >
          <Button color="link" onClick={() =>this.openRef(p.children)} 
              className="drafteditor-ref-btn">
            {p.children}
          </Button>
          </span>;
        };

        
        this.HandleHttp = (p) => {
          return  <span {...p} >
          <Button className="btn-sm btn-info btn-link" href={p.children}>
            {p.children}
          </Button>
          </span>;
        };

        this.HandleMention = (p) => {
          return  <span {...p} className='drafteditor-tag'>
          {p.children}
          </span>;
        };

       this.compositeDecorator = new CompositeDecorator([
          {
            strategy: this.hashtagStrategy,
            component: this.HandleSpan,
          },
          // {
          //   strategy: this.httpStrategy,
          //   component: this.HandleHttp,
          // },
          {
            strategy: this.mentionStrategy,
            component: this.HandleMention,
          },
        ]);

        //console.info(properties);

        var _readOnly: boolean = false;
        if (properties.readOnly!=undefined && properties.readOnly)
        { 
          _readOnly = true;
        }

        var _textOnly: boolean = false;
        if (properties.textOnly!=undefined && properties.textOnly)
        { 
          _textOnly = true;
        }

        let _editorstate: EditorState = EditorState.createEmpty();

        if (properties.text!=undefined)
        {
           try { 
           if (_textOnly) {
              var text: string = properties.text;
              if (text.charAt(0)== '{') 
              {
                const contentRaw: RawDraftContentState = JSON.parse(properties.text);
                _editorstate = EditorState.createWithContent(
                  convertFromRaw(contentRaw));
                
              }
              else{
                _editorstate = EditorState.createWithContent(
                  ContentState.createFromText(properties.text));
              }
            }
            else {
              var text: string = properties.text;
              if (text.charAt(0)== '{')
              {
                 const contentRaw: RawDraftContentState = JSON.parse(properties.text);
                _editorstate = EditorState.createWithContent(
                  convertFromRaw(contentRaw),
                  this.compositeDecorator);
              }
              else
              {
              _editorstate = EditorState.createWithContent(
                ContentState.createFromText(properties.text), this.compositeDecorator);
              }
            }
          }
          catch (error)
          {
            console.error(error);
          }
        }
        else
        if (properties.data!=undefined)
        {
          try {
            const contentRaw: RawDraftContentState = JSON.parse(properties.data);
            if (_textOnly) {
              _editorstate = EditorState.createWithContent(
                convertFromRaw(contentRaw));
            }
            else
            {
              _editorstate = EditorState.createWithContent(
                convertFromRaw(contentRaw),
                this.compositeDecorator);
            }
          }
          catch (exception)
          {
            console.error(exception)
          }
        }

        this.onSettingsBtn = this.onSettingsBtn.bind(this);

        this.state = { 
          readOnly: _readOnly, 
          loading: false, 
          hasFocus: false,
          showToolbar: false,
          textOnly: _textOnly,
          editorState: _editorstate,
          currentSelection: '',
          // analytics: undefined
        };

        this.setFocus = this.setFocus.bind(this);
    }
  
    private hashtagStrategy(contentBlock: ContentBlock, callback: any, contentState: ContentState) {
      const _REGEX = /\#[\w\u0590-\u05ffåäöÅÄÖ]+/g;
      this.findWithRegex(_REGEX, contentBlock, callback);
    }

    private httpStrategy(contentBlock: ContentBlock, callback: any, contentState: ContentState) {
      const _REGEX = /\h[\w]+/g;
      this.findWithRegex(_REGEX, contentBlock, callback);
    }

    private mentionStrategy(contentBlock: ContentBlock, callback: any, contentState: ContentState) {
      const _REGEX = /\@[\w\u0590-\u05ffåäöÅÄÖ]+/g;
      this.findWithRegex(_REGEX, contentBlock, callback);
    }

    private findWithRegex(regex: any, contentBlock: ContentBlock, callback: any) {
    
      const text = contentBlock.getText();
      //console.log('find:' + text);
      let matchArr, start;
      while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
      }
    }

    private openRef(value: any) {
      
      this.setState({showToolbar: false, hasFocus: false});
      
      var el : DraftEditorLeaf;
      if (value[0]!=undefined)
      {
        el = value[0] as DraftEditorLeaf;
        //console.log(el);
        if (el.props!=undefined)
        {
          //console.log(el.props.text);
          if (el.props.text!=undefined)
          {
            var keyword : string = el.props.text;
            keyword = keyword.replace('#','');

            App.publish("ref.open", keyword);
          }
        }
      }
    }

    public componentDidMount() {  
       App.subscribe(this.props.id,this.receiveData);
    }

    private receiveData(id: string, data: DraftEditorData) {
      //console.info(data);
      if (id===this.props.id) {
        this.update(data.contentStateText, data.contentStateRaw);
      }
    }  

    public componentWillUnmount() {
      App.unsubscribe(this.props.id);
    }

    private update(text?: string, raw?: string){
      try {      
          if (text!=undefined) {
            if (this.state.textOnly) {
              this.setState({
                editorState: EditorState.createWithContent(
                  ContentState.createFromText(text))
                });
            }
            else
            {
              this.setState({
                editorState: EditorState.createWithContent(
                  ContentState.createFromText(text),this.compositeDecorator)
                });
            }
            this._loaded = true;
          }

          if (raw!=undefined) {
            const contentRaw: RawDraftContentState = JSON.parse(raw);
            
            if (!this.state.textOnly)
            {
              this.setState({
                editorState: EditorState.createWithContent(
                  convertFromRaw(contentRaw),
                  this.compositeDecorator)
              });
            }
            else
            {
              this.setState({
                editorState: EditorState.createWithContent(
                  convertFromRaw(contentRaw))
              });
            }
            this._loaded = true;
          }
          //this.forceUpdate();

      } catch (error) {
        console.log(error);
      }
    }

    private handleKeyCommand(command: any, editorState: EditorState) {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onEditorChange(newState);
        return 'handled';
      }
      return 'not-handled';
    }

    private editfocus() {
      if (this.props.readOnly==undefined)
      {
        this.setState({hasFocus: true});
      }
      if (this.props.readOnly!=undefined && !this.props.readOnly)
      {
        this.setState({hasFocus: true});
      }
    }

    private editBlur() {
      try {
        if (!this.state.showToolbar)
        {
          this.setState({hasFocus: false});
          if (this.props.onSave!=null) {
            try {
              let content = this.state.editorState.getCurrentContent();
            
              let data : DraftEditorData = {
                id: this.props.id,
                contentStateRaw: JSON.stringify(convertToRaw(content)), 
                contentStateText: content.getPlainText()
              };
              this.props.onSave(data);
              this._dirty = false;
            }
            catch (exception)
            {
              console.error(exception);
            }
          }
        }
      }
      catch (error)
      {
       console.error(error);
      }
    }

    private getBlockStyle(block: ContentBlock) {
      switch (block.getType()) {
        //case 'blockquote': return 'RichEditor-blockquote';
        default: return '';
      }
    }

    private onEditorChange(state: EditorState) {
        this._dirty = true;

        var selectionState = state.getSelection();
        
        if (!selectionState.isEmpty())
        {
            var anchorKey = selectionState.getAnchorKey();
            var currentContent = state.getCurrentContent();
            var currentContentBlock = currentContent.getBlockForKey(anchorKey);
            var start = selectionState.getStartOffset();
            var end = selectionState.getEndOffset();
            var selectedText = currentContentBlock.getText().slice(start, end);

            if (selectedText.length>0)
            {
                //Display inline toolbar
                this.setState({showToolbar: true, currentSelection: selectedText});
            }
            else {
              this.setState({showToolbar: false});
            }
        }

        this.setState( {editorState: state} );

        if (this.props.onChange!=null) {
          try {
            let content = this.state.editorState.getCurrentContent();
          
            let rawdata: string = JSON.stringify(convertToRaw(content));

            let data : DraftEditorData = {
              id: this.props.dataId,
              contentStateRaw: rawdata, 
              contentStateText: content.getPlainText()
            };
            this.props.onChange(this.props.dataId, data);
            this._dirty = false;
          }
          catch (exception)
          {
            console.error(exception);
          }
        }
    }

    private onBoldBtn() {
      this.onEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
    }

    private onItalicBtn() {
      this.onEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'));
    }

    private onUnderlineBtn() {
      this.onEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE'));
    }

    private onLinkBtn() {
      var selectionState =this.state.editorState.getSelection();
      this.onEditorChange(RichUtils.toggleLink(this.state.editorState,selectionState, null));
    }

    private onToolsBtn() {
      this.setState({showToolbar: false});
      App.publish("text.tool.open" + this.props.id,{});

    }

    private onSettingsBtn() {
      this.setState({showToolbar: false});
      App.publish("textanalytics.open",this.state.currentSelection);
    }

    public setFocus() {
      if (this._editor!=null) {
        this._editor.focus();
      }
    }

    public render() {
        
        return <div id={this.props.id} 
        onClick={() => this.editfocus()} onFocus={() => this.editfocus()} onBlur={()=> this.editBlur()} 
        className={ this.state.hasFocus ? 'drafteditor-focus' : 'drafteditor'}>
          <Editor editorState={this.state.editorState} 
                  onChange={this.onEditorChange} readOnly={this.state.readOnly}
                  spellCheck={true} 
                  stripPastedStyles={true}
                  ref={((component: any) => { this._editor = component;} )}
                  blockStyleFn={this.getBlockStyle}
                  handleKeyCommand={this.handleKeyCommand}
                  placeholder={this.props.placeHolder}
          />
          <Popover target={this.props.id} isOpen={this.state.showToolbar}
            placement="top-start">
            <PopoverHeader>Textverktyg 
              <button type="button" className="close" onClick={()=>this.setState({showToolbar: false})} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </PopoverHeader>
            <PopoverBody>
            <Row>
              <Col md='12'>
             
                <button type="button" onClick={()=> this.onBoldBtn()}  
                className="btn btn-outline-dark btn-sm pull-left draft-btn">B</button>
   

                <button type="button" onClick={()=> this.onItalicBtn()} style={{fontStyle: 'italic'}}
                className="btn btn-outline-dark btn-sm pull-left draft-btn">I</button>


                <button type="button" onClick={()=> this.onUnderlineBtn()}  
                
                className="btn btn-outline-dark btn-sm pull-left draft-btn">
                <div style={{borderBottomWidth: '1px', borderBottomColor:'black', borderBottomStyle: 'solid'}}
                >U</div>
                </button>
                
                {/*
                <button type="button" onClick={()=> this.onLinkBtn()} 
                className="btn btn-primary btn-sm pull-left draft-btn">Länk</button>
                */}
                
                <button type="button" onClick={()=> this.onToolsBtn()} 
                className="btn btn-outline-dark btn-sm pull-left draft-btn">...</button>

                {/* <button type="button" onClick={()=> this.onSettingsBtn()} 
                className="btn btn-primary btn-sm pull-left draft-btn">Analys</button> */}

                
              </Col>
            </Row>

            </PopoverBody>
          </Popover>

        </div>
    }
  
  }

import * as React from 'react';
import * as App from 'pubsub-js';
import { UncontrolledCollapse } from 'reactstrap';
import { Language } from '../backbone/Language';

export interface CollapseListProps {
    id: string;
    loading?: boolean;
    style?: any;
    items: CollapseListItem[];
    lang: Language;
}

export interface CollapseListItem {    
    title?: React.ReactNode;
    content?: React.ReactNode;
    command?: string;
    data?: any;
    isOpen?: boolean;
}

export class CollapseList extends React.Component<CollapseListProps, {}> {
    constructor(props:CollapseListProps) {
        super(props);
    }
    public render() {
    return <React.Fragment>
        <div className="list-group" style={this.props.style}>
            {this.props.items.map((item,i) => {return (
                 <div key={i} style={{cursor: 'pointer'}} className="list-group-item list-group-item-action" 
                    onClick={() => {if (item.command!=undefined) App.publish(item.command,item.data)}} 
                    >
                    <div className="d-flex w-100 justify-content-between" id={ this.props.id + "cl" + i}>
                        {item.title}
                    </div>
                    <UncontrolledCollapse toggler={"#" + this.props.id + "cl" + i} defaultOpen={item.isOpen}>
                        {item.content}
                    </UncontrolledCollapse>
                 </div>
            )})}
            {this.props.items.length===0 ? <>
                <p className='text-center'><i className="fa fa-umbrella-beach"/>{'   '} {this.props.lang.Text('no.data')}</p>
            </>:<></>}
        </div>
    </React.Fragment>
    }
}
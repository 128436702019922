import React, { Component, useReducer } from 'react';
import { Container, Button, Fade, Row, Col } from 'reactstrap';

import * as App from 'pubsub-js';
import { LanguageContext, Language } from '../backbone/Language';
import { User } from '../api/User';
import { Application } from '../api/App';
import { GlobalAppsettings } from '../backbone/GlobalSettings';
import { ContentItem } from '../api/Content';
import { Loading } from '../components/base/Loading';
import { AppCore } from '../backbone/AppCore';
import { UrlHelper } from '../backbone/UrlHelper';
import { ContentBase } from '../components/base/ContentBase';
import ReactMarkdown from 'react-markdown';

const moment = require("moment");

export interface ContentPageProps {
    contentId?: string;
    contentType?: string;
    preview?: boolean;
}

export interface ContentPageState {
    loading: boolean,
    content?: ContentItem;
    contentId: string;
    contentType: string,
    locale: string,
    preview: boolean
    user: User.UserProfile;
    app:  Application.AppProfile;
}

export class ContentPage extends React.Component<ContentPageProps,ContentPageState> {
  static displayName = ContentPage.name;
  static contextType = LanguageContext;

  constructor(props:ContentPageProps) {
    super(props);

        this.state= {
            loading: true,
            user: AppCore.GetUserProfile(),
            app: AppCore.GetAppProfile(),
            locale: Language.GetLocale(),
            preview: this.props.preview ? true : false,
            contentId: this.props.contentId ?  this.props.contentId : '',
            contentType: this.props.contentType ?  this.props.contentType : 'contentful'
        };
    }

    componentDidMount() {
        
        let contentId = this.state.contentId;
        if (contentId===null || contentId ==='')
        {
            contentId = UrlHelper.GetIdFromCurrentURL();
        }
        
        if (this.state.contentType=='contentful' && contentId.length>0) {
            fetch('api/content/getcontent?id=' + contentId + '&locale=' + this.state.locale
                + '&preview=' + this.state.preview,
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<ContentItem>)
                .then(data => {    
                    console.log(data);
                    this.setState({content: data, loading: false});
                }).catch(reason => {
                    console.log(reason);
                    this.setState({loading: false});
            });
        }
        else if (this.state.contentType=='internal' && contentId.length>0)
        {
            fetch('api/content/getinternalcontent?id=' + contentId + '&locale=' + this.state.locale,
                {credentials: "same-origin"})
                .then(response => response.json() as Promise<ContentItem>)
                .then(data => {    
                    console.log(data);
                    this.setState({content: data, loading: false});
                }).catch(reason => {
                    console.log(reason);
                    this.setState({loading: false});
            });
        }
    }

    render() {
        return ( <React.Fragment>
        <LanguageContext.Consumer>
        {lang => <React.Fragment>
        <Container style={{paddingTop: '40px'}}>
            {this.state.loading ? 
            <Loading loading style={{height: '400px'}}/>
            :
            <React.Fragment>
                <Row>
                <Col>
                    <div className="yc-page-header" style={{color: '#0476D9', lineHeight: '75px'}}>{this.state.content?.title}</div>
                </Col> 
                {(this.state.content?.images && this.state.content?.images.length > 0)?
                <Col className="u-box-shadow-lg img-fluid" md="4" 
                    style={{
                    // borderLeft: '1px solid white', 
                    marginLeft: '20px',
                    backgroundImage:  'url(' + this.state.content?.images[0].url +')', 
                        backgroundPosition: '2px 0px', backgroundRepeat: 'no-repeat', overflowY: 'hidden'
                }}
                >
                    {/* <img src={this.state.content?.images[0].url}/> */}
                </Col>: <React.Fragment></React.Fragment>}

                {this.state.content?.description ?
                    <Col md="4" 
                    style={{borderLeft: '3px solid #0476D9', margin: '18px' }}
                    >
                    <ReactMarkdown key="1" source={this.state.content?.description} />
                </Col>:<React.Fragment></React.Fragment>}
                
                </Row>
                <Row style={{minHeight: '400px'}}>
                    <Col>
                        <ReactMarkdown key="2" source={this.state.content?.copy} />
                    </Col>
                </Row>
                                
            </React.Fragment>
            }
            <p style={{fontSize: '0.8em', color: 'darkgray'}}>
               {lang.Text('last.updated')}  {moment(this.state.content?.sys.updatedAt).format('YYYY-MM-DD HH:mm')}
               {'  '} 
               {/* {this.state.content?.sys.publishedBy} {' - '} */}
               {this.state.content?.shortDescription}            
            </p>
            </Container>
            {this.state.content?.slug ? <section style={{color: 'white', backgroundColor: '#F26D3D', padding: '20px'}}>
                <Container>
                    <Row>
                        <Col>
                            <ReactMarkdown key="3" source={this.state.content?.slug} skipHtml={true}/>
                        </Col>
                    </Row>
                </Container>
            </section> : <React.Fragment></React.Fragment>}
        </React.Fragment>}
        </LanguageContext.Consumer> 
        </React.Fragment>);

    }
}
